import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Paragraph } from '../components/Paragraph';
import { ExternalLink } from '../components/Button';
import { HeadingExtraLarge } from '../components/Heading';
import {
    Inner as _Inner,
    Content,
    LinkWrapper,
} from '../components/ProcessingElements';
import { trackPurchase } from '../utils/googleEvents';
import useConfig from '../hooks/useConfig';
import { orderFromPaymentIntentQuery } from '../utils/runtimeQueries';
import type {
    OrderFromPaymentIntentQueryQuery,
    OrderFromPaymentIntentQueryQueryVariables,
} from '../gql/api-public';
import { create404NotFoundUrl } from '../utils/urlHelper';
import { sentryMessage } from '../utils/sentry';
import isBrowser from '../utils/isBrowser';

export { Head } from '../components/Head';

const Container = styled.div``;

const Inner = styled(_Inner)`
    min-height: 100vh;
`;

const Light = styled.span`
    color: var(--foregroundColorMix5);
`;

enum STEP {
    LOADING,
    LOADING_DOWNLOADS,
    COMPLETE_DOWNLOADABLE,
    COMPLETE_NO_DOWNLOADS,
}

type STATE =
    | { step: STEP.LOADING }
    | { step: STEP.LOADING_DOWNLOADS }
    | {
          step: STEP.COMPLETE_DOWNLOADABLE;
          orderNumber: string;
          email: string;
          downloadUrl: string;
      }
    | {
          step: STEP.COMPLETE_NO_DOWNLOADS;
          orderNumber: string;
          email: string;
      };

// 90 second timeout.
// We want to know When delivering an order takes longer than this.
const TIMEOUT_DURATION = 90 * 1000;

function CheckoutSuccessPage(): React.ReactElement | null {
    const config = useConfig();
    const [state, setState] = React.useState<STATE>({ step: STEP.LOADING });
    const [timeoutTime, setTimeoutTime] = React.useState<number>(0);
    const location = useLocation();
    const paymentIntentId = location.state
        ? (location.state as KlimLinkState).paymentIntentId
        : undefined;

    const {
        data: queryData,
        startPolling,
        stopPolling,
        networkStatus,
    } = useQuery<
        OrderFromPaymentIntentQueryQuery,
        OrderFromPaymentIntentQueryQueryVariables
    >(orderFromPaymentIntentQuery, {
        variables: {
            paymentIntentId: paymentIntentId || '',
        },
        ssr: false,
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    // Workaround to ensure that polling stops properly.
    // See https://github.com/apollographql/react-apollo/issues/3272#issuecomment-514776237
    React.useEffect((): (() => void) => {
        startPolling(1000);
        return (): void => {
            stopPolling();
        };
    }, [startPolling, stopPolling]);

    // We can bail if there's no paymentIntentId. This likely means that
    // someone navigated to this page without going through
    // checkout.
    if (!paymentIntentId) {
        stopPolling();
        if (isBrowser()) {
            navigate(create404NotFoundUrl());
        }
    }

    const order = queryData?.orderFromPaymentIntent;

    // Process query result
    React.useEffect((): void => {
        if (paymentIntentId && order) {
            const { orderNumber, downloadUrl, cart, account } = order;
            const { email } = account.user;

            const newState: STATE = cart.hasDownloadables
                ? downloadUrl
                    ? {
                          step: STEP.COMPLETE_DOWNLOADABLE,
                          orderNumber,
                          email,
                          downloadUrl,
                      }
                    : {
                          step: STEP.LOADING_DOWNLOADS,
                      }
                : {
                      step: STEP.COMPLETE_NO_DOWNLOADS,
                      orderNumber,
                      email,
                  };
            if (
                newState.step === STEP.COMPLETE_NO_DOWNLOADS ||
                newState.step === STEP.COMPLETE_DOWNLOADABLE
            ) {
                stopPolling();
            }

            // Track e-commerce transaction with gtag.js
            if (
                newState.step === STEP.COMPLETE_NO_DOWNLOADS ||
                newState.step === STEP.LOADING_DOWNLOADS
            ) {
                trackPurchase(order, config.baseCurrency);
            }

            setState(newState);
        }
    }, [
        paymentIntentId,
        config.baseCurrency,
        order?.cart.hasDownloadables,
        order?.orderNumber,
        order?.downloadUrl,
        order?.account.user.email,
    ]);

    // Check for timeouts each time networkStatus changes
    if (timeoutTime === 0) {
        setTimeoutTime(Date.now() + TIMEOUT_DURATION);
    }
    React.useEffect((): void => {
        // Let Sentry know that there was a timeout...
        if (timeoutTime > 0 && Date.now() > timeoutTime) {
            sentryMessage(
                `orderFromPaymentIntentQuery has been polling for ${TIMEOUT_DURATION}ms on paymentIntentId ${paymentIntentId} and order ${
                    order && order.orderNumber
                }`,
            );
            // Stop checking, we've already timed out.
            setTimeoutTime(-1);
        }
    }, [networkStatus, timeoutTime, setTimeoutTime]);

    return (
        <Container>
            <Inner>
                <Content>
                    {state.step === STEP.LOADING && (
                        <>
                            <HeadingExtraLarge>
                                Please wait
                                <br />
                                Processing payment…
                            </HeadingExtraLarge>
                            <Paragraph>
                                Please do not refresh or navigate away from this
                                page during processing.
                            </Paragraph>
                        </>
                    )}
                    {state.step === STEP.COMPLETE_DOWNLOADABLE && (
                        <>
                            <HeadingExtraLarge>
                                Thanks for your purchase.
                                <br />
                                <Light>Order Nº {state.orderNumber}.</Light>
                            </HeadingExtraLarge>
                            <Paragraph>
                                Your order has been added to your account and
                                we’ve emailed your receipt to {state.email}.
                            </Paragraph>
                            <LinkWrapper>
                                <ExternalLink href={state.downloadUrl}>
                                    {`Download fonts & receipt`}
                                </ExternalLink>
                            </LinkWrapper>
                        </>
                    )}
                    {state.step === STEP.LOADING_DOWNLOADS && (
                        <>
                            <HeadingExtraLarge>
                                Payment successful.
                                <br />
                                Building your fonts…
                            </HeadingExtraLarge>
                            <Paragraph>
                                We’ll be done soon and will email you a link to
                                download or you can hang out here.
                            </Paragraph>
                        </>
                    )}
                    {state.step === STEP.COMPLETE_NO_DOWNLOADS && (
                        <>
                            <HeadingExtraLarge>
                                Thanks for your purchase.
                                <br />
                                <Light>Order Nº {state.orderNumber}.</Light>
                            </HeadingExtraLarge>
                            <Paragraph>
                                Your order has been added to your account and
                                we’ve emailed your receipt to {state.email}.
                            </Paragraph>
                        </>
                    )}
                </Content>
            </Inner>
        </Container>
    );
}

export default React.memo(CheckoutSuccessPage);
