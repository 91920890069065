import styled, { css } from 'styled-components';
import { LOZENGE_SPACING, VIEWPORT } from '../settings/Global';

const commonDataStyles = css`
    overflow-wrap: break-word;
`;

export const AccountRowsContainer = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: var(--gridColumnGap);
    grid-template-rows: 1fr auto;
    align-items: baseline;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: 100%;
    }
`;

export const AccountSpacer = styled.div`
    margin: var(--spacing7) 0;
    grid-column: 1 / -1;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        border-bottom: none;
    }
`;

export const AccountRow = styled.div<{ $noBorder?: boolean }>`
    --border: 1px solid var(--borderColor);

    grid-column: 1 / span 7;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: var(--gridColumnGap);
    padding: var(--spacing1) 0;
    ${({ $noBorder }): ReturnType<typeof css> | null =>
        $noBorder
            ? null
            : css`
                  border-bottom: var(--border);
              `}

    &:last-child {
        border-bottom: none;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const AccountRowTitle = styled.div<{ $rowSpan?: number }>`
    ${commonDataStyles};

    font-weight: bold;
    grid-column: 1 / span 2;
    white-space: pre-wrap;

    ${({ $rowSpan }): ReturnType<typeof css> | null =>
        $rowSpan
            ? css`
                  grid-row-end: span ${$rowSpan};

                  @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
                      grid-row-end: initial;
                  }
              `
            : null};

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

/**
 * Full width data
 */
export const AccountRowData = styled.div<{ $tabletColEnd?: number }>`
    ${commonDataStyles};

    grid-column: 3 / -1;

    ${({ $tabletColEnd }): ReturnType<typeof css> | null =>
        $tabletColEnd
            ? css`
                  @media screen and (max-width: ${VIEWPORT.TABLET}px) {
                      grid-column: 3 / span ${$tabletColEnd};
                  }
              `
            : null};

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 /-1;
    }
`;

export const AccountRowLicence = styled.div`
    ${commonDataStyles};

    grid-column: 3 / span 1;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 2;
    }
`;

export const AccountRowTier = styled.div`
    ${commonDataStyles};

    grid-column: 4 / span 2;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 3 / span 2;
    }
`;

export const AccountRowItem = styled.div`
    ${commonDataStyles};

    color: var(--textColor, var(--foregroundColor));
    grid-column: 3 / span 4;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 2;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 4;
    }
`;

export const AccountRowSubtotalName = styled(AccountRowItem)`
    grid-column: 3 / span 3;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 2;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / span 3;
    }
`;

export const AccountRowDataLast = styled.div`
    ${commonDataStyles};

    color: var(--textColor, var(--foregroundColor));
    grid-column: 7 / -1;
    text-align: right;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 5 / -1;
    }
`;

export const AccountRowLicenceSubject = styled(AccountRowDataLast)`
    word-break: break-all;
    grid-column: 6 / -1;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 5 / -1;
    }
`;

export const AccountRowSubtotalValue = styled(AccountRowDataLast)`
    grid-column: 6 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 5 / -1;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 4 / -1;
    }
`;

export const AccountRowSeparator = styled.hr`
    display: block;
    border: 0;
    height: 1px;
    width: 100%;
    grid-column: 3 / -1;
    vertical-align: middle;
    background-color: var(--foregroundColorMix2);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

export const ActionsRow = styled(AccountRowData)`
    display: flex;
    flex-direction: row;
    gap: ${LOZENGE_SPACING}px;
    align-items: flex-start;
    margin-top: var(--spacing4);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-bottom: 0;
    }
`;
