import React from 'react';
import useIsCartEmpty from '../hooks/useIsCartEmpty';
import { useMutation } from '@apollo/client';
import { shareCartMutation } from '../utils/runtimeQueries';
import type { ShareCartMutationMutation } from '../gql/api-public';
import useCartQuery from '../hooks/useCartQuery';
import { useErrorOverlayState, useGlobalState } from './GlobalRuntimeState';
import Button, { ExternalLink } from './Button';
import { TEST_ID } from '../settings/E2e';
import {
    createCartQuoteDownloadUrl,
    createSharedCartUrl,
} from '../utils/urlHelper';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import FieldText from './FieldText';
import Icon, { IconType } from './Icon';
import { ErrorOverlayType } from './ErrorOverlay';

const ShareControls = styled.div`
    position: relative;
    display: grid;
    grid-gap: var(--gridColumnGap) var(--gridColumnGap);
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: 100%;
    }
`;

const ModalCenter = styled.div`
    text-align: center;
`;

const TextField = styled(FieldText)`
    text-align: center;

    --lozengeColor: var(--foregroundColor);
`;

export default function CartShareSection(): React.ReactElement | null {
    const cartQuery = useCartQuery();
    const [, setErrorOverlayState] = useErrorOverlayState();
    const cart = cartQuery?.data?.cart;
    const cartIsEmpty = useIsCartEmpty(cart);
    const [doShareCart] =
        useMutation<ShareCartMutationMutation>(shareCartMutation);
    const [, setModalState] = useGlobalState('modal');
    const [, setSiteOverlayState] = useGlobalState('siteOverlay');

    const closeModal = React.useCallback(() => {
        setModalState({});
        setSiteOverlayState({});
    }, [setModalState, setSiteOverlayState]);

    const introText = (
        <ModalCenter>
            Use this link to share your cart’s contents.
            <br />
            <br />
        </ModalCenter>
    );

    const handleShareCartClick = React.useCallback(async () => {
        setModalState({
            isVisible: true,
            isUberAlles: true,
            message: (
                <>
                    {introText}
                    <TextField
                        value="Loading…"
                        disabled
                        contentEditable={false}
                        readOnly
                        onFocus={(e): void => e.target.select()}
                    />
                </>
            ),
        });

        const result = await doShareCart();
        const sharedCartId = result.data?.shareCart?.sharedCartId;

        if (!sharedCartId) {
            closeModal();
            setErrorOverlayState({ isShown: true });
            return;
        }

        const shareUrl = createSharedCartUrl(sharedCartId);

        setModalState({
            isVisible: true,
            isUberAlles: true,
            message: (
                <>
                    {introText}
                    <TextField
                        value={shareUrl}
                        contentEditable={false}
                        readOnly
                        onFocus={(e): void => e.target.select()}
                    />
                </>
            ),
            primaryButtonText: `Copy link`,
            primaryButtonCallback: () => {
                navigator.clipboard.writeText(shareUrl).then(
                    () => {
                        setTimeout(closeModal, 2000);
                        setModalState((state) => {
                            return {
                                ...state,
                                primaryButtonText: (
                                    <>
                                        <Icon
                                            type={IconType.CHECKBOX_CHECKED}
                                        />
                                        &nbsp;Copied
                                    </>
                                ),
                                primaryButtonCallback: (): void => undefined,
                            };
                        });
                    },
                    () => {
                        closeModal();
                        setErrorOverlayState({ isShown: true });
                    },
                );
            },
        });
    }, [closeModal, doShareCart, setErrorOverlayState, setModalState]);

    if (cartIsEmpty) {
        return null;
    }

    return (
        <ShareControls>
            <Button
                $type="withBackground"
                data-cy={TEST_ID.CART_SHARE_BUTTON}
                onClick={handleShareCartClick}
            >
                Share cart
            </Button>
            <ExternalLink
                data-cy={TEST_ID.CART_QUOTE_BUTTON}
                $type="withBackground"
                href={createCartQuoteDownloadUrl()}
                onClick={(e): void => {
                    setErrorOverlayState({
                        isShown: true,
                        type: ErrorOverlayType.DOWNLOAD_QUOTE,
                        hideButton: true,
                    });

                    // Download quote using Ajax, so that we can trigger onLoad event and close modal
                    const req = new XMLHttpRequest();
                    req.withCredentials = true;
                    req.open('GET', createCartQuoteDownloadUrl(), true);
                    req.responseType = 'blob';
                    req.setRequestHeader('Content-type', 'application/json');
                    req.onload = (): void => {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(req.response);
                        const contentDisposition =
                            req.getResponseHeader('Content-Disposition') || '';
                        const fileName = contentDisposition
                            .split('filename=')
                            .pop();
                        link.download =
                            fileName &&
                            (fileName.endsWith('.pdf') ||
                                fileName.endsWith('.html'))
                                ? fileName
                                : 'klim-quote.pdf';
                        link.click();
                        setErrorOverlayState({
                            isShown: false,
                        });
                    };
                    req.send();

                    // Prevent modal from auto-closing
                    e.preventDefault();
                    // e.stopPropagation();
                }}
            >
                Save as PDF
            </ExternalLink>
        </ShareControls>
    );
}
