import React from 'react';
import { LineItemType } from '../gql/api-public';
import { CMS_SSR_LicenceTypeId } from '../gql/api-ssr';
import type { Cart } from '../hooks/useUserOrdersQuery';
import getGroupedLineItems from '../utils/getGroupedLineItems';
import AccountCartLineItem from './AccountCartLineItem';
import useActiveLicenceTypes, {
    LicenceType,
} from '../hooks/useActiveLicenceTypes';
import { AccountRow, AccountRowTitle } from './AccountElements';

function formatDomain(domain: string | null): string {
    if (!domain) {
        return '';
    }
    const domainName = domain.replace(/^https?:\/\//, '');
    return domainName.endsWith('/') ? domainName.slice(0, -1) : domainName;
}

function getLicenceTierName(
    cart: Cart,
    licenceType?: LicenceType,
): string | undefined {
    if (!licenceType) {
        return;
    }
    // UDLA doesn't have tiers, really.
    if (licenceType.id === CMS_SSR_LicenceTypeId.UDLA) {
        return;
    }
    const cartTier = cart.licenceTiers.find(
        (cartTier) => cartTier.tier.licenceType.id === licenceType.id,
    );

    // We don't want a tier name for unlimited licensing here, either
    if (!cartTier || cartTier.tier.isUnlimited) {
        return;
    }

    let suffix = '';
    if (
        cartTier?.licenceSubject &&
        cartTier.tier.licenceType.licenceSubjectLabel
    ) {
        suffix = `\n${
            cartTier.tier.licenceType.id === CMS_SSR_LicenceTypeId.WEB
                ? formatDomain(cartTier.licenceSubject)
                : cartTier.licenceSubject
        }`;
    }

    return `${cartTier.tier.title}${suffix}`;
}

function createLicenceName(heading: string, licenceTypeName?: string): string {
    return `${heading}${licenceTypeName ? `\n${licenceTypeName}` : ''}`;
}

const LineItemGroup = React.memo(
    ({
        cart,
        licenceType,
        lineItemType,
    }: {
        cart: Cart;
        licenceType?: LicenceType;
        lineItemType?: LineItemType;
        licenceQuantitySelector?: React.ReactNode;
    }): React.ReactElement | null => {
        const groupedLineItems = getGroupedLineItems({
            items: cart.items,
            licenceType,
            lineItemType,
        });
        if (!groupedLineItems || !groupedLineItems.items.length) {
            return null;
        }
        const { items, name: heading } = groupedLineItems;

        return (
            <AccountRow>
                <AccountRowTitle $rowSpan={items.length}>
                    {cart.hasSimpleLicensing
                        ? cart.hasFontlessItems
                            ? 'Items'
                            : 'Fonts'
                        : createLicenceName(
                              heading,
                              getLicenceTierName(cart, licenceType),
                          )}
                </AccountRowTitle>
                {items.map(
                    (item): React.ReactElement => (
                        <AccountCartLineItem
                            cart={cart}
                            item={item}
                            key={item.id}
                        />
                    ),
                )}
            </AccountRow>
        );
    },
);
LineItemGroup.displayName = 'LineItemGroup';

interface AccountCartInventoryProps {
    cart: Cart;
}

function AccountCartInventory({
    cart,
}: AccountCartInventoryProps): React.ReactElement {
    const activeLicenceTypes = useActiveLicenceTypes();
    return (
        <>
            {activeLicenceTypes.map((licenceType) => {
                return (
                    <LineItemGroup
                        key={`lig-${licenceType.id}`}
                        cart={cart}
                        licenceType={licenceType}
                    />
                );
            })}

            <LineItemGroup cart={cart} lineItemType={LineItemType.RETAIL} />
            <LineItemGroup cart={cart} lineItemType={LineItemType.CUSTOM} />
            <LineItemGroup cart={cart} lineItemType={LineItemType.GOODS} />
            <LineItemGroup cart={cart} lineItemType={LineItemType.OTHER} />
        </>
    );
}

export default React.memo(AccountCartInventory);
