import React from 'react';
import styled from 'styled-components';
import { ReactComponent as _StripeLogo } from '../assets/icons/stripe-payments.svg';
import { SubHeading } from './CheckoutFormElements';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { createRefundPaymentPolicyUrl } from '../utils/urlHelper';

const StripeLogo = styled(_StripeLogo)`
    fill: var(--foregroundColorMix5);
`;

const Link = styled(InlineUnderlinedLink)`
    color: inherit;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing2);
    padding-top: var(--spacing2);
`;

export default function CheckoutFormPaymentSectionLabel(): React.ReactElement {
    return (
        <Container>
            <SubHeading>
                All transactions are
                <br />
                {` `}
                secure and encrypted.
                <br />
                <Link to={createRefundPaymentPolicyUrl()}>Read more</Link>
            </SubHeading>
            <StripeLogo />
        </Container>
    );
}
