import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import LicenceQuantitySelector from './LicenceQuantitySelector';
import { LineItemType, FontProductTypeChoice } from '../gql/api-public';
import useCartQuery from '../hooks/useCartQuery';
import type { Item } from '../hooks/useCartQuery';
import CartSummaryRow, {
    CartAmount,
    CartSummaryRowsWrapper,
} from './CartSummaryRow';
import getGroupedLineItems from '../utils/getGroupedLineItems';
import useAllFonts from '../hooks/useAllFonts';
import useActiveLicenceTypes from '../hooks/useActiveLicenceTypes';
import type { LicenceType } from '../hooks/useActiveLicenceTypes';
import notNull from '../utils/notNull';
import useConfig from '../hooks/useConfig';
import getFontUrl from '../utils/getFontUrl';
import printPrice from '../utils/printPrice';
import getLineItemUpgradeDescription from '../utils/getLineItemUpgradeDescription';
import { TEST_ID } from '../settings/E2e';
import { sentryException, sentryMessage } from '../utils/sentry';
import { useDebouncedCartUpdateMutationContext } from '../hooks/useDebouncedCartUpdateMutation';
import { useErrorOverlayState, useGlobalState } from './GlobalRuntimeState';
import {
    BUY_PAGE_FONTS_ID,
    BUY_PAGE_LICENCES_ID,
    LOZENGE_SPACING,
    MARGIN_SMALL,
    VIEWPORT,
} from '../settings/Global';
import { FONT_SIZE_FIXED2_TABLET_LARGE } from './CSSVariableDefinitions';
import { useIsInvoicePage } from './PageContext';
import Icon, { IconType } from './Icon';
import { A } from './Lozenge';

const EmptyCartHint = styled(A)`
    --lozengeColor: var(--foregroundColorMix4);
    --lozengeBackgroundColor: transparent;
    --lozengeHoverColor: var(--backgroundColor);
    --lozengeBackgroundHoverColor: var(--foregroundColor);
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);
`;

const SimpleTitle = styled.h3`
    font-weight: bold;
    color: var(--foregroundColor);
`;

const LicenceSelectorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    color: var(--foregroundColorMix5);
`;

const LineItemsGroup = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing2);
`;

const LicenceSelectorWrap = styled.span`
    display: inline-block;
    color: var(--foregroundColorMix4);
`;

export const ProductLink = styled(GatsbyLink)`
    &:hover,
    &:focus,
    &:active {
        color: var(--foregroundColorMix4);
    }
`;

export const ProductDescriptionInline = styled.span`
    color: var(--foregroundColorMix4);
`;

export const ProductDescription = styled.p`
    display: block;
    white-space: pre-wrap;
    color: var(--foregroundColorMix4);
`;

const LineItem = React.memo(
    ({ item }: { item: Item }): React.ReactElement | null => {
        const cartQuery = useCartQuery();
        const cart = cartQuery?.data?.cart;
        const config = useConfig();
        const fontFamilyGroups = useAllFonts();
        const [, setShowErrorOverlay] = useErrorOverlayState();
        const [, setIsCartShown] = useGlobalState('isCartShown');
        const doDebouncedCartUpdate = useDebouncedCartUpdateMutationContext();

        if (!cart) {
            return null;
        }

        const fontUrl = getFontUrl(item, fontFamilyGroups);
        const unifiedDescription = item.productName || item.description;
        const priceFormatted = item.price ? (
            <CartAmount data-cy={TEST_ID.CART_ITEM_PRICE}>
                {printPrice(item.price, cart.nativeCurrency)}
            </CartAmount>
        ) : undefined;
        const upgradeDescription = getLineItemUpgradeDescription(item);

        const onClickRemove = (
            event: React.MouseEvent<HTMLButtonElement>,
        ): void => {
            event.stopPropagation(); // Prevents Cart overlay being dismissed on click.
            if (!item.font) {
                return;
            }
            doDebouncedCartUpdate({
                cart,
                config,
                fontIdsToRemove: [item.font.fontId],
            }).catch((error) => {
                sentryException(error);
                setShowErrorOverlay({
                    isShown: true,
                });
            });
        };

        // For font styles we want to show the subtitle inline with the main description, rather than below it
        const descriptionSuffix =
            item.productSubtitle &&
            item.font?.fontProductType === FontProductTypeChoice.FONTSTYLE ? (
                <ProductDescriptionInline>
                    {' '}
                    {item.productSubtitle}
                </ProductDescriptionInline>
            ) : null;

        return (
            <CartSummaryRow
                leftContent={
                    <>
                        {fontUrl ? (
                            <ProductLink
                                to={fontUrl}
                                data-cy={TEST_ID.CART_FONT}
                                onClick={(): void => {
                                    setIsCartShown(false);
                                }}
                            >
                                {unifiedDescription}
                            </ProductLink>
                        ) : (
                            unifiedDescription
                        )}
                        {descriptionSuffix}
                    </>
                }
                rightContent={priceFormatted}
                onRemove={!cart.isInvoice ? onClickRemove : undefined}
                removeLabel={`Remove ${unifiedDescription} from cart`}
                belowContent={
                    <>
                        {item.description && item.productName && (
                            <ProductDescription>
                                {item.description}
                            </ProductDescription>
                        )}
                        {upgradeDescription && (
                            <ProductDescription>
                                {upgradeDescription}
                            </ProductDescription>
                        )}
                        {!item.description &&
                            !item.upgradedOrderNumbers.length &&
                            item.productSubtitle &&
                            !descriptionSuffix && (
                                <ProductDescription>
                                    {item.productSubtitle}
                                </ProductDescription>
                            )}
                    </>
                }
            />
        );
    },
);
LineItem.displayName = 'LineItem';

const LineItemGroup = React.memo(
    ({
        licenceType,
        lineItemType,
        licenceQuantitySelector,
    }: {
        licenceType?: LicenceType;
        lineItemType?: LineItemType;
        licenceQuantitySelector?: React.ReactNode;
    }): React.ReactElement | null => {
        const cartQuery = useCartQuery();
        const cart = cartQuery?.data?.cart;
        const groupedLineItems = React.useMemo(
            () =>
                cart?.items
                    ? getGroupedLineItems({
                          items: cart?.items,
                          licenceType,
                          lineItemType,
                      })
                    : undefined,
            [cart?.items, licenceType, lineItemType],
        );
        if (!cart || !groupedLineItems || !groupedLineItems.items.length) {
            return null;
        }
        const { items: lineItems, name: heading } = groupedLineItems;
        return (
            <LineItemsGroup>
                <CartSummaryRowsWrapper>
                    <CartSummaryRow
                        leftContent={<SimpleTitle>{heading}</SimpleTitle>}
                        rightContent={
                            !cart.hasSimpleLicensing &&
                            licenceQuantitySelector ? (
                                <LicenceSelectorWrapper>
                                    {licenceQuantitySelector}
                                </LicenceSelectorWrapper>
                            ) : null
                        }
                    />
                    {lineItems.map(
                        (item): React.ReactElement => (
                            <LineItem key={`cli-${item.id}`} item={item} />
                        ),
                    )}
                </CartSummaryRowsWrapper>
            </LineItemsGroup>
        );
    },
);
LineItemGroup.displayName = 'LineItemGroup';

function CartHint({
    type,
    isMobile,
}: {
    type: 'font' | 'licence';
    isMobile: boolean | undefined;
}): React.ReactElement {
    return (
        <CartSummaryRow
            leftContent={
                <EmptyCartHint
                    href={`#${
                        type === 'font'
                            ? BUY_PAGE_FONTS_ID
                            : BUY_PAGE_LICENCES_ID
                    }`}
                >
                    <Icon
                        type={
                            isMobile ? IconType.ARROW_UP : IconType.ARROW_LEFT
                        }
                    />
                    &nbsp;Choose {type}s
                </EmptyCartHint>
            }
        />
    );
}

function CartSummaryInventory({
    isMobile,
    containerRef,
}: {
    isMobile?: boolean;
    containerRef?: React.Ref<HTMLDivElement>;
}): React.ReactElement | null {
    const isInvoicePage = useIsInvoicePage();
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const config = useConfig();
    const [, setShowErrorOverlay] = useErrorOverlayState();
    const activeLicenceTypes = useActiveLicenceTypes();
    const doDebouncedCartUpdate = useDebouncedCartUpdateMutationContext();
    const cartLicenceWrapperRef = React.useRef<HTMLDivElement | null>(null);
    const cartLicenceSelectorItemsRef = React.useRef<
        Array<HTMLSpanElement | null>
    >([]);
    const [viewportWidth] = useGlobalState('viewportWidth');

    /*
    Set the maximum width for the licence <select> lists, based on available width.
    Sadly we can't use percentages or other relative units here, as we're truncating
    select list contents with ellipsis.
     */
    const updateLicenceSelectorMaxWidths = React.useCallback(() => {
        cartLicenceSelectorItemsRef.current.forEach((licenceWrapEl) => {
            if (!licenceWrapEl) {
                return;
            }
            const rightColumn = licenceWrapEl.parentElement;
            if (!rightColumn) {
                return;
            }
            const columnParent = rightColumn.parentElement;
            if (!columnParent) {
                return;
            }
            let width = columnParent.clientWidth - MARGIN_SMALL;
            if (
                viewportWidth !== undefined &&
                viewportWidth <= VIEWPORT.MOBILE
            ) {
                width -= LOZENGE_SPACING + FONT_SIZE_FIXED2_TABLET_LARGE;
            }
            Array.from(columnParent.children).forEach((child) => {
                if (child === rightColumn) {
                    return;
                }
                width -= child.clientWidth;
            });
            licenceWrapEl.style.setProperty('--lozengeMaxWidth', `${width}px`);
        });
    }, [cartLicenceSelectorItemsRef]);

    React.useEffect((): void | (() => void) => {
        if (!cartLicenceWrapperRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver((): number =>
            requestAnimationFrame((): void => updateLicenceSelectorMaxWidths()),
        );
        resizeObserver.observe(cartLicenceWrapperRef.current);
        return (): void => resizeObserver.disconnect();
    }, [cartLicenceWrapperRef]);

    if (!cart) {
        return null;
    }

    const testId = isMobile
        ? TEST_ID.CART_INVENTORY_MOBILE
        : TEST_ID.CART_INVENTORY;

    /**
     * Simple view
     */
    if (cart.hasSimpleLicensing) {
        const lineItems = getGroupedLineItems({
            items: cart.items,
            cartHasSimpleLicensing: true,
        });

        return (
            <Container data-cy={testId} ref={containerRef}>
                {(cart.licenceTiers.length > 0 || !isInvoicePage) && (
                    <>
                        <CartSummaryRowsWrapper ref={cartLicenceWrapperRef}>
                            <CartSummaryRow
                                leftContent={
                                    <SimpleTitle>Licence formats</SimpleTitle>
                                }
                            />
                            {!cart.licenceTiers.length ? (
                                <CartHint type="licence" isMobile={isMobile} />
                            ) : (
                                activeLicenceTypes
                                    .filter(notNull)
                                    .map((licenceType, idx) => {
                                        const cartTier = cart.licenceTiers.find(
                                            (cartTier) =>
                                                cartTier.tier.licenceType.id ===
                                                licenceType.id,
                                        );
                                        if (!cartTier) {
                                            return null;
                                        }

                                        const licenceSelector =
                                            cart.isInvoice ? (
                                                cartTier.tier.title
                                            ) : (
                                                <LicenceSelectorWrap
                                                    ref={(
                                                        el,
                                                    ): HTMLSpanElement | null =>
                                                        (cartLicenceSelectorItemsRef.current[
                                                            idx
                                                        ] = el)
                                                    }
                                                >
                                                    <LicenceQuantitySelector
                                                        invertContentColors
                                                        licenceType={
                                                            licenceType
                                                        }
                                                        onChange={(
                                                            tierToAdd,
                                                        ): void => {
                                                            doDebouncedCartUpdate(
                                                                {
                                                                    cart,
                                                                    config,
                                                                    tierToAdd,
                                                                },
                                                            ).catch((error) => {
                                                                sentryException(
                                                                    error,
                                                                );
                                                                setShowErrorOverlay(
                                                                    {
                                                                        isShown:
                                                                            true,
                                                                    },
                                                                );
                                                            });
                                                        }}
                                                    />
                                                </LicenceSelectorWrap>
                                            );

                                        const onRemoveLicence = cart.isInvoice
                                            ? undefined
                                            : (): void => {
                                                  doDebouncedCartUpdate({
                                                      cart,
                                                      config,
                                                      tierIdToRemove:
                                                          cartTier.tier.id,
                                                  }).catch((error) => {
                                                      sentryException(error);
                                                      setShowErrorOverlay({
                                                          isShown: true,
                                                      });
                                                  });
                                              };

                                        return (
                                            <CartSummaryRow
                                                key={`lic-${licenceType.id}`}
                                                leftContent={licenceType.name}
                                                rightContent={licenceSelector}
                                                onRemove={onRemoveLicence}
                                                removeLabel={`Remove ${licenceType.name} fonts from cart`}
                                            />
                                        );
                                    })
                            )}
                        </CartSummaryRowsWrapper>
                        <CartSummaryRowsWrapper>
                            <CartSummaryRow
                                leftContent={<SimpleTitle>Fonts</SimpleTitle>}
                            />
                            {lineItems && lineItems.items.length ? (
                                lineItems.items.map((item) => (
                                    <LineItem
                                        key={`cil-${item.id}`}
                                        item={item}
                                    />
                                ))
                            ) : (
                                <CartHint type="font" isMobile={isMobile} />
                            )}
                        </CartSummaryRowsWrapper>
                    </>
                )}
                <LineItemGroup lineItemType={LineItemType.CUSTOM} />
                <LineItemGroup lineItemType={LineItemType.GOODS} />
                <LineItemGroup lineItemType={LineItemType.OTHER} />
            </Container>
        );
    }

    /**
     * More complex view, when `cart.hasSimpleLicensing === false`.
     * This only applies to invoiced carts, so can be read-only.
     */

    if (!cart.isInvoice) {
        // Let's see if this ever happens... shouldn't do!
        sentryMessage(
            `This cart doesn't have simple licencing but is also not an invoice! ${cart.id}`,
        );
    }

    return (
        <Container data-cy={testId} ref={containerRef}>
            {activeLicenceTypes.filter(notNull).map((licenceType) => {
                const cartTier = cart.licenceTiers.find(
                    (cartTier) =>
                        cartTier.tier.licenceType.id === licenceType.id,
                );
                if (!cartTier) {
                    return null;
                }
                return (
                    <LineItemGroup
                        key={`lig-${licenceType.id}`}
                        licenceType={licenceType}
                        licenceQuantitySelector={cartTier.tier.title}
                    />
                );
            })}
            <LineItemGroup lineItemType={LineItemType.RETAIL} />
            <LineItemGroup lineItemType={LineItemType.CUSTOM} />
            <LineItemGroup lineItemType={LineItemType.GOODS} />
            <LineItemGroup lineItemType={LineItemType.OTHER} />
        </Container>
    );
}

export default React.memo(CartSummaryInventory);
