import {
    applyMinimumFontSize,
    GeneratedSpecimen,
} from '../utils/GeneratedSpecimen';
import {
    MEDIUM_SPECIMEN_MOBILE_FONT_SIZE_MULTIPLIER,
    SpecimenType,
    VIEWPORT,
} from '../settings/Global';
import { useGlobalState } from '../components/GlobalRuntimeState';
import useConfig from './useConfig';

function getMediumFontSizeMultiplier(viewportWidth: number): number {
    return viewportWidth < VIEWPORT.TABLET
        ? MEDIUM_SPECIMEN_MOBILE_FONT_SIZE_MULTIPLIER
        : 1;
}

/**
 * Font size is calculated in different ways from the GeneratedSpecimen,
 * to ensure that it is correct for the window width. It returns a size
 * in pixels.
 */
export default function useSpecimenFontSize(
    specimen: GeneratedSpecimen,
): number {
    const { bigSpecimenRefFontSizePx } = useConfig();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [viewportWidthWithoutMargins] = useGlobalState(
        'viewportWidthWithoutMargins',
    );

    if (
        viewportWidth === undefined ||
        viewportWidthWithoutMargins === undefined
    ) {
        throw new Error(`Can't get font size, as viewportWidth is undefined`);
    }

    const fontSizePx =
        specimen.type === SpecimenType.BIG
            ? (viewportWidthWithoutMargins / specimen.fontSizeRef) *
              bigSpecimenRefFontSizePx *
              // A little extra buffer to prevent these from being slightly too wide for viewport...
              0.99
            : specimen.type === SpecimenType.MEDIUM
              ? (viewportWidthWithoutMargins *
                    specimen.fontSizeRef *
                    getMediumFontSizeMultiplier(viewportWidth)) /
                100
              : specimen.type === SpecimenType.SMALL
                ? (viewportWidthWithoutMargins * specimen.fontSizeRef) / 100
                : undefined;

    if (fontSizePx === undefined) {
        throw new Error(`Incorrect specimen type ${specimen.type}`);
    }

    return applyMinimumFontSize(
        viewportWidth,
        specimen.type,
        Math.floor(fontSizePx),
    );
}
