import React from 'react';
import styled from 'styled-components';
import { HeadingExtraLarge } from './Heading';
import AccountOrderHistory from './AccountOrderHistory';
import AccountUserDetails from './AccountUserDetails';
import type { User } from '../hooks/useUserQuery';
import { VIEWPORT } from '../settings/Global';
import { TEST_ID } from '../settings/E2e';
import _TitledSection from './TitledSection';
import Button, { Link } from './Button';
import Icon, { IconType } from './Icon';
import { createLogoutUrl, createRevertLoginUrl } from '../utils/urlHelper';
import { ActionsRow } from './AccountElements';

const TitledSection = styled(_TitledSection)`
    padding-top: 0;
`;

export const Title = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    margin-bottom: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-bottom: var(--spacing4);
    }

    ${HeadingExtraLarge}, ${ActionsRow} {
        grid-column: 5 / -1;

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            grid-column: 3 / -1;
        }

        @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
            grid-column: 1 / -1;
        }
    }
`;

export default function AccountUser({
    user,
}: {
    user: User;
}): React.ReactElement {
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    let possessiveName = user.fullName ? `${user.fullName}’` : 'Your';
    if (user.fullName && !user.fullName.endsWith('s')) {
        possessiveName = `${possessiveName}s`;
    }
    return (
        <>
            <Title>
                <HeadingExtraLarge data-cy={TEST_ID.ACCOUNT_HEADING} as="h1">
                    {`${possessiveName} account`}
                </HeadingExtraLarge>
                <ActionsRow>
                    <Button
                        onClick={(): void => setIsEditable(true)}
                        $type="tertiary"
                        $slim
                        data-state={isEditable ? 'active' : undefined}
                    >
                        Edit details&nbsp;&nbsp;
                        <Icon type={IconType.EDIT} />
                    </Button>
                    <Link to={createLogoutUrl()} $type="tertiary" $slim>
                        Sign out&nbsp;&nbsp;
                        <Icon type={IconType.EXTERNAL_LINK} />
                    </Link>
                    {user.canRevert ? (
                        <Link
                            to={createRevertLoginUrl()}
                            $type="tertiary"
                            $slim
                        >
                            Back to admin&nbsp;&nbsp;
                            <Icon type={IconType.RESET} />
                        </Link>
                    ) : null}
                </ActionsRow>
            </Title>
            <TitledSection title="Details" hideTitleOnTablet>
                <AccountUserDetails
                    user={user}
                    isEditable={isEditable}
                    setIsEditable={setIsEditable}
                />
            </TitledSection>
            <TitledSection title="Purchases" hideTitleOnTablet>
                <AccountOrderHistory />
            </TitledSection>
        </>
    );
}
