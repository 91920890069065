import React from 'react';
import styled from 'styled-components';
import { SpecimenType, VIEWPORT } from '../settings/Global';
import { useFontSizeState, useSpecimenContext } from './TypeEditorContext';
import printFontSizePx from '../utils/printFontSizePx';
import { type TypeEditorActiveStatus } from './TypeEditorToolbar';
import {
    IconWithActiveStatus,
    SliderWithActiveStatus,
    ValueWithActiveStatus,
    WidgetContainerWithActiveStatus,
} from './TypeEditorToolbarCommon';
import useSpecimenFontSize from '../hooks/useSpecimenFontSize';

const Container = styled(WidgetContainerWithActiveStatus)`
    --lozengeMinWidth: 220px;
    --lozengeWidth: calc(20vw - var(--gridMarginGap));

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        --lozengeMinWidth: 200px;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        --lozengeWidth: calc(40vw - var(--gridMarginGap));
        --lozengeMinWidth: auto;
    }
`;

const TypeEditorFontSizeInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        const specimen = useSpecimenContext();
        const fontSizePx = useSpecimenFontSize(specimen);
        const [fontSize, setFontSize] = useFontSizeState();
        const maxSizeCap = specimen.type === SpecimenType.SMALL ? 60 : 40;

        return (
            <Container $activeStatus={activeStatus} title="Font size">
                <IconWithActiveStatus
                    type="FONT_SIZE"
                    $activeStatus={activeStatus}
                />
                <SliderWithActiveStatus
                    ref={forwardedRef}
                    value={[fontSize]}
                    min={10}
                    max={Math.max(fontSizePx * 2, maxSizeCap)}
                    onValueChange={(val): void =>
                        val.length ? setFontSize(val[0]) : undefined
                    }
                    $activeStatus={activeStatus}
                />
                <ValueWithActiveStatus $activeStatus={activeStatus}>
                    {printFontSizePx(fontSize)}
                </ValueWithActiveStatus>
            </Container>
        );
    },
);
TypeEditorFontSizeInput.displayName = 'TypeEditorFontSizeInput';

export default TypeEditorFontSizeInput;
