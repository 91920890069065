import React from 'react';
import styled, { css } from 'styled-components';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import ConditionalWrap from 'conditional-wrap';
import Button, { InlineButton } from './Button';
import Fieldset from './Fieldset';
import FieldText from './FieldText';
import FieldInputGroup from './FieldInputGroup';
import { VIEWPORT } from '../settings/Global';
import { ErrorCode } from '../gql/api-public';
import { newsletterSignupMutation } from '../utils/runtimeQueries';
import type {
    NewsletterSignupMutationMutation,
    NewsletterSignupMutationMutationVariables,
} from '../gql/api-public';
import { createSubscribeSuccessUrl } from '../utils/urlHelper';
import notNull from '../utils/notNull';
import { sentryException } from '../utils/sentry';
import { useErrorOverlayState } from './GlobalRuntimeState';

const Inner = styled.div<{ $isFooter?: boolean }>`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing4);
    margin-top: var(--spacing5);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        ${({ $isFooter }): ReturnType<typeof css> | null =>
            $isFooter
                ? css`
                      margin-top: 0;
                      margin-bottom: var(--spacing7);
                  `
                : null};
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        margin-top: var(--spacing3);
    }
`;

const Fields = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacing3) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const PageColumnButton = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const FooterColumnButton = styled.div``;

function ColumnButton({
    isFooter,
    children,
}: {
    isFooter?: boolean;
    children: React.ReactNode;
}): React.ReactElement {
    if (isFooter) {
        return <FooterColumnButton>{children}</FooterColumnButton>;
    }
    return <PageColumnButton>{children}</PageColumnButton>;
}

const SuccessMessage = styled.div`
    grid-column: 1 / -1;
    line-height: var(--lineHeightBody1);
`;

interface FormValues {
    email: string;
}

const defaultValues = {
    email: '',
};

function getErrorMessage(
    errorCode: ErrorCode | undefined,
    fieldLabel: string,
): string {
    switch (errorCode) {
        case ErrorCode.REQUIRED:
            return `Enter ${fieldLabel.toLowerCase()}`;
        case ErrorCode.USER_IS_LOGGED_IN:
            return `You are already signed in. Update your preferences from the Account section.`;
        case ErrorCode.ACCOUNT_EXISTS_FOR_EMAIL:
            return `An account for this email address already exists. Please sign in to update your preferences.`;
        case ErrorCode.INVALID:
        default:
            return `Enter a valid ${fieldLabel.toLowerCase()}`;
    }
}

interface Props {
    isFooter?: boolean;
}

function SubscribeForm({ isFooter }: Props): React.ReactElement {
    const [doNewsletterSignup] = useMutation<
        NewsletterSignupMutationMutation,
        NewsletterSignupMutationMutationVariables
    >(newsletterSignupMutation);
    const { register, handleSubmit, setError, formState, reset } =
        useForm<FormValues>({
            defaultValues,
        });
    const [successfullySubmittedEmail, setSuccessfullySubmittedEmail] =
        React.useState<string | undefined>();
    const [, setShowErrorOverlay] = useErrorOverlayState();

    const onSubmit = async (values: FormValues): Promise<void> => {
        try {
            const result = await doNewsletterSignup({
                variables: {
                    input: values,
                },
            });

            const errors =
                result?.data?.newsletterSignup?.errors?.filter(notNull) || [];

            if (errors.length > 0) {
                errors.forEach((error): void => {
                    // TODO: If !error.field display a generic page-wide error.

                    if (!error.messages || !error.field) {
                        return;
                    }

                    const fieldLabel =
                        error.field === 'email' ? 'Email address' : 'Name';

                    const errorCode = error.messages.pop();
                    const message = getErrorMessage(errorCode, fieldLabel);

                    setError(error.field as keyof FormValues, {
                        type: 'generic',
                        message,
                    });
                });
            } else {
                // Success.
                if (isFooter) {
                    reset();
                    setSuccessfullySubmittedEmail(values.email);
                } else {
                    await navigate(createSubscribeSuccessUrl(), {
                        replace: true,
                        state: {
                            email: values.email,
                        },
                    });
                }
            }
        } catch (e) {
            sentryException(e);
            setShowErrorOverlay({ isShown: true });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Inner $isFooter={isFooter}>
                <ConditionalWrap
                    condition={!isFooter}
                    wrap={(children): React.ReactElement => (
                        <Fieldset legend="Your details">{children}</Fieldset>
                    )}
                >
                    <Fields>
                        {successfullySubmittedEmail ? (
                            <SuccessMessage>
                                An email is on its way to{' '}
                                {successfullySubmittedEmail}. It contains a link
                                to verify your email address.
                            </SuccessMessage>
                        ) : (
                            <>
                                <FieldInputGroup
                                    input={
                                        <FieldText
                                            label="Email address"
                                            isError={!!formState.errors.email}
                                            {...register('email', {
                                                required:
                                                    'Enter an email address',
                                                maxLength: {
                                                    value: 255,
                                                    message:
                                                        'Email address must not exceed 255 characters',
                                                },
                                            })}
                                        />
                                    }
                                    error={formState.errors.email}
                                />
                                {isFooter ? (
                                    <div>
                                        <InlineButton
                                            $type="withBackground"
                                            type="submit"
                                            disabled={
                                                formState.isSubmitting ||
                                                undefined
                                            }
                                        >
                                            {formState.isSubmitting
                                                ? 'Subscribing…'
                                                : 'Subscribe'}
                                        </InlineButton>
                                    </div>
                                ) : null}
                            </>
                        )}
                    </Fields>
                </ConditionalWrap>

                {!isFooter ? (
                    <ColumnButton>
                        <Button
                            type="submit"
                            disabled={formState.isSubmitting || undefined}
                        >
                            {formState.isSubmitting
                                ? 'Subscribing…'
                                : 'Subscribe'}
                        </Button>{' '}
                    </ColumnButton>
                ) : null}
            </Inner>
        </form>
    );
}

export default React.memo(SubscribeForm);
