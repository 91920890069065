import React from 'react';
import { InView } from 'react-intersection-observer';
import { HeadingSmall } from './Heading';
import AccountUserOrderDetails from './AccountUserOrderDetails';
import useUserOrdersQuery from '../hooks/useUserOrdersQuery';
import { TEST_ID } from '../settings/E2e';
import { AccountRowData, AccountRowsContainer } from './AccountElements';

const ORDER_QUERY_LIMIT = 3;

export default function AccountOrderHistory(): React.ReactElement {
    const [limit, setLimit] = React.useState(ORDER_QUERY_LIMIT);
    const { loading, fetchMore, data } = useUserOrdersQuery({
        offset: 0,
        limit,
    });
    const orders = data?.user?.orders;
    const [doFetchMore, setDoFetchMore] = React.useState(true);

    return (
        <AccountRowsContainer data-cy={TEST_ID.ACCOUNT_ORDER_HISTORY}>
            {orders?.map(
                (order): React.ReactElement => (
                    <AccountUserOrderDetails order={order} key={order.id} />
                ),
            )}
            {loading ? (
                <AccountRowData>
                    <HeadingSmall>Loading purchases…</HeadingSmall>
                </AccountRowData>
            ) : doFetchMore ? (
                <InView
                    onChange={(inView): void => {
                        if (inView) {
                            const currentLength = orders?.length || 0;
                            fetchMore({
                                variables: {
                                    offset: currentLength,
                                    limit: ORDER_QUERY_LIMIT,
                                },
                            }).then((fetchMoreResult) => {
                                if (!fetchMoreResult.data.user?.orders.length) {
                                    setDoFetchMore(false);
                                }
                                setLimit(
                                    currentLength +
                                        (fetchMoreResult.data.user?.orders
                                            .length || 0),
                                );
                            });
                        }
                    }}
                ></InView>
            ) : orders?.length === 0 ? (
                <AccountRowData>
                    <HeadingSmall>No purchases in history.</HeadingSmall>
                </AccountRowData>
            ) : null}
        </AccountRowsContainer>
    );
}
