import React from 'react';
import type { Cart, Item } from '../hooks/useUserOrdersQuery';
import useAllFonts from '../hooks/useAllFonts';
import getLineItemUpgradeDescription from '../utils/getLineItemUpgradeDescription';
import getFontUrl from '../utils/getFontUrl';
import {
    ProductDescription,
    ProductDescriptionInline,
    ProductLink,
} from './CartSummaryInventory';
import { FontProductTypeChoice } from '../gql/api-public';
import styled, { css } from 'styled-components';
import { AccountRowDataLast, AccountRowItem } from './AccountElements';
import { CartAmount } from './CartSummaryRow';
import { TEST_ID } from '../settings/E2e';
import printPrice from '../utils/printPrice';

const ItemWrap = styled.div<{ $isRefunded: boolean }>`
    ${({ $isRefunded }): ReturnType<typeof css> | null =>
        $isRefunded
            ? css`
                  text-decoration: line-through;
                  text-decoration-thickness: 1px;
              `
            : null};
`;

export default function AccountCartLineItem({
    item,
    cart,
}: {
    item: Item;
    cart: Cart;
}): React.ReactElement {
    const fontFamilyGroups = useAllFonts();
    const { fontUrl, upgradeDescription, unifiedDescription, priceFormatted } =
        React.useMemo(() => {
            return {
                fontUrl: getFontUrl(item, fontFamilyGroups),
                upgradeDescription: getLineItemUpgradeDescription(item),
                unifiedDescription: item.productName || item.description,
                priceFormatted: (
                    <CartAmount data-cy={TEST_ID.CART_ITEM_PRICE}>
                        {printPrice(item.price, cart.nativeCurrency, true)}
                    </CartAmount>
                ),
            };
        }, [item, cart]);

    // For font styles we want to show the subtitle inline with the main description, rather than below it
    const descriptionSuffix =
        item.productSubtitle &&
        item.font?.fontProductType === FontProductTypeChoice.FONTSTYLE ? (
            <ProductDescriptionInline>
                {' '}
                {item.productSubtitle}
            </ProductDescriptionInline>
        ) : null;

    return (
        <>
            <AccountRowItem>
                <ItemWrap
                    $isRefunded={item.isRefunded}
                    title={item.isRefunded ? 'Item was refunded' : undefined}
                >
                    {fontUrl ? (
                        <ProductLink to={fontUrl}>
                            {unifiedDescription}
                        </ProductLink>
                    ) : (
                        unifiedDescription
                    )}
                    {descriptionSuffix}
                </ItemWrap>
                {item.description && item.description !== unifiedDescription ? (
                    <ProductDescription>{item.description}</ProductDescription>
                ) : item.productSubtitle && !descriptionSuffix ? (
                    <ProductDescription>
                        {item.productSubtitle}
                    </ProductDescription>
                ) : null}
                {upgradeDescription ? (
                    <ProductDescription>
                        {upgradeDescription}
                    </ProductDescription>
                ) : null}
            </AccountRowItem>
            <AccountRowDataLast>{priceFormatted}</AccountRowDataLast>
        </>
    );
}
