export default function getMainSubfamilyName(fontFamily: {
    hasVariableFonts: boolean;
    subfamilies: { name: string }[];
    mainSubfamily: { name: string } | null;
}): string | undefined {
    if (!fontFamily.hasVariableFonts) {
        return;
    }
    if (fontFamily.mainSubfamily) {
        return fontFamily.mainSubfamily.name;
    }
    if (fontFamily.subfamilies.length) {
        return fontFamily.subfamilies[0].name;
    }
}
