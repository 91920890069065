import React from 'react';
import styled, { css } from 'styled-components';
import type { Cart } from '../hooks/useCartQuery';
import printPrice from '../utils/printPrice';
import Icon, { IconType } from './Icon';
import { DiscountType } from '../gql/api-public';
import CartSummaryRow, {
    CartAmount,
    CartSummaryRowsWrapper,
} from './CartSummaryRow';
import { COLOR } from '../settings/Global';
import { useCurrentColorScheme } from './ColorSchemeContext';
import { TEST_ID } from '../settings/E2e';
import useCartQuery from '../hooks/useCartQuery';
import useIsDefaultColorScheme from '../hooks/useIsDefaultColorScheme';
import roundHalfUp from '../utils/roundHalfUp';

const Container = styled(CartSummaryRowsWrapper)`
    padding: var(--spacing2) 0;
`;

const Description = styled.div`
    color: var(--foregroundColorMix4);
`;

const Label = styled.div`
    font-feature-settings: 'tnum';
`;

interface RowData {
    testId?: TEST_ID;
    label: React.ReactNode;
    currencyCode: React.ReactNode;
    formattedAmount: string;
    description?: React.ReactNode;
    styleOverride?: ReturnType<typeof css> | null;
}

function useSubtotalsRowData(
    cart: Cart | null | undefined,
    discountColor: string,
    forAccountPage: boolean,
): RowData[] {
    const rows: RowData[] = [];
    if (!cart) {
        return rows;
    }
    if (!forAccountPage && !cart.amountTotal) {
        return rows;
    }

    // Row before total will have a different border
    const defaultStyleOverride = forAccountPage
        ? null
        : css`
              &:nth-last-child(2) {
                  border-bottom: 1px solid var(--foregroundColor);
              }
          `;

    // All discounts will be coloured...
    const discountStyleOverride = css`
        --textColor: ${discountColor};
    `;

    // Subtotal
    if (forAccountPage || cart.amountSubtotal !== cart.amountTotal) {
        rows.push({
            label: 'Subtotal',
            formattedAmount: printPrice(
                cart.amountSubtotal,
                cart.nativeCurrency,
                false,
            ),
            currencyCode: cart.nativeCurrency,
            styleOverride: defaultStyleOverride,
        });
    }

    const nonPackageDiscounts = cart.discounts.filter(
        (discount) =>
            discount.amount &&
            discount.amount > 0 &&
            discount.type !== DiscountType.PACKAGE,
    );

    // Package discount
    const packageDiscount = cart.discounts
        .filter(
            (discount) =>
                discount.amount && discount.type === DiscountType.PACKAGE,
        )
        .reduce(
            (previousValue, currentValue) =>
                previousValue + (currentValue.amount || 0),
            0,
        );
    if (packageDiscount > 0) {
        const percentage = roundHalfUp(
            (packageDiscount /
                cart.amountSubtotalForFontsWithLicenceMultiplier) *
                100,
        );
        rows.push({
            testId: TEST_ID.CART_ROW_PACKAGE_DISCOUNT,
            label: (
                <Label>{`${percentage}%${
                    cart.amountSubtotal !==
                    cart.amountSubtotalForFontsWithLicenceMultiplier
                        ? ' font'
                        : ''
                } package discount`}</Label>
            ),
            formattedAmount: printPrice(
                -packageDiscount,
                cart.nativeCurrency,
                false,
            ),
            currencyCode: cart.nativeCurrency,
            styleOverride: css`
                ${discountStyleOverride};
                ${defaultStyleOverride}
            `,
        });
    }

    // Non-package discounts
    nonPackageDiscounts
        .filter((discount) => Boolean(discount.amount))
        .forEach((discount) => {
            if (!discount.amount) {
                return;
            }
            rows.push({
                testId: TEST_ID.CART_ROW_OTHER_DISCOUNT,
                label: discount.percentage ? (
                    <Label>{`${discount.percentage}% discount`}</Label>
                ) : (
                    `Discount`
                ),
                formattedAmount: printPrice(
                    -discount.amount,
                    cart.nativeCurrency,
                    false,
                ),
                currencyCode: cart.nativeCurrency,
                styleOverride: css`
                    ${discountStyleOverride};
                    ${defaultStyleOverride}
                `,
                description: discount.description ? (
                    <Description>{discount.description}</Description>
                ) : undefined,
            });
        });

    // Currency conversion
    if (
        cart.usesCurrencyConversion &&
        cart.nativeCurrency != cart.currency &&
        (forAccountPage || cart.amountSubtotalConverted > 0)
    ) {
        rows.push({
            label: (
                <>
                    {cart.nativeCurrency} <Icon type={IconType.ARROW_RIGHT} />{' '}
                    {cart.currency}
                </>
            ),
            formattedAmount: printPrice(
                cart.amountSubtotalConverted,
                cart.currency,
                false,
            ),
            currencyCode: cart.currency,
            styleOverride: defaultStyleOverride,
        });
    }

    // Tax/GST
    if (cart.amountTax > 0) {
        rows.push({
            label: 'GST',
            formattedAmount: printPrice(cart.amountTax, cart.currency, false),
            currencyCode: cart.currency,
            styleOverride: defaultStyleOverride,
        });
    }

    // Refunds
    if (forAccountPage && cart.refundedAmount) {
        // cart.re
        rows.push({
            label: 'Refunded',
            formattedAmount: printPrice(
                -cart.refundedAmount,
                cart.currency,
                false,
            ),
            currencyCode: cart.currency,
            styleOverride: defaultStyleOverride,
        });
    }

    // Total
    rows.push({
        label: forAccountPage ? 'Total amount paid' : 'Total',
        testId: TEST_ID.CART_ROW_TOTAL,
        formattedAmount: printPrice(cart.amountTotal, cart.currency, false),
        currencyCode: cart.currency,
        styleOverride: forAccountPage
            ? css`
                  font-weight: bold;
              `
            : css`
                  ${defaultStyleOverride};
                  border-bottom: none;
                  ${cart.amountTotal === cart.amountSubtotal
                      ? // This is the only row, show a line
                        css`
                            border-top: 1px solid var(--foregroundColor);
                        `
                      : css`
                            border-top: none;
                        `};
                  font-weight: bold;
              `,
    });

    return rows;
}

function useCartDiscountColor(): string {
    const currentColorScheme = useCurrentColorScheme();
    const isDefaultTheme = useIsDefaultColorScheme();
    return isDefaultTheme ? COLOR.GREEN : currentColorScheme.highlightColor;
}

export function useCartSubtotalsRowData(
    cart: Cart | null | undefined,
    forAccountPage: boolean = false,
): {
    rowData: RowData[];
    longestAmountWidth: number;
} {
    const discountColor = useCartDiscountColor();
    const rowData = useSubtotalsRowData(cart, discountColor, forAccountPage);

    // We'll calculate the width of the amount "column", from the longest amount string (set in Söhne Mono),
    // so that we can use it to align the currency codes into their own "column", i.e.
    // Subtotal               USD  $800.00
    // 35% package discount   USD -$280.00
    // USD > NZD              NZD  $743.46
    // GST                    NZD   $11.52
    // -----------------------------------
    const longestAmountWidth = React.useMemo(
        () =>
            rowData.reduce((previousValue, currentValue) => {
                if (currentValue.formattedAmount.length > previousValue) {
                    return currentValue.formattedAmount.length;
                }
                return previousValue;
            }, 0),
        [rowData],
    );

    return { rowData, longestAmountWidth };
}

export function getPaddedAmount(amount: string, padLength: number): string {
    // Create a composite string of `currencyCode` and `formattedAmount`, but
    // with the amount string &nbsp;-padded to `longestAmountWidth` if it's shorter.
    // Gotta ❤ monospaced fonts.
    return amount.padStart(padLength + 1, '\u00A0');
}

function CartSummarySubtotals(): React.ReactElement | null {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const { rowData, longestAmountWidth } = useCartSubtotalsRowData(cart);

    // We make this conditional on the cart.amountTotal as we reset the total
    // amount in cache on the checkout success page, at which point we don't
    // want these totals to display anymore.
    if (!cart?.amountTotal) {
        return null;
    }

    return (
        <Container data-cy={TEST_ID.CART_TOTALS}>
            {rowData.map((row, index) => {
                return (
                    <CartSummaryRow
                        key={`cartSubtotalRow${index}`}
                        leftContent={row.label}
                        rightContent={
                            <CartAmount
                                data-cy={TEST_ID.CART_TOTALS_AMOUNT}
                            >{`${row.currencyCode}${getPaddedAmount(
                                row.formattedAmount,
                                longestAmountWidth,
                            )}`}</CartAmount>
                        }
                        belowContent={row.description}
                        styleOverride={row.styleOverride || undefined}
                        testId={row.testId}
                    />
                );
            })}
        </Container>
    );
}

export default React.memo(CartSummarySubtotals);
