import React from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import SVG from 'react-inlinesvg';

import { VIEWPORT } from '../settings/Global';
import { useFontFamily } from './PageContext';
import { A } from './Lozenge';
import forcePreserveAspectRatio from '../utils/forcePreserveAspectRatio';
import { useCurrentColorScheme } from './ColorSchemeContext';
import replaceSvgColors from '../utils/replaceSvgColors';
import { useGlobalState } from './GlobalRuntimeState';
import Icon, { IconType } from './Icon';
import { FontFamilySectionIds } from '../templates/FontFamilyPage';

const DetailsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: var(--gridColumnGap) var(--gridColumnGap);

    @media screen and (max-width: ${VIEWPORT.NETBOOK}px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: flex;
        align-items: stretch;
        overflow: auto hidden;
        width: 100vw;
        height: 100vw;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        margin: 0 calc(-1 * var(--gridMarginGap));

        &::-webkit-scrollbar {
            /* Remove scrollbar space and make invisible */
            display: none;
            width: 0;
            height: 0;
            background: transparent;
        }
    }
`;

const Pagination = styled.ol`
    display: none;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        display: block;
    }

    width: 100%;
    list-style: none;
    text-align: center;
    line-height: var(--fontSizeFixed5);
`;

const Page = styled.li`
    display: inline-block;
`;

const PageButton = styled.button<{ $isSelected: boolean }>`
    font-size: var(--fontSizeFixed5);
    color: var(--foregroundColorMix7);
    padding: 0 calc(var(--fontSizeFixed5) / 4);

    ${({ $isSelected }): ReturnType<typeof css> | null =>
        $isSelected
            ? css`
                  color: var(--foregroundColor);
              `
            : null}
`;

const Detail = styled.div`
    scroll-snap-align: center;

    /* Otherwise tries to cram them all into
       the width of a single slide. */
    flex-shrink: 0;
    width: 100%;
`;

const Svg = styled(SVG)`
    width: 100%;
    height: 100%;
    fill: var(--foregroundColor);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        width: 100vw;
        height: 100vw;
    }
`;

const Footer = styled.div`
    display: grid;
    grid-template-columns: var(--gridTemplateColumnsDefault);
    grid-column-gap: var(--gridColumnGap);
    margin-top: var(--spacing6);
`;

const All = styled.div`
    grid-column: 3 / -1;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 1 / -1;
    }
`;

function NotableDetails(): React.ReactElement {
    const [viewportWidth] = useGlobalState('viewportWidth');
    const [selectedDetailIndex, setSelectedDetailIndex] = React.useState(0);
    const detailsWrapperRef = React.useRef<HTMLDivElement>(null);
    const fontFamily = useFontFamily();
    const colorScheme = useCurrentColorScheme();

    const scrollToIndex = React.useCallback(
        (detailIndex: number) => {
            if (!detailsWrapperRef.current) return;

            const slideWidth =
                detailsWrapperRef.current.scrollWidth /
                fontFamily.notableDetails.length;
            detailsWrapperRef.current.scrollTo({
                left: slideWidth * detailIndex,
                top: 0,
                behavior: 'smooth',
            });
        },
        [detailsWrapperRef],
    );

    // Listen for slides scrolling and update currently visible slite state.
    // Only update that state as a result of the div being scrolled, never the other way around.
    React.useEffect(() => {
        if (!viewportWidth || viewportWidth > VIEWPORT.TABLET) {
            return;
        }

        const scrollHandler = (): void => {
            if (!detailsWrapperRef.current) return;

            const slidesWidth = detailsWrapperRef.current.offsetWidth;
            const { scrollLeft } = detailsWrapperRef.current;

            setSelectedDetailIndex(
                Math.min(
                    Math.max(Math.floor(scrollLeft / slidesWidth), 0),
                    fontFamily.notableDetails.length - 1,
                ),
            );
        };

        const debouncedScrollHandler = debounce(scrollHandler, 100, {
            maxWait: 100,
        });

        detailsWrapperRef.current?.addEventListener(
            'scroll',
            debouncedScrollHandler,
            {
                passive: true,
            },
        );

        const detailsWrapperRefCurrent = detailsWrapperRef.current;
        return (): void =>
            detailsWrapperRefCurrent?.removeEventListener(
                'scroll',
                debouncedScrollHandler,
            );
    }, [viewportWidth, detailsWrapperRef]);

    return (
        <>
            <DetailsWrapper ref={detailsWrapperRef}>
                {fontFamily.notableDetails.map(
                    (detail, index): React.ReactElement => (
                        <Detail key={`ndsvg${index}`}>
                            <Svg
                                src={replaceSvgColors(detail.svg, colorScheme)}
                                title={undefined}
                                description={`${fontFamily.name} detail ${
                                    index + 1
                                }`}
                                preProcessor={(svg): string =>
                                    forcePreserveAspectRatio(
                                        svg,
                                        'xMidYMid slice',
                                    )
                                }
                            />
                        </Detail>
                    ),
                )}
            </DetailsWrapper>
            <Pagination>
                {fontFamily.notableDetails.map((detail, index) => (
                    <Page key={`ndp${index}`}>
                        <PageButton
                            aria-label={`Detail ${index + 1}`}
                            onClick={(): void => scrollToIndex(index)}
                            $isSelected={index === selectedDetailIndex}
                        >
                            •
                        </PageButton>
                    </Page>
                ))}
            </Pagination>
            <Footer>
                <All>
                    <A href={`#${FontFamilySectionIds.GLYPHS}`}>
                        All glyphs&nbsp;
                        <Icon type={IconType.ARROW_DOWN} />
                    </A>
                </All>
            </Footer>
        </>
    );
}

export default React.memo(NotableDetails);
