import React from 'react';
import type { DraftInlineStyle } from 'draft-js';
import { useEditorState } from '../../components/TypeEditorContext';
import { OrderedSet } from 'immutable';

export default function useDraftJsCurrentInlineStyle(): DraftInlineStyle {
    const [editorState] = useEditorState();
    return React.useMemo(() => {
        // We reverse the style order here, as variable font style tokens are sometimes applied
        // multiple times with the same axis (e.g. `wght`) and we want to use the _last_ value
        // only. Reversing here means not having to do it everywhere throughout the rest of the code.
        return OrderedSet(editorState.getCurrentInlineStyle().reverse());
    }, [editorState]);
}
