import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import AccountCartInventory from './AccountCartInventory';
import AccountOrderHistoryForm from './AccountOrderHistoryForm';
import type { Order } from '../hooks/useUserOrdersQuery';
import Button, { ExternalLink } from './Button';
import useActiveLicenceTypes from '../hooks/useActiveLicenceTypes';
import { CMS_SSR_LicenceTypeId } from '../gql/api-ssr';
import formatDjangoDateString from '../utils/formatDjangoDateString';
import Icon, { IconType } from './Icon';
import { HeadingLarge } from './Heading';
import {
    AccountRowsContainer,
    AccountRow,
    AccountRowData,
    AccountRowLicence,
    AccountRowSeparator,
    AccountRowTier,
    AccountRowTitle,
    AccountSpacer,
    AccountRowSubtotalName,
    AccountRowSubtotalValue,
    AccountRowLicenceSubject,
    ActionsRow,
} from './AccountElements';
import {
    getPaddedAmount,
    useCartSubtotalsRowData,
} from './CartSummarySubtotals';
import { CartAmount } from './CartSummaryRow';

const HeadingWrap = styled(AccountRowData)`
    margin-bottom: var(--spacing4);
`;

const Heading = styled(HeadingLarge)`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        font-size: var(--fontSizeFixed5);
    }
`;

const UpgradeMessage = styled(AccountRowData)`
    border: 1px solid var(--borderColor);
    padding: var(--spacing2);
    margin-bottom: var(--spacing5);
`;

interface AccountUserOrderDetailsProps {
    order: Order;
}

function AccountUserOrderDetails({
    order,
}: AccountUserOrderDetailsProps): React.ReactElement {
    const [showUpgradeForm, setShowUpgradeForm] =
        React.useState<boolean>(false);
    const licenceTypes = useActiveLicenceTypes();
    const { rowData: subtotalsData, longestAmountWidth } =
        useCartSubtotalsRowData(order.cart, true);

    const downloadUrl = order.downloadUrl
        ? order.downloadUrl
        : order.invoiceUrl
          ? order.invoiceUrl
          : undefined;

    return (
        <>
            <HeadingWrap>
                <Heading>Order Nº {order.orderNumber}</Heading>
                <ActionsRow>
                    <ExternalLink
                        download
                        href={downloadUrl}
                        $type="tertiary"
                        $slim
                    >
                        Download {order.downloadUrl ? `fonts & ` : ''}
                        receipt&nbsp;&nbsp;
                        <Icon type={IconType.DOWNLOAD} />
                    </ExternalLink>
                    {showUpgradeForm || order.activeUpgradeRequest ? null : (
                        <Button
                            onClick={(): void => setShowUpgradeForm(true)}
                            $type="tertiary"
                            $slim
                        >
                            Upgrade order&nbsp;&nbsp;
                            <Icon type={IconType.RELOAD} />
                        </Button>
                    )}
                </ActionsRow>
            </HeadingWrap>
            {order.activeUpgradeRequest ? (
                <AccountRow>
                    <UpgradeMessage>
                        Your upgrade request has been successfully sent. We will
                        be in touch within the next few days.
                    </UpgradeMessage>
                </AccountRow>
            ) : showUpgradeForm ? (
                <AccountOrderHistoryForm
                    order={order}
                    setShowForm={setShowUpgradeForm}
                />
            ) : null}
            <AccountRowsContainer>
                <AccountRow>
                    <AccountRowTitle>Date</AccountRowTitle>
                    <AccountRowData>
                        {formatDjangoDateString(order.date)}
                    </AccountRowData>
                </AccountRow>

                {order.cart.licensedForSomeoneElse ? (
                    <AccountRow>
                        <AccountRowTitle>Purchased for</AccountRowTitle>
                        <AccountRowData>
                            {order.cart.licensedToCompanyName
                                ? order.cart.licensedToCompanyName
                                : order.cart.legacyLicensedTo}
                        </AccountRowData>
                    </AccountRow>
                ) : null}

                {order.cart.hasSimpleLicensing &&
                order.cart.licenceTiers.length > 0 ? (
                    <AccountRow>
                        <AccountRowTitle>Licence formats</AccountRowTitle>
                        {order.cart.licenceTiers
                            // Sort according to active licence types
                            .slice()
                            .sort((a, b) => {
                                const aIndex = licenceTypes.findIndex(
                                    (lt) => lt.id === a.tier.licenceType.id,
                                );
                                const bIndex = licenceTypes.findIndex(
                                    (lt) => lt.id === b.tier.licenceType.id,
                                );
                                // Can't be found? Sort to bottom...
                                if (aIndex === -1) {
                                    return 1;
                                }
                                if (bIndex === -1) {
                                    return -1;
                                }
                                return aIndex - bIndex;
                            })
                            .map((cartTier) => {
                                return (
                                    <React.Fragment key={cartTier.id}>
                                        <AccountRowLicence>
                                            {cartTier.tier.licenceType.name}
                                        </AccountRowLicence>
                                        {cartTier.tier.licenceType.id ===
                                        CMS_SSR_LicenceTypeId.UDLA ? null : (
                                            <AccountRowTier>
                                                {cartTier.tier.title}
                                            </AccountRowTier>
                                        )}
                                        {cartTier.licenceSubject ? (
                                            <AccountRowLicenceSubject>
                                                {cartTier.licenceSubject}
                                            </AccountRowLicenceSubject>
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                    </AccountRow>
                ) : null}

                <AccountCartInventory cart={order.cart} />

                <AccountRow>
                    {subtotalsData.map((row, idx) => {
                        const isLastRow = idx + 1 === subtotalsData.length;
                        return (
                            <React.Fragment key={`sub-${idx}`}>
                                {isLastRow ? <AccountRowSeparator /> : null}
                                <AccountRowSubtotalName css={row.styleOverride}>
                                    {row.label}
                                </AccountRowSubtotalName>
                                <AccountRowSubtotalValue
                                    css={row.styleOverride}
                                >
                                    <CartAmount>{`${row.currencyCode}${getPaddedAmount(
                                        row.formattedAmount,
                                        longestAmountWidth,
                                    )}`}</CartAmount>
                                </AccountRowSubtotalValue>
                            </React.Fragment>
                        );
                    })}
                </AccountRow>
            </AccountRowsContainer>
            <AccountSpacer />
        </>
    );
}

export default React.memo(AccountUserOrderDetails);
