import React from 'react';
import styled from 'styled-components';
import {
    Paragraph as DefaultParagraph,
    ParagraphMarginStyles,
} from '../components/Paragraph';
import { Accordion, AccordionItem } from '../components/Accordion';
import {
    createAccountUrl,
    createBlogIndexUrl,
    createCustomFontsMenuUrl,
    createEulaUrl,
    createFacebookUrl,
    createFontDesignInfoUrl,
    createEulaOverviewUrl,
    createFontsInUseUrl,
    createFontsMenuUrl,
    createHomeUrl,
    createInstagramUrl,
    createInterviewUrl,
    createKlimInUseEmailUrl,
    createMediaEmailUrl,
    createPinterestUrl,
    createSalesEmailUrl,
    createStripeUrl,
    createSubscribeUrl,
    createSupportEmailUrl,
    createTestFontsUrl,
    createTumblrlUrl,
    createTwitterUrl,
    createFaqUrl,
} from '../utils/urlHelper';
import Link, { A as ExternalLink } from '../components/InlineUnderlinedLink';
import {
    Blockquote as _Blockquote,
    Code as _Code,
    Li,
    Ul as DefaultUl,
} from '../components/Primitives';
import { CurrencyChoices, LicenceTypeId } from '../gql/api-public';

const Ul = styled(DefaultUl)`
    ${ParagraphMarginStyles};
    &:last-child {
        margin-bottom: 0;
    }
`;

const Paragraph = styled(DefaultParagraph)`
    ${ParagraphMarginStyles};
    &:last-child {
        margin-bottom: 0;
    }

    i {
        font-style: italic;
    }

    b {
        font-weight: bold;
    }
`;

const Blockquote = styled(_Blockquote)`
    ${ParagraphMarginStyles};
    &:last-child {
        margin-bottom: 0;
    }
`;

const Code = styled(_Code)`
    ${ParagraphMarginStyles};
    &:last-child {
        margin-bottom: 0;
    }
`;

interface AccordionItem {
    content: React.ReactNode;
    subHeading: React.ReactNode;
}

interface Accordion {
    id: string;
    heading: string;
    items: AccordionItem[];
}

function jumpLink(accordion: Accordion): string {
    return `${createFaqUrl()}#${accordion.id}`;
}

/* ============================================================================
 * UPGRADES
 * ========================================================================== */

export const upgradesAccordion: Accordion = {
    id: 'upgrades',
    heading: 'Upgrades',
    items: [
        {
            subHeading: 'How can I upgrade my font licence?',
            content: (
                <>
                    <Paragraph>
                        You can upgrade your desktop, wep, app, advertising,
                        broadcast or OEM font licence with us at any stage.
                    </Paragraph>
                    <Paragraph>
                        To upgrade your licence order, either:
                    </Paragraph>
                    <Ul>
                        <Li>
                            sign in to your{' '}
                            <Link to={createAccountUrl()}>Klim account</Link>{' '}
                            and select ‘Upgrade order’ on the previous order you
                            wish to upgrade
                        </Li>
                        <Li>
                            or contact{' '}
                            <ExternalLink href={createSupportEmailUrl(true)}>
                                {createSupportEmailUrl()}
                            </ExternalLink>{' '}
                            to arrange your upgrade.
                        </Li>
                    </Ul>
                    <Paragraph>
                        You can use the drop-down menus to view the licence tier
                        options for each licence type on our retail font ‘Buy’
                        pages. All pricing and discounting is shown as you add
                        items to the cart.
                    </Paragraph>
                    <Paragraph>
                        Upgrades only apply to licence purchases for fonts from
                        the same font family.
                    </Paragraph>
                    <Paragraph>
                        Below is a list of the ways that you can ‘upgrade’ your
                        font licence.
                    </Paragraph>
                    <Paragraph>You can upgrade from:</Paragraph>
                    <Ul>
                        <Li>
                            an individual style to add one or more styles (e.g.
                            Calibre Regular to Calibre Regular, Medium & Bold)
                        </Li>
                        <Li>
                            an individual style to the full family, or to a
                            collection (e.g. National 2 family to National 2
                            Collection)
                        </Li>
                        <Li>
                            a few font styles to the full family, or to a
                            collection
                        </Li>
                        <Li>
                            a family to a collection (e.g. Tiempos Text family
                            to Tiempos collection, which includes all styles of
                            Tiempos Text, Tiempos Headline and Tiempos Fine)
                        </Li>
                        <Li>
                            your current ‘number of users’ to an increased
                            number of users
                        </Li>
                        <Li>
                            your current ‘page views’ to an increased tier of
                            page views
                        </Li>
                        <Li>
                            your current ‘unique users’ to an increased tier of
                            unique users
                        </Li>
                        <Li>
                            your current ‘MAUs’ or ‘downloads’ to an increased
                            number of ‘MAUs’ or ‘downloads’.
                        </Li>
                        <Li>
                            your current ‘monthly impressions’ to an increased
                            number of monthly impressions.
                        </Li>
                        <Li>
                            your current ‘production budget’ or ‘audience size’
                            to an increased ‘production budget’ or ‘audience
                            size’.
                        </Li>
                        <Li>
                            your current ‘number of devices’ to an increased
                            number of devices.
                        </Li>
                        <Li>
                            your current licence type or ‘format’ (e.g. desktop)
                            to add the same fonts for another licence
                            type/format, e.g. web, app, advertising,
                            broadcasting or OEM licensing.
                        </Li>
                    </Ul>
                    <Paragraph>
                        We’ll only charge you the difference between what you’ve
                        already paid and the new, upgraded licence fee.
                    </Paragraph>
                    <Paragraph>
                        The below are ways that you <b>cannot</b>
                        {` `}
                        ‘upgrade’ your font licence with us:
                    </Paragraph>
                    <Ul>
                        <Li>
                            from one individual font style from a font family to
                            add a font style from another family, (e.g. Calibre
                            Regular to add Founders Grotesk Bold)
                        </Li>
                        <Li>
                            from one font family or collection for one licence
                            type, for example a desktop licence, to add another,
                            different font family for another licence type, for
                            example a web licence (e.g. Calibre family desktop
                            fonts to add National 2 web fonts)
                        </Li>
                        <Li>
                            from one font family to add another family from that
                            collection (e.g. from Tiempos Text to add Tiempos
                            Headline)
                        </Li>
                        <Li>
                            from web fonts for one website to add web fonts for
                            another completely separate website. (If you wish to
                            use the fonts across multiple primary web domains
                            you’ll need our Unlimited Web Font licence,{' '}
                            <ExternalLink href={createSupportEmailUrl(true)}>
                                contact us
                            </ExternalLink>{' '}
                            to discuss).
                        </Li>
                        <Li>
                            from app fonts for one app to add app fonts for
                            another completely separate app. (If you wish to use
                            the fonts across multiple unique apps you’ll need
                            our Unlimited App Font licence,{' '}
                            <ExternalLink href={createSupportEmailUrl(true)}>
                                contact us
                            </ExternalLink>{' '}
                            to discuss).
                        </Li>
                    </Ul>
                    <Paragraph>
                        For all of the examples directly above you need to
                        purchase a new font licence from our website, or from
                        our team directly. There are no discounts or upgrades
                        available for these.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * LICENSING
 * ========================================================================== */

const licencingAccordion: Accordion = {
    heading: 'Licensing',
    id: 'licensing',
    items: [
        {
            subHeading: 'What is a font licence?',
            content: (
                <>
                    <Paragraph>
                        A font licence gives you the right to use a font in
                        certain, specified ways. You do not own the font itself.
                        The{' '}
                        <Link to={createEulaOverviewUrl()}>Font Licence</Link>{' '}
                        specifies the terms and conditions of use and, once you
                        finalise the purchase of the licence, is a legally
                        binding contract between you and Klim Type Foundry.
                    </Paragraph>
                    <Paragraph>
                        The licensing fee is a one-off cost for ongoing use of
                        the fonts, as per the terms of the licence you purchase.
                        There are no recurring fees. Although you are required
                        to <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence if your use of the fonts increases.
                    </Paragraph>
                    <Paragraph>
                        The fonts can be used worldwide and all licences have no
                        expiry.
                    </Paragraph>
                    <Paragraph>
                        We offer font licences for{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            desktop
                        </Link>
                        , <Link to={createEulaUrl(LicenceTypeId.WEB)}>web</Link>
                        , <Link to={createEulaUrl(LicenceTypeId.APP)}>app</Link>
                        ,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.AD)}>
                            advertising
                        </Link>
                        ,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.BROADCAST)}>
                            broadcasting
                        </Link>{' '}
                        and{' '}
                        <Link to={createEulaUrl(LicenceTypeId.OEM)}>OEM</Link>{' '}
                        use on our website. We also have a range of other font
                        licences available on request. Please see the
                        information about all available font licensing on our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page.
                    </Paragraph>
                    <Paragraph>
                        It’s important that you read and understand the
                        applicable font licence terms before making a purchase
                        with us.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'Which font licence is right for me?',
            content: (
                <>
                    <Paragraph>
                        Please see the information about all available font
                        licensing on our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page. This provides an overview of the most common ‘use
                        cases’ with a match to the best font licence.
                    </Paragraph>
                    <Paragraph>
                        We offer font licences for{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            desktop
                        </Link>
                        , <Link to={createEulaUrl(LicenceTypeId.WEB)}>web</Link>{' '}
                        and{' '}
                        <Link to={createEulaUrl(LicenceTypeId.APP)}>app</Link>,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.AD)}>
                            advertising
                        </Link>
                        ,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.BROADCAST)}>
                            broadcasting
                        </Link>{' '}
                        and{' '}
                        <Link to={createEulaUrl(LicenceTypeId.OEM)}>OEM</Link>{' '}
                        use via our website. We also have a range of other font
                        licences available on request.
                    </Paragraph>
                    <Paragraph>
                        If your use case is not included please contact us at{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to discuss.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Where do I find the font licensing agreements on your website?',
            content: (
                <>
                    <Paragraph>
                        You can find an overview of all available font licensing
                        on our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page, as well as specific web pages with the terms of
                        our{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            desktop
                        </Link>
                        , <Link to={createEulaUrl(LicenceTypeId.WEB)}>web</Link>
                        , <Link to={createEulaUrl(LicenceTypeId.APP)}>app</Link>
                        ,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.AD)}>
                            advertising
                        </Link>
                        ,{' '}
                        <Link to={createEulaUrl(LicenceTypeId.BROADCAST)}>
                            broadcasting
                        </Link>
                        , <Link to={createEulaUrl(LicenceTypeId.OEM)}>OEM</Link>{' '}
                        and{' '}
                        <Link to={createEulaUrl(LicenceTypeId.TEST)}>
                            test licences
                        </Link>
                        .
                    </Paragraph>
                    <Paragraph>
                        You can use the site’s Menu to navigate to the{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        section of the site. You can also link to these licence
                        pages via our ‘Buy’ web pages when purchasing any retail
                        font (click on ‘Learn more’).
                    </Paragraph>
                    <Paragraph>
                        It is your responsibility to read and understand the
                        font licences, so we recommend you do this before making
                        a purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'Can I licence a single font style?',
            content: (
                <>
                    <Paragraph>
                        Yes. All of our fonts are available as individual
                        styles. The base price is $60 {CurrencyChoices.USD} for
                        the first font style. Each subsequent font style is
                        added at a discounted rate.
                    </Paragraph>
                    <Paragraph>
                        You can also purchase a licence for font families.
                        Purchasing font licences in bulk like this is cheaper
                        than licensing each individual style. The cart reflects
                        discounts as you add items to it.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Do you offer different licensing agreements than those shown on your website?',
            content: (
                <>
                    <Paragraph>
                        Yes, we offer a range of other{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        to suit specific ‘use cases’ that don‘t fit within the
                        terms of our standard licence agreements for desktop,
                        web and app. Please see our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page for more information about these options.
                    </Paragraph>
                    <Paragraph>
                        We have various unlimited licensing options available
                        for corporate use.
                    </Paragraph>
                    <Paragraph>
                        If your use case is not included please contact us at{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to discuss. In special circumstances we may be able to
                        tailor a licence to your specific needs.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Who should purchase the font licence if there’s a parent company with several subsidiaries?',
            content: (
                <>
                    <Paragraph>
                        The parent company should purchase the{' '}
                        <Link to={createEulaOverviewUrl()}>font licence</Link>,
                        which can then be shared across that parent company’s
                        subsidiaries or divisions, as long as all parties comply
                        with the font licence terms. It is important to note
                        that the parent company will be responsible for ensuring
                        its subsidiaries or divisions are compliant with the
                        licence terms.
                    </Paragraph>
                    <Paragraph>The font licence(s) must cover:</Paragraph>
                    <Ul>
                        <Li>
                            the correct amount of users across all subsidiaries
                            or divisions (desktop fonts)
                        </Li>
                        <Li>
                            the correct number of monthly page views or monthly
                            unique users for all websites that the fonts will be
                            used on across all subsidiaries or divisions (web
                            fonts)
                        </Li>
                        <Li>
                            the correct number of active subscribers (for email
                            marketing) and/or impressions (for dynamic web-based
                            advertising), in addition to monthly page views or
                            monthly unique users for all websites across all
                            subsidiaries or divisions, if relevant to your
                            business (web fonts)
                        </Li>
                        <Li>
                            the correct number of monthly active users (MAUs) or
                            total downloads for all applications (apps) that the
                            fonts will be used on across all subsidiaries or
                            divisions (app fonts).
                        </Li>
                        <Li>
                            the correct number of monthly impressions, as per
                            your media buy (advertising fonts).
                        </Li>
                        <Li>
                            the correct production budget or audience size
                            (broadcasting fonts).
                        </Li>
                        <Li>
                            the correct number of devices that your company
                            intends to produce (OEM fonts).
                        </Li>
                    </Ul>
                    <Paragraph>
                        Please read the relevant{' '}
                        <Link to={createEulaOverviewUrl()}>font licence</Link>{' '}
                        agreement before making a purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'Can I share your fonts with my client or contractor?',
            content: (
                <>
                    <Paragraph>
                        Yes, but there are terms and conditions. Under the terms
                        of our standard desktop font licence you can share the
                        font files with any authorised people or parties outside
                        of your organisation, including clients, contractors,
                        freelance employees, vendors, partners, agencies,
                        affiliates, suppliers or other associates for purposes
                        related to your business. As the licence holder, you
                        must ensure that all third parties are aware of and
                        comply with the terms of the{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            Desktop Font Licence
                        </Link>
                        . Your licence must cover all intended users within and
                        outside of your company or organisation. If your use
                        increases over time, you must{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence with us.
                    </Paragraph>
                    <Paragraph>
                        In scenarios where there is a client and design or
                        similar creative agency working together, we recommend
                        that the client purchases the desktop font licence ,
                        becoming the licence holder and end user. They can then
                        share the fonts with their agency and other third
                        parties for purposes associated with their business,
                        brand and/or projects.
                    </Paragraph>
                    <Paragraph>
                        Although, if preferred, licences can still be purchased
                        by a design or similar creative agency on a client’s
                        behalf. Use the ‘Fonts are for someone else’ option on
                        the Checkout page and enter their details.
                    </Paragraph>
                    <Paragraph>
                        Under the terms of our standard web and app font
                        licences, you may also share our web or app fonts with
                        your developer(s) for purposes related to work on your
                        website or app, but please read the specific terms and
                        conditions related to this within the relevant licence
                        agreements. See sub-clause <b>3i.</b> of the{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.WEB,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            Web
                        </Link>{' '}
                        or{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.APP,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            App
                        </Link>{' '}
                        Font Licence.
                    </Paragraph>
                    <Paragraph>
                        Please note that the purchasing for ‘someone else’
                        function on our website is designed as a convenience to
                        allow design or similar creative agencies and web or app
                        developers to purchase the fonts on their client’s
                        behalf in order to use the fonts to develop brand
                        artwork and assets for them. Under no circumstance
                        should these creative agencies and/or developers resell
                        or profit in any way from this font licence purchase.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * TEST FONTS
 * ========================================================================== */

const testFontsAccordion: Accordion = {
    id: 'test-fonts',
    heading: 'Test fonts',
    items: [
        {
            subHeading: 'Do you have fonts I can test?',
            content: (
                <Paragraph>
                    Yes.{' '}
                    <Link to={createTestFontsUrl()}>Download them here.</Link>{' '}
                    Our test font download includes all{' '}
                    <Link to={createFontsMenuUrl()}>retail fonts</Link> in our
                    catalogue.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * PAYMENT
 * ========================================================================== */

const paymentAccordion: Accordion = {
    id: 'payment',
    heading: 'Payment',
    items: [
        {
            subHeading: 'What payment methods do you accept?',
            content: (
                <Paragraph>
                    We accept payments from Visa, MasterCard and American
                    Express (AMEX) credit cards on our website. We do not accept
                    PayPal or Bitcoin. We can prepare an invoice for
                    international bank transfer. Please contact{' '}
                    <ExternalLink href={createSupportEmailUrl(true)}>
                        {createSupportEmailUrl()}
                    </ExternalLink>{' '}
                    to discuss. For any international credit card payments on
                    our site you may be charged additional transaction fees by
                    your card issuer. Klim has no control over these fees. For
                    any international payments made by invoice and bank transfer
                    you may also be charged international payment fees by your
                    bank for the transaction. You must cover the cost of these
                    fees. You are not permitted to deduct this fee amount from
                    the Klim invoice total.
                </Paragraph>
            ),
        },
        {
            subHeading: 'My transaction has failed, what do I do?',
            content: (
                <Paragraph>
                    Please contact your card issuer directly to resolve any
                    problem that has occurred with your credit card transaction.
                    We do not authorise your card, your card issuer does. Klim
                    Type Foundry uses{' '}
                    <ExternalLink href={createStripeUrl()}>Stripe</ExternalLink>{' '}
                    to securely process credit card data. We can not see your
                    private details or the reason why your purchase has been
                    declined.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Does Klim offer additional discounts to those on your website?',
            content: (
                <>
                    <Paragraph>
                        In short, no. When you purchase more than one font style
                        with us, it comes with a discount on the base price of
                        $60 USD for each subsequent font you add to your order.
                        For example, if you purchase National Regular as an
                        individual style for our base licence (‘5 users’) you’ll
                        pay $60 USD, whereas you can buy National Regular and
                        National Bold it will cost $102 USD, a saving of $18 USD
                        or 15%. For 3 styles of National (e.g. Regular, Bold and
                        Medium) you would receive a saving of $36 USD or 20%,
                        and so on. Our font families and collections are also
                        already priced to include a bulk discount.
                    </Paragraph>
                    <Paragraph>
                        Font licence prices are also adjusted to include a
                        discount as the licence tier quantity increases. For
                        example, if you purchase the Calibre family desktop
                        licence (for ‘5 users’) it would cost you $420 USD. If
                        the number of users is increased by 5 to a total of 10,
                        (the ‘10 users’ licence), you only pay an extra $210 USD
                        (a total cost of $630 USD), so that’s a saving of
                        approximately $210 USD or roughly a 25 percent discount.
                    </Paragraph>
                    <Paragraph>
                        There are combo discounts available for licence
                        purchases across the desktop/web/app font format
                        categories (or ‘licence types’) too. For example, if you
                        purchase the Calibre family for desktop, there’s a
                        package discount offered if you wish to purchase the
                        Calibre family for web, and/or for app.
                    </Paragraph>
                    <Paragraph>
                        These package discounts only apply to purchases of the
                        same fonts. That is, for the same font styles from one
                        family (e.g. Calibre Regular and Bold for desktop and
                        web), or the same family or collection (e.g. Tiempos
                        Fine Family for web and app, or National 2 Collection
                        for desktop and web). If you want to buy 3 styles from 3
                        different font families for desktop, web or app (e.g.
                        Calibre Regular for desktop, National Bold for web and
                        Tiempos Text Semibold for app), then the package
                        discount does not apply. The same goes for different
                        families or collections for different licence types
                        (e.g. National 2 Family for desktop and Domaine Display
                        for web).
                    </Paragraph>
                    <Paragraph>
                        If you ever{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your{' '}
                        <Link to={createEulaOverviewUrl()}>Font Licence</Link>{' '}
                        we’ll only charge you the difference between what you’ve
                        already paid and the upgrade fee.
                    </Paragraph>
                    <Paragraph>
                        We do not provide student or educational discounts,
                        sorry.
                    </Paragraph>
                    <Paragraph>
                        We do offer special discounts for registered New Zealand
                        charities. Please contact{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to inquire about this.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * ORDERING
 * ========================================================================== */

const orderingAccordion: Accordion = {
    heading: 'Ordering',
    id: 'ordering',
    items: [
        {
            subHeading: 'How and when will I get the fonts from Klim?',
            content: (
                <>
                    <Paragraph>
                        Upon successful payment, you will get an immediate
                        download with the required font files in a .zip folder.
                    </Paragraph>
                    <Paragraph>
                        The font file formats you’ll receive are as follows:
                    </Paragraph>
                    <Ul>
                        <Li>
                            Desktop, Broadcasting and OEM font files: OpenType
                            Format (OTF), Variable Fonts in TrueType Format
                            (TTF)
                        </Li>
                        <Li>
                            Web font files: Web Open Font Format 2.0 (WOFF2)
                        </Li>
                        <Li>App font files: TrueType Format (TTF)</Li>
                    </Ul>
                    <Paragraph>
                        Note that our{' '}
                        <Link to={createEulaUrl(LicenceTypeId.AD)}>
                            Advertising Font Licence
                        </Link>{' '}
                        does not include font files. You will need to purchase
                        an additional{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            Desktop Font Licence
                        </Link>{' '}
                        to receive font files.
                    </Paragraph>
                    <Paragraph>
                        You can also access your order and font download at any
                        time via your Klim account. To do this, visit{' '}
                        <Link to={createAccountUrl()}>account</Link> and sign in
                        using the email you used to make the purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Oops! I’ve ordered the wrong font by mistake, can you help me?',
            content: (
                <Paragraph>
                    If you’ve accidentally purchased a licence for the wrong
                    font weight/style or font format (e.g. desktop font instead
                    of web) please contact{' '}
                    <ExternalLink href={createSupportEmailUrl(true)}>
                        {createSupportEmailUrl()}
                    </ExternalLink>{' '}
                    for assistance as soon as possible, and no later than 5 days
                    after purchase.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * ACCOUNT
 * ========================================================================== */

const accountAccordion: Accordion = {
    heading: 'My account',
    id: 'account',
    items: [
        {
            subHeading: 'Why do I have an account?',
            content: (
                <Paragraph>
                    Your account is created automatically upon checkout after a
                    successful purchase. If you haven’t purchased a font from{' '}
                    <Link to={createHomeUrl()}>klim.co.nz</Link> you will not
                    yet have an account.
                </Paragraph>
            ),
        },
        {
            subHeading: 'What is in my account?',
            content: (
                <Paragraph>
                    You will be able to access your font downloads and sales
                    receipts from any previous orders anytime by signing in to
                    your Klim account.
                </Paragraph>
            ),
        },
        {
            subHeading: 'How do I sign in to my account?',
            content: (
                <Paragraph>
                    To access your Klim account, visit{' '}
                    <Link to={createAccountUrl()}>
                        klim.co.nz{createAccountUrl()}
                    </Link>{' '}
                    and enter the email address you used to purchase the fonts.
                    You will be sent an email for confirmation, which will then
                    allow you to sign in to your account.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * REFUNDS
 * ========================================================================== */

const refundsAccordion: Accordion = {
    id: 'refunds',
    heading: 'Refunds',
    items: [
        {
            subHeading: 'Do you offer refunds if I change my mind?',
            content: (
                <Paragraph>
                    Sorry, no. All font licensing sales are final.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * DESKTOP FONTS
 * ========================================================================== */

const desktopFontsAccordion: Accordion = {
    id: 'desktop-fonts',
    heading: 'Desktop fonts',
    items: [
        {
            subHeading: 'What will I receive when I buy your desktop fonts?',
            content: (
                <>
                    <Paragraph>
                        Upon a successful payment, you will get an immediate
                        download with the required font files in a .zip folder.
                        The desktop font files are OpenType Format (OTF) and
                        Variable Fonts are TrueType Format (TTF).
                    </Paragraph>
                    <Paragraph>
                        You can also access your order and font download at any
                        time via your Klim account. To do this, visit{' '}
                        <Link to={createAccountUrl()}>account</Link> and sign in
                        using the email you used to make the purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'What if I want to use the fonts on less than the minimum desktop licence for ‘5 users’?',
            content: (
                <Paragraph>
                    The minimum or ‘base’ desktop font licence is technically up
                    to ‘5 users’ (1-5) meaning anything less than 5 is captured
                    within this price tier. The price tiers for the number of
                    users are fixed and non-negotiable.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'What if I want to use the fonts for a number of users that’s not specified on your website?',
            content: (
                <Paragraph>
                    The price tiers for the number of users are fixed ranges
                    (e.g. 1–5 users, 6–10 users, 11–20, and so forth), so you
                    would need to purchase the licence that allows for the
                    number of users who will be installing and using the fonts.
                    For example, if you have 6 users you’ll need the desktop
                    font licence for ‘10 users’. Each user can install the fonts
                    on as many devices as required for their work with your
                    company or organisation. It’s important that you understand
                    the terms and conditions of the font licences, as you are
                    responsible for ensuring that only the number of users you
                    have paid for use the fonts.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Do all the users need to be in the same physical location?',
            content: (
                <Paragraph>
                    No, they don’t need to be in the same physical location, but
                    they must all be employed or authorised by you. Note that a
                    ‘user’ can install the fonts on any device including, but
                    not limited to: a desktop computer, a laptop, a tablet, or a
                    mobile phone, that is required for their work with your
                    company or organisation.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Do the computers that I install the desktop fonts on need to be in the same physical place?',
            content: (
                <Paragraph>
                    No, they don’t need to be in the same physical place, but
                    they must all be owned by you, or by someone you have
                    authorised to use the fonts. Note that a ‘computer’ can
                    include any device including, but not limited to: a desktop
                    computer, a laptop, a tablet, or a mobile phone.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'If I hold a licence for desktop fonts can I use these fonts on the web or do I need a separate licence?',
            content: (
                <Paragraph>
                    No, you can not use desktop fonts on the web. You’ll need to
                    purchase a separate{' '}
                    <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                        Web Font Licence
                    </Link>{' '}
                    which will provide you with the correct font file formats to
                    use Klim fonts on the web. Desktop fonts can only be used
                    online within static image files, e.g. as a .jpg. or .png.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Can I provide the desktop fonts to a print supplier?',
            content: (
                <>
                    <Paragraph>
                        Yes, you may provide the desktop font files to printers
                        when required, but you both must comply with <b>3e.</b>{' '}
                        of the{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.DESKTOP,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            Desktop Font Licence
                        </Link>{' '}
                        agreement, as per below, and all other licence terms:
                    </Paragraph>
                    <Blockquote>
                        3e. Service Bureau/Printers
                        <br />
                        <br />
                        To reproduce a particular document, You may provide a
                        copy of the specified Fonts to a commercial printer or
                        service bureau. Afterwards, the printer or service
                        bureau must destroy the copies of the Fonts from their
                        devices and electronic storage, including email, and You
                        agree to take reasonable steps to ensure this happens.
                    </Blockquote>
                </>
            ),
        },
        {
            subHeading: 'Can I use your fonts on my iPad?',
            content: (
                <Paragraph>
                    No, you cannot install our fonts on iPads, sorry. Apple
                    officially allow installation of third-party fonts on iOS
                    through specially developed apps. However, Klim does not
                    have immediate plans to develop and support an iOS app for
                    the installation of our fonts.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Can I purchase a licence for Klim retail fonts and modify some of the characters to create a logo?',
            content: (
                <Paragraph>
                    Yes, you are permitted to do this. You must first purchase a{' '}
                    <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                        Desktop Font Licence
                    </Link>{' '}
                    for the font you wish to use. You may then convert a
                    restricted set of letters to outlines and modify these for
                    your logo, but you can not modify the full font file. All
                    aspects of the{' '}
                    <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                        Desktop Font Licence
                    </Link>{' '}
                    must be complied with.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Can I change users over time?',
            content: (
                <Paragraph>
                    Yes, you may change users within and outside of Your
                    organisation over time. You must ensure that former users
                    cease to use the fonts and delete the font files from their
                    devices and electronic storage, including email.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Can I use the fonts within my software subscriptions?',
            content: (
                <Paragraph>
                    Yes, the fonts can be installed for use within software
                    subscription services, for example, Adobe’s Creative Cloud
                    or similar. You can use our test fonts to check that the
                    fonts work as you want them to before purchase.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * WEB FONTS
 * ========================================================================== */

const webFontsAccordion = {
    heading: 'Web fonts',
    id: 'web-fonts',
    items: [
        {
            subHeading: 'What counts as a web domain?',
            content: (
                <>
                    <Paragraph>
                        Our{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.WEB,
                                '#definitions',
                            )}
                        >
                            Web Font Licence
                        </Link>{' '}
                        agreement specifies what a ‘domain’ is in <b>1f.</b>, as
                        per below:
                    </Paragraph>
                    <Blockquote>
                        1f. The “Domain” is the host name of a Website’s home
                        page. The Domain may be comprised of one unique
                        second-level Domain name (as in “example” of
                        https://www.example.com), multiple top-level Domains (as
                        in .com, .org, .fr), and multiple sub-Domains (as in
                        “shop” of shop.example.com, or “blog” of
                        blog.example.com). Under this license You must own the
                        Domain and control the content of the licensed Website.
                    </Blockquote>
                    <Paragraph>
                        In other words, it’s the central part of the domain name
                        (e.g. blog.example.com) that matters most. If this part
                        of the URL is different for two websites that your
                        company operates then they count as two separate primary
                        domains, which will require two distinct web font
                        licences. This naming convention only applies to the
                        company’s public-facing websites. For any internal
                        website or intranet site, which must be directly related
                        to and owned by your business, these naming conventions
                        do not apply. Your licence must, however, cover the
                        total number of average monthly unique users or page
                        views across all of your sites. If your use increases
                        over time you must{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'How do I calculate page views?',
            content: (
                <>
                    <Paragraph>
                        A page view is one request for the viewing of a page on
                        your website. Font licensing is based on your average
                        monthly page views (or monthly unique users if you
                        prefer this metric). To calculate the average monthly
                        page views for your website: measure the page views over
                        the span of three consecutive months, i.e. add the
                        totals together, then divide the total by three.
                    </Paragraph>
                    <Paragraph>
                        You can choose which of the two metrics you wish to use
                        when licensing our web fonts. You cannot later switch
                        from one metric to another so please choose carefully.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'How do I calculate unique users?',
            content: (
                <>
                    <Paragraph>
                        A unique user is an individual visitor to your website.
                        Font licensing is based on your average monthly unique
                        users (or page views if you prefer this metric). To
                        calculate the average monthly unique users for your
                        website: measure the unique users over the span of three
                        consecutive months, i.e. add the totals together, then
                        divide the total by three.
                    </Paragraph>
                    <Paragraph>
                        You can choose which of the two metrics you wish to use
                        when licensing our web fonts. You cannot later switch
                        from one metric to another so please choose carefully.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'What if I want to use the web fonts on a website that has less than the minimum licence amount of ‘20,000 monthly page views’ or ‘5,000 monthly unique users’?',
            content: (
                <Paragraph>
                    The minimum web font licence is up to ‘20,000 monthly page
                    views’ or up to ‘5,000 monthly unique users’ meaning
                    anything less than 20,000 page views or 5,000 unique users
                    respectively is captured within this price tier. The price
                    tiers for the number of page views and unique users are
                    fixed and non-negotiable.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'What if I want to use the web fonts for a number of page views or unique users that’s not specified on your website?',
            content: (
                <>
                    <Paragraph>
                        The price tiers for the number of page views and unique
                        users are fixed ranges or tiers (e.g. 1 page view - up
                        to 20,000 monthly page views, 20,001 up to 40,000
                        monthly page views, or 1 unique user - up to 5,000,
                        5,001-10,000, and so forth), so you would need to
                        purchase a licence that allows for either the average
                        monthly page views or average monthly unique users for
                        the website you will be using the fonts on. These price
                        tiers are fixed and non-negotiable. It’s important you
                        understand the terms and conditions of the{' '}
                        <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                            Web Font Licence
                        </Link>
                        , as you are responsible for ensuring you stay within
                        the page views or unique users tier you have paid for.
                    </Paragraph>
                    <Paragraph>
                        Also note that if you also wish to use the web fonts for
                        email marketing and/or dynamic web-based advertising as
                        well you may. However, you must ensure that your web
                        font licence covers the correct number of active
                        subscribers (for email marketing) and/or impressions
                        (for dynamic web-based advertising), in addition to the
                        monthly page views or monthly unique users for your
                        website. That is, the sum of your total average monthly
                        page views or unique users, combined with your total
                        monthly impressions (as per your media buy) and/or your
                        total active subscribers (as per your email marketing
                        database). If it does not, you need to{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence with us.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'Can I use the web fonts on my draft staging site?',
            content: (
                <>
                    <Paragraph>
                        Yes, you can use the web fonts in your staging site, as
                        long you comply with <b>3i.</b> of our{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.WEB,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            Web Font Licence
                        </Link>{' '}
                        agreement, as per below, and all other web font licence
                        terms:
                    </Paragraph>
                    <Blockquote>
                        3i. Development Environment
                        <br />
                        <br />
                        You may use the Fonts in a secure development
                        environment controlled by You. The secure development
                        environment must be for work specifically related to the
                        Domain specified on Your Sales Receipt. You may provide
                        the Web Font files to your web developer for the
                        purposes of styling text on Your Website, provided You
                        ensure the web developer complies with 3d. of this
                        Agreement and all other licence terms. At the conclusion
                        of the web developer’s work for You, they must delete
                        any font files in their possession from their devices
                        and electronic storage, including email, and You agree
                        to take reasonable steps to ensure this happens.
                    </Blockquote>
                </>
            ),
        },

        {
            subHeading: 'What will I receive when I buy your web fonts?',
            content: (
                <>
                    <Paragraph>
                        Upon a successful payment, you will get an immediate
                        download with the required font files in a .zip folder.
                        The web font files are: Web Open Font Format 2.0
                        (WOFF2).
                    </Paragraph>
                    <Paragraph>
                        You can also access your order and font download at any
                        time via your Klim account. To do this, visit{' '}
                        <Link to={createAccountUrl()}>account</Link> and sign in
                        using the email you used to make the purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'What CSS do you recommend using for the web fonts?',
            content: (
                <>
                    <Code>
                        {[
                            `@font-face {`,
                            `   font-family: 'FontFamily';`,
                            `   src: url('FontFamily-Regular.woff2') format('woff2');`,
                            `   font-weight: normal;`,
                            `   font-style: normal;`,
                            `}`,
                            ` `,
                            `@font-face {`,
                            `   font-family: 'FontFamily';`,
                            `   src: url('FontFamily-RegularItalic.woff2') format('woff2');`,
                            `   font-weight: normal;`,
                            `   font-style: italic;`,
                            `}`,
                            ` `,
                            `.FontFamily {`,
                            `   font-family: 'FontFamily';`,
                            `}`,
                            ` `,
                            `.FontFamily-italic {`,
                            `   font-family: 'FontFamily';`,
                            `   font-style: italic;`,
                            `}`,
                        ].map(
                            (line, index): React.ReactElement => (
                                <pre key={`css-${index}`}>{line}</pre>
                            ),
                        )}
                    </Code>
                    <Paragraph>
                        ‘FontFamily’ is the Font Family name, for example
                        ‘Calibre’ or ‘TiemposHeadline’.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'If I hold a web font licence can I use these fonts on desktop computers or do I need a separate licence?',
            content: (
                <Paragraph>
                    No, under the terms of our{' '}
                    <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                        Web Font Licence
                    </Link>{' '}
                    you’re not permitted to use web fonts on a desktop computer.
                    Also, the web font files will not work on a desktop. You’ll
                    need to purchase a separate{' '}
                    <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                        Desktop Font Licence
                    </Link>{' '}
                    from Klim and download the correct font file formats for
                    desktops.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Can I provide the web fonts to a developer?',
            content: (
                <>
                    <Paragraph>
                        Yes, you may provide the web font files to your web
                        developer for the purposes of styling text on your
                        website, but you both must comply with <b>3d.</b> of the{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.WEB,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            Web Font Licence
                        </Link>{' '}
                        agreement, as per below, and all other licence terms:{' '}
                    </Paragraph>
                    <Blockquote>
                        3d. Web Fonts File Protection
                        <br />
                        <br />
                        You agree to use reasonable measures to ensure the Web
                        Fonts are available only for the process of styling text
                        on Your website. At a minimum, and by way of
                        illustration not limitation, reasonable measures include
                        a.) preventing unlicensed third-party access, i.e.
                        hotlinking and b.) not allowing direct download of the
                        Web Fonts unrelated to the process of styling text for
                        Your website.
                    </Blockquote>
                    <Paragraph>
                        Once the work on your website is complete, the web
                        developer must delete any font files in their possession
                        from their devices and electronic storage, including
                        email.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Why do the desktop fonts look different to the web fonts?',
            content: (
                <>
                    <Paragraph>
                        Desktop and web fonts will very likely render
                        differently across various screen environments.
                        Operating systems, desktop apps and browsers often use
                        different font rendering engines and techniques. For
                        example, applications like Adobe InDesign will render
                        fonts differently to Apple’s Pages, and FireFox will
                        render them differently again. This is not something
                        that can be readily addressed by the font itself.
                    </Paragraph>
                    <Paragraph>
                        We recommend that you download our{' '}
                        <Link to={createTestFontsUrl()}>test fonts</Link> to
                        check that the fonts work as you would like them to on
                        desktop and in web environments ahead of a font licence
                        purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Do you still support EOT and WOFF web font file formats?',
            content: (
                <>
                    <Paragraph>
                        We have discontinued support for EOT and WOFF as of July
                        2021. The WOFF2 format, which succeeds EOT and WOFF, has
                        been supported by every major web browser since 2016.
                    </Paragraph>
                    <Paragraph>
                        Upon request we can still provide WOFF web font files
                        for greater backward compatibility, however these files
                        will not receive support. We are no longer able to
                        provide EOT files.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Can I use Klim fonts in a third-party web font service like Google Fonts or TypeKit?',
            content: (
                <>
                    <Paragraph>
                        No, Klim does not provide its fonts through any such web
                        font services.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * WEB BASED ADVERTISING
 * ========================================================================== */

const webAdvertisingAccordion: Accordion = {
    id: 'web-based-advertising',
    heading: 'Web-based advertising',
    items: [
        {
            subHeading:
                'What kind of font licence do I need if I want to use the fonts within online advertising, e.g. web banners?',
            content: (
                <>
                    <Paragraph>
                        The answer to this question depends on how you’re using
                        the fonts online.
                    </Paragraph>
                    <Paragraph>
                        If you hold a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            Desktop Font Licence
                        </Link>{' '}
                        you can use the fonts within a web banner, for instance,
                        but only if the fonts are used in a static image within
                        the banner, e.g as a .jpg or .png.
                    </Paragraph>
                    <Paragraph>
                        This use is covered under <b>3d.</b> of our{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.DESKTOP,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            Desktop Font Licence Agreement
                        </Link>{' '}
                        (see below), specifically regarding ‘images for print
                        and web’.
                    </Paragraph>
                    <Blockquote>
                        3d. Authorised Use
                        <br />
                        <br />
                        Authorised uses of the Fonts include: the production of
                        paper publications, for example, newspapers, pamphlets,
                        books and magazines; print advertising; vinyl or printed
                        signage; posters and billboards; logos and logotypes;
                        corporate documents; newsletters and annual reports; as
                        well as images for print, web and social media (static
                        images only); embedding in ePub publications;
                        product/merchandise packaging; marketing materials;
                        outdoor advertising (OOH); digital screens and
                        billboards ( non-interactive); presentations (e.g.
                        Powerpoint); film and video (non-broadcast). You may use
                        the Fonts to display static images within online and
                        social media advertisements (e.g. web banners). You may
                        use the Fonts for non-broadcast video content for Your
                        own business website or Your own social media
                        account(s). You are not entitled to use the Fonts for
                        any form of video content for paid advertising (e.g. a
                        commercial), whether on social media platforms, online
                        channels, TV networks, streaming services or otherwise.
                        You agree to indemnify Klim for any third party claim
                        that arises as a result of any unauthorised use by You
                        of the Fonts.
                    </Blockquote>
                    <Paragraph>
                        If you want to use our fonts dynamically within web
                        banners, or in other similar online content, where you
                        need to serve ‘live’ web fonts to the content, you will
                        need to purchase a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                            Web Font Licence.
                        </Link>{' '}
                        To calculate the web font licence required, you’d need
                        to use the monthly ‘ad impressions’ (as per your media
                        buy), in place of website ‘page views’ or ‘unique
                        users’.
                    </Paragraph>
                    <Paragraph>
                        You must ensure that your web font licence with us
                        covers the correct number of monthly impressions (for
                        dynamic web-based advertising), in addition to the
                        monthly page views or monthly unique users for your
                        website. That is, the sum of your total average monthly
                        page views or unique users, combined with your total
                        monthly impressions (as per your media buy). If it does
                        not, you need to{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence with us.
                    </Paragraph>
                    <Paragraph>
                        If you are purchasing a Web Font Licence for the sole
                        purpose of dynamic web-based advertising you do not need
                        to worry about the above sum calculation, but you would
                        need to tie the licence to a web domain for the purposes
                        of licensing records, e.g. your company website’s URL.
                    </Paragraph>
                    <Paragraph>
                        For an overview of all font licensing available see our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'What kind of font licence do I need to use the fonts in external email communications?',
            content: (
                <>
                    <Paragraph>
                        If you want to use our fonts in email template
                        communications for an external database (e.g. via
                        Campaign Monitor or MailChimp), you will need to
                        purchase a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                            Web Font Licence.
                        </Link>{' '}
                        To calculate the web font licence required, you’d need
                        to use the number of active email subscribers in your
                        database, in place of website ‘page views’ or ‘unique
                        users’.
                    </Paragraph>
                    <Paragraph>
                        You must ensure that your web font licence with us
                        covers the correct number of active email subscribers,
                        in addition to the monthly page views or monthly unique
                        users for your website. That is, the sum of your total
                        average monthly ‘page views’ or ‘unique users’, combined
                        with your total active subscribers (as per your email
                        database). If it does not, you need to{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence with us.
                    </Paragraph>
                    <Paragraph>
                        If you are purchasing a Web Font Licence for the sole
                        purpose of email marketing, you do not need to worry
                        about the above sum calculation, but you would need to
                        tie your licence to a web domain for the purposes of
                        licensing records, e.g. your company website’s URL.
                    </Paragraph>
                    <Paragraph>
                        <b>Please note</b> that while some email clients do now
                        support use of fonts in this way, others still do not
                        (e.g. Gmail), so in these instances the fonts may
                        default to fallback fonts for the receiving person.
                    </Paragraph>
                    <Paragraph>
                        For an overview of all font licensing available see our{' '}
                        <Link to={createEulaOverviewUrl()}>font licences</Link>{' '}
                        page.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Will you expect us to track our Page Views or Unique Users?',
            content: (
                <Paragraph>
                    It’s important that you understand the terms and conditions
                    of the{' '}
                    <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                        Web Font Licence
                    </Link>{' '}
                    as you are responsible for ensuring that you stay within the
                    page views/unique users tier you have paid for. So yes, we
                    do expect that you will keep track of this metric and{' '}
                    <Link to={jumpLink(upgradesAccordion)}>upgrade</Link> to the
                    next tier of font licensing as needed.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * APP FONTS
 * ========================================================================== */

const appFontsAccordion: Accordion = {
    id: 'app-fonts',
    heading: 'App fonts',
    items: [
        {
            subHeading:
                'If I hold a web font licence can I use these fonts in an app or do I need a separate licence?',
            content: (
                <Paragraph>
                    No, under Klim’s{' '}
                    <Link to={createEulaUrl(LicenceTypeId.WEB)}>
                        Web Font Licence
                    </Link>{' '}
                    agreement you are not permitted to use or embed the web
                    fonts into an app for mobile or any other platform. Also,
                    the font files required for apps are different to those used
                    on the web. You’ll need to purchase a separate{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence
                    </Link>{' '}
                    agreement from Klim and download the correct font format
                    files for app.
                </Paragraph>
            ),
        },
        {
            subHeading: 'What will I receive when I buy your app fonts?',
            content: (
                <>
                    <Paragraph>
                        Upon a successful payment, you will get an immediate
                        download with the required font files in a .zip folder.
                        The app font files are TrueType Format (TTF).
                    </Paragraph>
                    <Paragraph>
                        You can also access your order and font download at any
                        time via your Klim account. To do this, visit{' '}
                        <Link to={createAccountUrl()}>account</Link> and sign in
                        using the email you used to make the purchase.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'Do I need a special app font licence for an app that works across multiple platforms?',
            content: (
                <Paragraph>
                    No, an app that works across several platforms (e.g. iOS,
                    Windows, Android etc) is still considered to be one app
                    under our{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence.
                    </Link>{' '}
                    If you’re creating more than one app, you require a separate{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence
                    </Link>{' '}
                    for each.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'What types of apps are covered by your App Font Licence?',
            content: (
                <Paragraph>
                    You can use our app fonts on an app for mobile, desktop or
                    web. A single{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence
                    </Link>{' '}
                    covers Applications that are functionally equivalent across
                    multiple platforms and operating systems.
                </Paragraph>
            ),
        },
        {
            subHeading: 'What’s the difference between MAUs and Downloads?',
            content: (
                <>
                    <Paragraph>
                        A MAU (monthly active user) is defined as an end user
                        that accesses or views your application at least once
                        per month. Downloads means the total number of downloads
                        of your application by end users.
                    </Paragraph>
                    <Paragraph>
                        You can choose which of these two metrics you wish to
                        use when licensing our app fonts. You cannot later
                        switch from one metric to another so please choose
                        carefully.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading: 'How do we calculate our monthly active users (MAUs)?',
            content: (
                <Paragraph>
                    There are analytics tools that can help you to track MAUs
                    for your app. If you’re unable to do this, then it’s best to
                    use total downloads as the metric for your app font
                    licensing instead. You are required to licence the fonts for
                    your average volume of MAUs, which can be calculated by
                    adding the total MAUs from three consecutive months and
                    dividing by 3.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Will you expect us to track our MAUs or Downloads?',
            content: (
                <Paragraph>
                    It’s important that you understand the terms and conditions
                    of the{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence
                    </Link>{' '}
                    as you are responsible for ensuring that you stay within the
                    MAUs/Downloads tier you have paid for. So yes, we do expect
                    that you will keep track of this metric and{' '}
                    <Link to={jumpLink(upgradesAccordion)}>upgrade</Link> to the
                    next tier of font licensing as needed.
                </Paragraph>
            ),
        },
        {
            subHeading: 'How do we work out our total downloads?',
            content: (
                <Paragraph>
                    As the app’s creator you can access data on the number of
                    downloads of your app within your hosting platform. You are
                    required to licence the fonts for the total downloads since
                    you released your app.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'What if I want to use the app fonts for an application that has less than the minimum licence amount of ‘2,500 MAUs’ or ‘2,500 downloads’?',
            content: (
                <Paragraph>
                    The minimum app font licence is technically up to ‘2,500
                    MAUs’ or up to ‘2,500 downloads’ (i.e. 1–2,500) meaning that
                    anything less than 2,500 is captured within this price tier.
                    These price tiers are fixed and non-negotiable.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Are there restrictions on app downloads or distribution?',
            content: (
                <Paragraph>
                    No, there are no download or distribution limitations within
                    our{' '}
                    <Link to={createEulaUrl(LicenceTypeId.APP)}>
                        App Font Licence
                    </Link>
                    .
                </Paragraph>
            ),
        },
        {
            subHeading: 'Can I provide the app fonts to a developer?',
            content: (
                <>
                    <Paragraph>
                        Yes, you may provide the app font files to your app
                        developer for the purposes of styling text within your
                        app, but you both must comply with <b>3c.</b>{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.APP,
                                '#permitted-uses-restrictions',
                            )}
                        >
                            App Font Licence
                        </Link>{' '}
                        agreement, as per below, and all other licence terms:
                    </Paragraph>
                    <Blockquote>
                        3c. Font File Protection
                        <br />
                        <br />
                        You agree to use reasonable measures to protect the
                        Fonts from access and use by unlicensed or unauthorised
                        third parties. If You discover or are made aware of any
                        unauthorised access and/or use by an unlicensed or
                        unauthorised third party, You agree to notify Klim as
                        soon as is reasonable.
                    </Blockquote>
                    <Paragraph>
                        Once the work on your application is complete, the app
                        developer must delete any font files in their possession
                        from their devices and electronic storage, including
                        email.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * VERTICAL METRICS
 * ========================================================================== */

const verticalMetricsAccordion: Accordion = {
    id: 'vertical-metrics',
    heading: 'Vertical metrics',
    items: [
        {
            subHeading:
                'Why is the vertical alignment of the web fonts we purchased not centered?',
            content: (
                <>
                    <Paragraph>
                        There are three factors that determine where a font sits
                        in a text bounding box.
                    </Paragraph>
                    <Paragraph>
                        First, the design of the font itself. Every font family
                        has different proportions and therefore different values
                        for: x-height, descenders, ascenders, caps and accents.
                        Those proportions make the font sit different for every
                        font family. There are no standards or rules for font
                        proportions, each font is different.
                    </Paragraph>
                    <Paragraph>
                        Second, fonts have 3 sets of internal vertical metrics
                        values (hhea, OS/2, usWin). These values tell operating
                        systems, apps and browsers where the font should set.
                        Each OS, app and browser uses different values, and
                        different interpretations. This is largely beyond our
                        control. There is no perfect combination of these values
                        that ensure exact consistency across all platforms.
                    </Paragraph>
                    <Paragraph>
                        Third, we set our vertical metrics to avoid issues like
                        accent clipping, which is one of the main problems for
                        web/app fonts. We use the biggest vertical metric values
                        possible for the entire family to ensure all the fonts
                        sit together on the baseline and parts of letters are
                        not cut off.
                    </Paragraph>
                    <Paragraph>
                        At this stage, it is not possible to get web font files
                        with the metadata for “vertical metrics” tweaked to
                        achieve visual centering, as they are already optimised
                        for the majority of platforms and use-cases.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * RESELLERS
 * ========================================================================== */

const resellersAccordion: Accordion = {
    id: 'resellers',
    heading: 'Resellers',
    items: [
        {
            subHeading: 'Do you allow resellers to on-sell your fonts?',
            content: (
                <>
                    <Paragraph>
                        In short, no. Please see the terms of our{' '}
                        <Link to={createEulaOverviewUrl()}>
                            font licence agreements
                        </Link>
                        , specifically Clause <b>1d.</b> of the{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.DESKTOP,
                                '#definitions',
                            )}
                        >
                            Desktop
                        </Link>
                        ,{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.WEB,
                                '#definitions',
                            )}
                        >
                            Web
                        </Link>
                        ,{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.APP,
                                '#definitions',
                            )}
                        >
                            App
                        </Link>
                        ,{' '}
                        <Link
                            to={createEulaUrl(LicenceTypeId.AD, '#definitions')}
                        >
                            Advertising
                        </Link>
                        ,{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.BROADCAST,
                                '#definitions',
                            )}
                        >
                            Broadcasting
                        </Link>{' '}
                        and{' '}
                        <Link
                            to={createEulaUrl(
                                LicenceTypeId.OEM,
                                '#definitions',
                            )}
                        >
                            OEM
                        </Link>{' '}
                        Font Licences, which explicitly prohibit this.
                    </Paragraph>
                    <Paragraph>
                        We prefer to work directly with all of our business
                        customers. We offer a full invoicing service for
                        customers who wish to incorporate purchase orders (POs)
                        and make payment by bank transfer. We can also work in
                        local currencies if desired. Our support team can answer
                        questions and guide customers step-by-step to allow for
                        an easy purchasing process. Once the purchase is
                        complete, all font orders are set up in our online
                        system for easy access to the fonts, sales receipts and
                        order history.
                    </Paragraph>
                    <Paragraph>
                        Please note that the purchasing for ‘someone else’
                        function at checkout is designed as a convenience to
                        allow design or similar creative agencies and web or app
                        developers to purchase the fonts on their client’s
                        behalf in order to use the fonts to develop brand
                        artwork and assets for them. Under no circumstance
                        should these agencies and/or developers profit in any
                        way from this font licence purchase. The font licensing
                        should always be owned by the client as the end user.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * STUDENTS
 * ========================================================================== */

const studentsAccordion: Accordion = {
    id: 'students',
    heading: 'Students',
    items: [
        {
            subHeading: 'Does Klim offer internships?',
            content: (
                <Paragraph>
                    We’re a small foundry and do not have the capacity to take
                    on interns, sorry.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Does Klim allow students to test their fonts?',
            content: (
                <Paragraph>
                    Yes.{' '}
                    <ExternalLink href={createTestFontsUrl()}>
                        Download them here.
                    </ExternalLink>
                </Paragraph>
            ),
        },
        {
            subHeading:
                'How can I learn more about how Klim’s typefaces were developed?',
            content: (
                <Paragraph>
                    To read about a particular typeface go to the Information
                    section of any retail font page. Also visit our{' '}
                    <Link to={createBlogIndexUrl()}>blog</Link> to read articles
                    about recent and past projects as well as our
                    collaborations.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * CUSTOM FONTS
 * ========================================================================== */

const customFontsAccordion: Accordion = {
    id: 'custom-fonts',
    heading: 'Custom fonts',
    items: [
        {
            subHeading:
                'I would like Klim to create a custom font for my business or client, how do I go about this?',
            content: (
                <Paragraph>
                    Contact{' '}
                    <ExternalLink href={createSalesEmailUrl(true)}>
                        {createSalesEmailUrl()}
                    </ExternalLink>{' '}
                    with your request.
                </Paragraph>
            ),
        },
        {
            subHeading: 'Are the custom fonts for sale?',
            content: (
                <Paragraph>
                    As a rule, no. These fonts were created as one-offs for
                    clients. However, some{' '}
                    <Link to={createCustomFontsMenuUrl()}>custom fonts</Link>{' '}
                    have a limited ‘exclusivity’ period. Once this period
                    expires, Klim may make the fonts available for purchase
                    through a retail font licence via klim.co.nz.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'Can I purchase a licence for Klim retail fonts and modify them?',
            content: (
                <>
                    <Paragraph>
                        No, under our standard font licensing terms we do not
                        allow people to modify our{' '}
                        <Link to={createFontsMenuUrl()}>retail fonts</Link>, nor
                        contract third parties to do so.
                    </Paragraph>
                    <Paragraph>
                        The terms of our unlimited font licensing allows for
                        modifications in some instances. Contact{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to discuss.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * FONT DESIGN INFORMATION
 * ========================================================================== */

const fontDesignInfoAccordion: Accordion = {
    id: 'font-design-information',
    heading: 'Font design information',
    items: [
        {
            subHeading:
                'I want to know more about the design of a particular Klim font, where can I read about this?',
            content: (
                <Paragraph>
                    You can read blog posts written by Klim’s director and lead
                    type designer, Kris Sowersby, in our Blog section{' '}
                    <Link to={createFontDesignInfoUrl()}>here.</Link> These
                    posts explain each retail typeface’s design and development.
                    There’s also articles about Klim’s custom typeface
                    commission projects.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * FONTS IN USE
 * ========================================================================== */

const fontsInUseAccordion: Accordion = {
    id: 'fonts-in-use',
    heading: 'Fonts in use',
    items: [
        {
            subHeading:
                'Where can I see examples of Klim fonts being used in design projects, brand identities, etc?',
            content: (
                <Paragraph>
                    Visit our site’s{' '}
                    <Link to={createFontsInUseUrl()}>in use</Link> section for
                    lots of examples of Klim fonts being used by international
                    brands, institutions and businesses of all sizes.
                </Paragraph>
            ),
        },
        {
            subHeading:
                'How can I share my work that uses Klim fonts with you?',
            content: (
                <Paragraph>
                    We love seeing what people do with our fonts! Please send
                    examples of your work to{' '}
                    <ExternalLink href={createKlimInUseEmailUrl(true)}>
                        {createKlimInUseEmailUrl()}
                    </ExternalLink>{' '}
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * KLIM GOODS
 * ========================================================================== */

const goodsAccordion: Accordion = {
    id: 'klim-goods',
    heading: 'Klim Goods',
    items: [
        {
            subHeading: 'Where can I buy Klim goods?',
            content: (
                <Paragraph>
                    Klim no longer sells physical goods, focusing instead solely
                    on its typeface catalogue.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * NEWS
 * ========================================================================== */

const newsAccordion: Accordion = {
    id: 'news',
    heading: 'News',
    items: [
        {
            subHeading: 'I want to receive news from Klim, how do I sign up?',
            content: (
                <>
                    <Paragraph>
                        When making a purchase on Klim or Klim Goods you can
                        sign up to receive emails from us as you check out.
                    </Paragraph>
                    <Paragraph>
                        If you’re not making a purchase but want to receive Klim
                        emails you can{' '}
                        <Link to={createSubscribeUrl()}>subscribe</Link> on our
                        site. To do this, see the Menu in the site’s top nav,
                        click on Newsletter and enter your details.
                    </Paragraph>
                    <Paragraph>
                        You can also follow Klim Type Foundry (@klimtypefoundry)
                        on{' '}
                        <ExternalLink href={createTwitterUrl()}>
                            Twitter
                        </ExternalLink>
                        ,{' '}
                        <ExternalLink href={createInstagramUrl()}>
                            Instagram
                        </ExternalLink>
                        ,{' '}
                        <ExternalLink href={createFacebookUrl()}>
                            Facebook
                        </ExternalLink>
                        ,{' '}
                        <ExternalLink href={createTumblrlUrl()}>
                            Tumblr
                        </ExternalLink>{' '}
                        and{' '}
                        <ExternalLink href={createPinterestUrl()}>
                            Pinterest
                        </ExternalLink>
                        .
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * MEDIA
 * ========================================================================== */

const mediaAccordion: Accordion = {
    id: 'media',
    heading: 'Media',
    items: [
        {
            subHeading:
                'How can I learn more about type designer Kris Sowersby?',
            content: (
                <Paragraph>
                    There’s a series of interviews with Klim Type Foundry’s
                    director and lead typeface designer, Kris Sowersby, on{' '}
                    <Link to={createInterviewUrl()}>our blog.</Link> Please
                    contact{' '}
                    <ExternalLink href={createMediaEmailUrl(true)}>
                        {createMediaEmailUrl()}
                    </ExternalLink>{' '}
                    if you wish to interview Kris Sowersby or have any questions
                    about Klim Type Foundry and our work.
                </Paragraph>
            ),
        },
    ],
};

/* ============================================================================
 * LANGUAGE SUPPORT
 * ========================================================================== */

const languageSupportAccordion: Accordion = {
    id: 'language-support',
    heading: 'Language support',
    items: [
        {
            subHeading: 'What languages do Klim fonts support?',
            content: (
                <>
                    <Paragraph>
                        Our standard Klim{' '}
                        <Link to={createFontsMenuUrl()}>retail fonts</Link>{' '}
                        <b>do not</b> support Greek and Cyrillic. Although we
                        can arrange for language support for these languages by
                        commission and on a case-by-case basis. Contact{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to find out more.
                    </Paragraph>
                    <Paragraph>
                        To find out what languages are supported, look for the
                        information panel and ‘Supported Languages’ at the
                        bottom of each font page in the{' '}
                        <Link to={createFontsMenuUrl()}>retail fonts</Link>{' '}
                        section.
                    </Paragraph>
                </>
            ),
        },
    ],
};

const broadcastAccordion: Accordion = {
    id: 'broadcast',
    heading: 'Broadcast fonts',
    items: [
        {
            subHeading: 'Can I licence Klim fonts for use in broadcast?',
            content: (
                <>
                    <Paragraph>
                        Yes, there is a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.BROADCAST)}>
                            Broadcasting Font Licence
                        </Link>{' '}
                        available if you intend to use the fonts:
                    </Paragraph>
                    <Ul>
                        <Li>
                            within a TV show/series, film/movie or other similar
                            broadcast media
                        </Li>
                        <Li>across your broadcasting network</Li>
                    </Ul>
                    <Paragraph>
                        Broadcast use is defined as any visual transmission of
                        non-fiction or entertainment content via television,
                        internet, film or other visual media. Our broadcasting
                        licence covers the use of our fonts within the broadcast
                        media itself, as well as all related marketing
                        communications used to promote that content.
                    </Paragraph>
                    <Paragraph>
                        You can choose the metric you wish to licence for -
                        either Production Budget or Audience Size, depending on
                        which best suits your needs. There is no upper limit to
                        the use of the fonts - you can use them in unlimited
                        films or tv shows and related marketing communication
                        outputs (e.g. trailers, posters, billboards, etc), you
                        just need to work within the limit of the licence tier
                        that you’ve purchased. That is, as long as the total
                        money spent on all content does not exceed your
                        Production Budget tier, or the total audience reached
                        does not exceed your Audience Size tier.
                    </Paragraph>
                    <Paragraph>
                        You must choose one metric and you can’t change this
                        over time. You can, however,{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence to a higher tier at any time. Note that the
                        top licence tier (40M+ Audience Size or $50M+ Production
                        Budget) is effectively an unlimited Broadcasting Font
                        Licence for your company. For more information please
                        read the full{' '}
                        <Link to={createEulaUrl(LicenceTypeId.BROADCAST)}>
                            licence terms
                        </Link>
                        .
                    </Paragraph>
                    <Paragraph>
                        If the scope of this new Broadcasting Font Licence still
                        does not cover your specific font licensing needs, then
                        our Enterprise Font Licence Agreement may be a better
                        fit for your company or organisation. Please contact
                        Sonja at{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        to discuss this type of licence.
                    </Paragraph>
                </>
            ),
        },
        {
            subHeading:
                'If I share my video or film content on my website, my social media account, ' +
                'and/or my YouTube channel does this count as broadcasting use?',
            content: (
                <>
                    <Paragraph>
                        No, it does not. You can use the fonts within your video
                        or film content for these purposes if you hold a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            desktop font licence
                        </Link>{' '}
                        with Klim.
                    </Paragraph>
                    <Paragraph>
                        You are only required to licence for Advertising if your
                        videos or films are used as advertising commercials
                        intended for use in paid advertising placements across
                        online, social media channels and/or TV networks.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * ADVERTISING
 * ========================================================================== */

const advertisingAccordion: Accordion = {
    id: 'advertising',
    heading: 'Advertising fonts',
    items: [
        {
            subHeading:
                'Can I license Klim fonts for use in advertising commercials?',
            content: (
                <>
                    <Paragraph>
                        Yes, there is an{' '}
                        <Link to={createEulaUrl(LicenceTypeId.AD)}>
                            Advertising Font Licence
                        </Link>{' '}
                        available if you wish to use the fonts within a
                        TVC/promotional video/short film intended for use in
                        paid advertising placements online, on social media
                        channels and/or via TV networks.
                    </Paragraph>
                    <Paragraph>
                        Note that you do need to own a{' '}
                        <Link to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                            Desktop Font Licence
                        </Link>{' '}
                        for the fonts you want to use before obtaining an
                        Advertising Font Licence, or purchase one at the same
                        time. You will not receive separate font downloads when
                        you purchase this licence. For all of your advertising
                        content needs, you can use the font files received with
                        your purchase of a Desktop Font Licence.
                    </Paragraph>
                    <Paragraph>
                        There is no limit to the use of the fonts - you can use
                        them in unlimited advertisements and campaigns, you just
                        need to work within the licence tier that you’ve
                        purchased. The licence tier is based upon your monthly
                        Impressions, as per your media buy. If your use
                        increases over time, you must purchase a licence{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        for the increased monthly impression amount. Note that
                        the top licence tier (40M+ monthly Impressions) is
                        effectively an unlimited Advertising Font Licence for
                        your company.
                    </Paragraph>
                    <Paragraph>
                        Advertising does not include any use of the font in
                        videos prepared for your own website, social media
                        account, YouTube channel or similar. Nor does it include
                        the use of the fonts within a static image web banners,
                        e.g as a .jpg or .png file. These uses are covered by
                        our Desktop Font Licence. For dynamic web-based
                        advertising see{' '}
                        <Link to={jumpLink(webAdvertisingAccordion)}>
                            this FAQ
                        </Link>
                        .
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * OEM FONTS
 * ========================================================================== */

const oemAccordion: Accordion = {
    id: 'oem',
    heading: 'OEM fonts',
    items: [
        {
            subHeading:
                'Can I use your fonts within a hardware device I’m creating?',
            content: (
                <>
                    <Paragraph>
                        Yes, our{' '}
                        <Link to={createEulaUrl(LicenceTypeId.OEM)}>
                            OEM Font Licence
                        </Link>{' '}
                        is intended for people or companies who want to use the
                        fonts within an operating system for a hardware device
                        they are producing. An Original Equipment Manufacturer
                        or ‘OEM’ is defined as any entity that produces parts
                        that are used in a final product that is owned, marketed
                        or branded by that company or another entity. The
                        licensing metric is the ‘number of devices’ that your
                        company intends to produce. You can{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>{' '}
                        your licence tier at any time if the number of devices
                        you produce increases. Contact Sonja at{' '}
                        <ExternalLink href={createSalesEmailUrl(true)}>
                            {createSalesEmailUrl()}
                        </ExternalLink>{' '}
                        if you wish to{' '}
                        <Link to={jumpLink(upgradesAccordion)}>upgrade</Link>.
                    </Paragraph>
                </>
            ),
        },
    ],
};

/* ============================================================================
 * OMG - THIS IS THE END OF ALL THE FAQS - OMG
 * ========================================================================== */

const items: Accordion[] = [
    licencingAccordion,
    testFontsAccordion,
    upgradesAccordion,
    paymentAccordion,
    orderingAccordion,
    accountAccordion,
    refundsAccordion,
    desktopFontsAccordion,
    webFontsAccordion,
    webAdvertisingAccordion,
    appFontsAccordion,
    advertisingAccordion,
    broadcastAccordion,
    oemAccordion,
    verticalMetricsAccordion,
    resellersAccordion,
    studentsAccordion,
    customFontsAccordion,
    fontDesignInfoAccordion,
    fontsInUseAccordion,
    goodsAccordion,
    newsAccordion,
    mediaAccordion,
    languageSupportAccordion,
];

export default items;
