import React from 'react';
import type { EditorState } from 'draft-js';
import tokens, {
    STYLISTIC_SET_LABEL_PREFIX,
    Token,
} from '../utils/type-editor/tokens';
import useTypeEditorUsedStyles from '../hooks/type-editor/useTypeEditorUsedStyles';
import { useFontFamily } from './PageContext';
import type { FontFamily } from './PageContext';
import { FontFeatureSetting } from '../utils/type-editor/FontFeatureSetting';
import notDuplicate from '../utils/notDuplicate';
import type { CMS_SSR_OpenTypeFeature } from '../gql/api-ssr';
import { useEditorState } from './TypeEditorContext';
import getInlineStylesForSelection from '../utils/type-editor/getInlineStylesForSelection';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import { Select, SelectItem, SelectProps } from './Select';
import LozengeCheckbox, { CheckboxProps } from './LozengeCheckbox';
import notUndefined from '../utils/notUndefined';
import useDraftJsCurrentInlineStyle from '../hooks/type-editor/useDraftJsCurrentInlineStyle';
import useDraftJsSelection from '../hooks/type-editor/useDraftJsSelection';

type FeatureTokens = Token | Token[];

// This sets the features to show in the UI (if available in the specimen),
// and the order in which they are shown.
// If there are multiple tokens in an array, then the interface will enforce a mutually-exclusive
// selection, e.g. select list or radio buttons.
const featuresToShow: FeatureTokens[] = [
    [
        tokens.numstyDefault,
        tokens.numstyLining,
        tokens.numstyOldstyle,
        tokens.numstyTabularLining,
        tokens.numstyTabularOldstyle,
    ],
    tokens.ss01,
    tokens.ss02,
    tokens.ss03,
    tokens.ss04,
    tokens.ss05,
    tokens.ss06,
    tokens.ss07,
    tokens.ss08,
    tokens.ss09,
    tokens.ss10,
    tokens.ss11,
    tokens.ss12,
    tokens.ss13,
    tokens.ss14,
    tokens.ss15,
    tokens.ss16,
    tokens.ss17,
    tokens.ss18,
    tokens.ss19,
    tokens.ss20,
    tokens.smcp,
    tokens.c2sc,
    tokens.dlig,
    tokens.swsh,
    tokens.zero,
    tokens.frac,
    tokens.sups,
    tokens.sinf,
    tokens.ordn,
    tokens.case,
];

type OpenTypeFeatureWithState = {
    tokens: Token[];
    selectedToken?: Token;
    indeterminate: boolean;
    disabled: boolean;
    onCheckedChange?: CheckboxProps['onCheckedChange'];
    onValueChange?: SelectProps['onValueChange'];
};

/**
 * Most labels are hardcoded as properties of the token in `tokens.ts`,
 * but "Stylistic Set" tokens can be assigned arbitrary labels in the CMS.
 *
 * Note: there's a flaw in this logic - that there could hypothetically be
 * multiple matching `stylisticSetCustomNames`, but in almost all cases the
 * `names` for the entire family would be identical.
 * @param token
 * @param fontStyles
 */
function getCustomTokenLabel(
    token: Token,
    fontStyles: FontFamily['fontStyles'],
): string {
    if (token.label && !token.label.startsWith(STYLISTIC_SET_LABEL_PREFIX)) {
        return token.label;
    }
    return fontStyles.reduce(
        (carry: string, fontStyle): string => {
            const matchingStylisticSet = fontStyle.stylisticSetCustomNames.find(
                (stylisticSet): boolean => {
                    /*
                     * "Stylistic Set" tokens can be best identified by a
                     * `features` property with a single item, that matches one
                     * of the `fontStyle.stylisticSet.openTypeFeature` strings.
                     */
                    return token.features.every(
                        (feature): boolean =>
                            /*
                             * "match" deliberately used rather than equality
                             * comparison because we happen to need it to be
                             * a case-insensitive comparison.
                             */
                            !!feature.match(
                                stylisticSet.openTypeFeature.toLowerCase(),
                            ),
                    );
                },
            );

            return matchingStylisticSet ? matchingStylisticSet.name : carry;
        },
        /*
         * Hardcoded label provided as default, in case this isn't a stylistic
         * set token, OR if there was no CMS override given.
         */
        token.label,
    );
}

/**
 * Returns the OpenTypeFeatures that are available for the current editor's FontStyles.
 */
export function useAvailableFeatures(): FeatureTokens[] {
    const { fontStyles } = useFontFamily();
    const usedStyles = useTypeEditorUsedStyles();

    /*
     * Array which describes the *names* of all currently-applicable openType
     * features. Some tokens come in pairs, like `PNUM_LNUM` so this has to
     * be a multidimensional array, eg.
     *
     * [
     *    ['afrc'],
     *    ['c2sc'],
     *    ['case'],
     *    ['pnum', 'lnum'],
     * ]
     *
     */
    const availableOtFeatures: string[][] = React.useMemo(
        () =>
            fontStyles
                // Exclude un-used fontStyles.
                .filter((style): boolean => usedStyles.includes(style.name))
                // Map to an array of *all* styles' openTypeFeatures.
                .reduce(
                    (
                        carry: CMS_SSR_OpenTypeFeature[],
                        style,
                    ): CMS_SSR_OpenTypeFeature[] => [
                        ...carry,
                        ...style.openTypeFeatures,
                    ],
                    [],
                )
                .filter(notDuplicate)
                /*
                 * Some OpenTypeFeatureEnum values are like `PNUM_LNUM`, so we want to
                 * create array pairs for those.
                 */
                .map((feature): string[] => feature.toLowerCase().split('_')),
        [fontStyles, usedStyles],
    );

    const filterFeatureToShow = React.useCallback(
        (singleFeatureToShow: Token) => {
            return availableOtFeatures.some(
                (availableFeatSet: string[]): boolean => {
                    return singleFeatureToShow.features.every(
                        (tokenFeatureId: FontFeatureSetting): boolean =>
                            availableFeatSet.includes(tokenFeatureId),
                    );
                },
            )
                ? singleFeatureToShow
                : undefined;
        },
        [availableOtFeatures],
    );

    // Filter `featuresToShow` based on what's available
    return React.useMemo(
        () =>
            featuresToShow
                .map((featureToShow): FeatureTokens | undefined => {
                    if (!Array.isArray(featureToShow)) {
                        // A single token
                        return filterFeatureToShow(featureToShow);
                    }
                    // Multiple tokens, filter each
                    return featureToShow
                        .map((singleFeatureToShow) => {
                            return filterFeatureToShow(singleFeatureToShow);
                        })
                        .filter(notUndefined);
                })
                .filter(notUndefined)
                // Remove potentially single selection or empty arrays
                .filter(
                    (featureToShow): boolean =>
                        !Array.isArray(featureToShow) ||
                        featureToShow.length > 1,
                ),
        [filterFeatureToShow],
    );
}

function useFeaturesWithState(
    availableFeatures: FeatureTokens[],
): OpenTypeFeatureWithState[] {
    const [editorState, setEditorState] = useEditorState();
    const currentInlineStyle = useDraftJsCurrentInlineStyle();
    const currentSelection = useDraftJsSelection();

    const { fontStyles } = useFontFamily();

    const editorHasText = React.useMemo(() => {
        return editorState.getCurrentContent().hasText();
    }, [editorState]);

    const inlineStylesForSelection = React.useMemo(
        () => getInlineStylesForSelection(editorState),
        [editorState],
    );

    return availableFeatures.map((availableFeature) => {
        const isSingleToken = !Array.isArray(availableFeature);

        const inlineStyleForSelection = inlineStylesForSelection.find(
            (inlineStyleForSelection) =>
                isSingleToken
                    ? inlineStyleForSelection.style === availableFeature.name
                    : availableFeature.find(
                          (token) =>
                              token.name === inlineStyleForSelection.style,
                      ),
        );
        const cursorPositionHasToken = isSingleToken
            ? currentInlineStyle.has(availableFeature.name)
            : availableFeature.some((token) =>
                  currentInlineStyle.has(token.name),
              );
        const selectionHasToken =
            inlineStyleForSelection !== undefined ||
            (currentSelection.isCollapsed() && cursorPositionHasToken);
        const inlineStyleForSelectionIsIndeterminate =
            (inlineStyleForSelection?.indeterminate &&
                !currentSelection.isCollapsed()) ||
            false;

        const selectedToken = isSingleToken
            ? selectionHasToken
                ? availableFeature
                : undefined
            : !selectionHasToken
              ? availableFeature[0]
              : availableFeature.find(
                    (token) =>
                        (inlineStyleForSelection &&
                            inlineStyleForSelection.style === token.name) ||
                        (currentSelection.isCollapsed() &&
                            currentInlineStyle.has(token.name)),
                );

        return {
            tokens: (isSingleToken ? [availableFeature] : availableFeature).map(
                (token) => {
                    return {
                        ...token,
                        // Apply possible custom label
                        label: getCustomTokenLabel(token, fontStyles),
                    };
                },
            ),
            selectedToken,
            indeterminate: inlineStyleForSelectionIsIndeterminate,
            disabled: !editorHasText,
            onCheckedChange: isSingleToken
                ? (checked): void => {
                      setEditorState((state: EditorState): EditorState => {
                          return updateEditorInlineStyles({
                              editorState: state,
                              stylesToApply: checked
                                  ? [availableFeature.name]
                                  : undefined,
                              stylesToRemove: !checked
                                  ? [availableFeature.name]
                                  : undefined,
                          });
                      });
                  }
                : undefined,
            onValueChange: !isSingleToken
                ? (value): void => {
                      setEditorState((state: EditorState): EditorState => {
                          return updateEditorInlineStyles({
                              editorState: state,
                              stylesToApply:
                                  availableFeature
                                      .filter((token) => token.name === value)
                                      .map((token) => token.name) || undefined,
                              stylesToRemove:
                                  availableFeature
                                      .filter((token) => token.name !== value)
                                      .map((token) => token.name) || undefined,
                          });
                      });
                  }
                : undefined,
        };
    });
}

export default function TypeEditorFeatures(): React.ReactElement | null {
    const availableFeatures = useAvailableFeatures();
    const features = useFeaturesWithState(availableFeatures);

    if (!features.length) {
        return null;
    }

    return (
        <>
            {features.map((item): React.ReactElement | null =>
                item.tokens.length > 1 ? (
                    <Select
                        onValueChange={item.onValueChange}
                        value={item.selectedToken?.name}
                        key={item.tokens.join('|')}
                    >
                        {item.tokens.map((token) => (
                            <SelectItem key={token.name} value={token.name}>
                                {token.label}
                            </SelectItem>
                        ))}
                    </Select>
                ) : (
                    <LozengeCheckbox
                        key={item.tokens[0].name}
                        checked={
                            item.indeterminate
                                ? 'indeterminate'
                                : item.selectedToken !== undefined
                        }
                        onCheckedChange={item.onCheckedChange}
                        disabled={item.disabled}
                        value={item.tokens[0].name}
                    >
                        {item.tokens[0].label}
                    </LozengeCheckbox>
                ),
            )}
        </>
    );
}
