import React from 'react';
import type { EditorState } from 'draft-js';
import styled, { css } from 'styled-components';
import useTypeEditorUsedStyles from '../hooks/type-editor/useTypeEditorUsedStyles';
import {
    UnionCssRenderInfo,
    useEditorState,
    useFontStyleState,
    useVariableAxesState,
} from './TypeEditorContext';
import { useFontFamily } from './PageContext';
import { Select, SelectItem } from './Select';
import { TEST_ID } from '../settings/E2e';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import { type TypeEditorActiveStatus } from './TypeEditorToolbar';
import { VIEWPORT } from '../settings/Global';
import {
    compileTokenName,
    decompileTokenName,
    getTokenNamePrefix,
    TokenType,
} from '../utils/type-editor/tokens';
import useDraftJsCurrentInlineStyle from '../hooks/type-editor/useDraftJsCurrentInlineStyle';
import useDraftJsIsAllSelected from '../hooks/type-editor/useDraftJsIsAllSelected';

const Wrap = styled.span<{ $activeStatus?: TypeEditorActiveStatus }>`
    --lozengeMinWidth: 180px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        --lozengeWidth: calc(40vw - var(--gridMarginGap));
        --lozengeMinWidth: auto;
    }

    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus === 'inactive'
            ? css`
                  --lozengeColor: var(--foregroundColorMix6);
                  --lozengeHoverColor: var(--foregroundColorMix4);
                  --lozengeBackgroundColor: transparent;
                  --lozengeBackgroundHoverColor: transparent;
              `
            : null}
`;

function useSelectedFontStyleId(): string | undefined {
    const fontFamily = useFontFamily();
    const [fontStyle] = useFontStyleState();
    const [variableAxes] = useVariableAxesState();
    const currentInlineStyle = useDraftJsCurrentInlineStyle();

    //
    // Static font
    //
    if (variableAxes === null) {
        return (
            (currentInlineStyle &&
                fontFamily.fontStyles.find((style) =>
                    currentInlineStyle.has(
                        compileTokenName({
                            type: TokenType.FAMILY,
                            names: [style.cssRenderInfo.fontFamilyName],
                        }),
                    ),
                )?.id) ||
            fontStyle.id
        );
    }

    //
    // Variable font
    //
    const axisValuesToMatch = variableAxes.map((axis) => {
        const tokenNamePrefix = getTokenNamePrefix(TokenType.VARIABLE, [
            axis.tag,
        ]);
        const token = currentInlineStyle
            .toArray()
            .find((token) => token.startsWith(tokenNamePrefix));
        const tokenData = token ? decompileTokenName(token) : undefined;
        if (!tokenData) {
            return axis;
        }
        return { tag: axis.tag, value: tokenData.value };
    });

    // Get style with same axis values (if any)
    return fontFamily.fontStyles.find((style) => {
        if (
            style.cssRenderInfo.variableAxisValues?.length !==
            axisValuesToMatch.length
        ) {
            return false;
        }
        return !style.cssRenderInfo.variableAxisValues.some((value) => {
            const axis = axisValuesToMatch.find(
                (axis) => axis.tag === value.tag,
            );
            return !axis || axis.value !== value.value;
        });
    })?.id;
}

const TypeEditorStylesInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLButtonElement>,
    ): React.ReactElement => {
        const [, setEditorState] = useEditorState();
        const [, setFontStyle] = useFontStyleState();
        const fontFamily = useFontFamily();
        const usedStyles = useTypeEditorUsedStyles();
        const [variableAxes] = useVariableAxesState();
        const selectedValue = useSelectedFontStyleId();
        const isAllSelected = useDraftJsIsAllSelected();

        const styleIdToCssRenderInfoMap: Record<
            string,
            {
                id: string;
                name: string;
                cssRenderInfo: UnionCssRenderInfo;
            }
        > = React.useMemo(() => {
            const styleEntries = fontFamily.fontStyles.map((fontStyle) => {
                return [
                    fontStyle.id,
                    {
                        id: fontStyle.id,
                        name: fontStyle.name,
                        cssRenderInfo: fontStyle.cssRenderInfo,
                    },
                ];
            });

            return Object.fromEntries(styleEntries);
        }, [fontFamily.fontStyles]);

        const onChange = React.useCallback(
            (fontStyleId: string): void => {
                setEditorState((state: EditorState): EditorState => {
                    const newEditorState = updateEditorInlineStyles({
                        editorState: state,
                        stylesToApply: fontFamily.fontStyles
                            .filter((fontStyle) => fontStyle.id === fontStyleId)
                            .flatMap((fontStyle) =>
                                fontStyle.cssRenderInfo.variableAxisValues
                                    ? fontStyle.cssRenderInfo.variableAxisValues.map(
                                          (axisValue) =>
                                              compileTokenName({
                                                  type: TokenType.VARIABLE,
                                                  names: [axisValue.tag],
                                                  value: axisValue.value,
                                              }),
                                      )
                                    : [
                                          compileTokenName({
                                              type: TokenType.FAMILY,
                                              names: [
                                                  fontStyle.cssRenderInfo
                                                      .fontFamilyName,
                                              ],
                                          }),
                                      ],
                            ),
                        stylesToRemove: fontFamily.fontStyles
                            .filter((fontStyle) => fontStyle.id !== fontStyleId)
                            .flatMap((fontStyle) =>
                                fontStyle.cssRenderInfo.variableAxisValues
                                    ? fontStyle.cssRenderInfo.variableAxisValues.map(
                                          (axisValue) =>
                                              compileTokenName({
                                                  type: TokenType.VARIABLE,
                                                  names: [axisValue.tag],
                                                  value: axisValue.value,
                                              }),
                                      )
                                    : [
                                          compileTokenName({
                                              type: TokenType.FAMILY,
                                              names: [
                                                  fontStyle.cssRenderInfo
                                                      .fontFamilyName,
                                              ],
                                          }),
                                      ],
                            ),
                    });

                    // If we've selected all text (or none, in which case the selection will be expanded to all)
                    // then we want to update the `useFontStyleState` context...
                    if (isAllSelected) {
                        setFontStyle(styleIdToCssRenderInfoMap[fontStyleId]);
                    }

                    return newEditorState;
                });
            },
            [
                fontFamily.fontStyles,
                setEditorState,
                setFontStyle,
                isAllSelected,
            ],
        );

        return (
            <Wrap $activeStatus={activeStatus}>
                <Select
                    ref={forwardedRef}
                    e2eId={TEST_ID.TYPE_EDITOR_STYLE_SELECT}
                    onValueChange={onChange}
                    placeholder={
                        variableAxes && selectedValue === undefined
                            ? 'Custom'
                            : usedStyles.join(', ') || 'Styles'
                    }
                    hideIcon={activeStatus === 'inactive'}
                    value={selectedValue}
                    contentMinWidth="180px"
                    contentWidth="15vw"
                >
                    {fontFamily.fontStyles.map(
                        (fontStyle): React.ReactElement => (
                            <SelectItem key={fontStyle.id} value={fontStyle.id}>
                                {fontStyle.name}
                            </SelectItem>
                        ),
                    )}
                </Select>
            </Wrap>
        );
    },
);
TypeEditorStylesInput.displayName = 'TypeEditorStylesInput';

export default TypeEditorStylesInput;
