import React from 'react';
import styled from 'styled-components';
import { labelStyles } from '../utils/formStyles';
import { LOZENGE_SPACING } from '../settings/Global';

const Container = styled.fieldset`
    /* fieldset doesn't support flex/grid layout! */
`;

const Legend = styled.div`
    ${labelStyles};
    margin-bottom: ${LOZENGE_SPACING}px;
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: ${LOZENGE_SPACING}px;
`;

export default function Fieldset({
    legend,
    children,
}: {
    legend?: string;
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <Container>
            {legend ? <Legend>{legend}</Legend> : null}
            <Inner>{children}</Inner>
        </Container>
    );
}
