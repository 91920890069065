import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';

const IntroWrapper = styled.div`
    grid-column: 1 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        grid-column: 1 / -1;
    }
`;

const AsideWrapper = styled.div`
    grid-column: 8 / span 2;

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;

const ContentWrapper = styled.div`
    grid-column: 1 / -1;
    margin-top: var(--spacing8);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        margin-top: var(--spacing7);
    }
`;

function FontInformation({
    intro,
    aside,
    content,
}: {
    intro: React.ReactElement | null;
    aside?: React.ReactElement | null;
    content?: React.ReactElement | null;
}): React.ReactElement {
    return (
        <>
            <IntroWrapper>{intro}</IntroWrapper>
            {aside && <AsideWrapper>{aside}</AsideWrapper>}
            {content && <ContentWrapper>{content}</ContentWrapper>}
        </>
    );
}

export default FontInformation;
