import React from 'react';
import styled from 'styled-components';
import * as Toolbar from '@radix-ui/react-toolbar';
import TypeEditorLineHeightInput from './TypeEditorLineHeightInput';
import TypeEditorTextAlignInput from './TypeEditorTextAlignInput';
import TypeEditorColumnsInput from './TypeEditorColumnsInput';
import TypeEditorTextInput from './TypeEditorTextInput';
import TypeEditorStylesInput from './TypeEditorStylesInput';
import TypeEditorFeatures from './TypeEditorFeatures';
import Icon, { IconType } from './Icon';
import { TEST_ID } from '../settings/E2e';
import { useReset } from './ResetContext';
import { lozengeStyles, statefulLozengeStyles } from './Lozenge';
import { LOZENGE_SPACING, VIEWPORT, ZINDEX } from '../settings/Global';
import { useGlobalState } from './GlobalRuntimeState';
import getLozengeHeight from '../utils/getLozengeHeight';
import { useFontFamily } from './PageContext';
import TypeEditorVariableFontInputs from './TypeEditorVariableFontInputs';
import TypeEditorFontSizeInput from './TypeEditorFontSizeInput';
import TypeEditorLetterSpacingInput from './TypeEditorLetterSpacingInput';

export type TypeEditorActiveStatus =
    | 'inactive'
    | 'hovered'
    | 'active'
    | 'forceHidden';

export const EXPANDED_TOOLBAR_BREAKPOINT = VIEWPORT.TABLET;

const ToolbarRoot = styled(Toolbar.Root)`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: ${LOZENGE_SPACING}px;

    /* Hold the space for two lines of UI elements */
    min-height: ${getLozengeHeight(2)}px;
    z-index: ${ZINDEX.TYPE_EDITOR_TOOLBAR};

    @media screen and (max-width: ${EXPANDED_TOOLBAR_BREAKPOINT}px) {
        min-height: ${getLozengeHeight()}px;
        margin-bottom: 0;
    }
`;

const ToolbarButton = styled(Toolbar.Button)`
    ${statefulLozengeStyles};
`;

export const ButtonGroupToggleGroup = styled(Toolbar.ToggleGroup)`
    ${lozengeStyles};
    display: inline-flex;
    align-items: center;
    gap: ${LOZENGE_SPACING}px;
`;

export const ButtonGroupToggleItem = styled(Toolbar.ToggleItem)`
    color: var(--foregroundColorMix5);

    &:hover,
    &:active,
    &[data-state='on'] {
        color: var(--foregroundColor);
    }
`;

function TypeEditorToolbar({
    activeStatus,
}: {
    activeStatus: TypeEditorActiveStatus;
}): React.ReactElement {
    const fontFamily = useFontFamily();
    const [viewportWidth] = useGlobalState('viewportWidth');
    const resetEditor = useReset();

    return (
        <ToolbarRoot data-cy={TEST_ID.TYPE_EDITOR_HUD}>
            <Toolbar.Button asChild>
                <TypeEditorStylesInput activeStatus={activeStatus} />
            </Toolbar.Button>
            {fontFamily.hasVariableFonts &&
            viewportWidth &&
            viewportWidth > EXPANDED_TOOLBAR_BREAKPOINT ? (
                <TypeEditorVariableFontInputs activeStatus={activeStatus} />
            ) : null}
            <Toolbar.Button asChild>
                <TypeEditorFontSizeInput activeStatus={activeStatus} />
            </Toolbar.Button>
            {viewportWidth && viewportWidth > EXPANDED_TOOLBAR_BREAKPOINT ? (
                <>
                    <Toolbar.Button asChild>
                        <TypeEditorLetterSpacingInput
                            activeStatus={
                                fontFamily.hasVariableFonts &&
                                activeStatus !== 'active'
                                    ? 'forceHidden'
                                    : activeStatus
                            }
                        />
                    </Toolbar.Button>
                    {activeStatus === 'active' ? (
                        <>
                            <Toolbar.Button asChild>
                                <TypeEditorLineHeightInput />
                            </Toolbar.Button>
                            <TypeEditorTextAlignInput />
                            <TypeEditorColumnsInput />
                            <Toolbar.Button asChild>
                                <TypeEditorTextInput />
                            </Toolbar.Button>
                            <TypeEditorFeatures />
                        </>
                    ) : null}
                </>
            ) : null}
            {activeStatus === 'active' ? (
                <ToolbarButton onClick={resetEditor} title="Reset editor">
                    <Icon type={IconType.RESET} />
                </ToolbarButton>
            ) : null}
        </ToolbarRoot>
    );
}

export default React.memo(TypeEditorToolbar);
