import React from 'react';
import styled, { css } from 'styled-components';
import { invertColorVariables } from '../utils/stylesMixins';
import { VIEWPORT, ZINDEX } from '../settings/Global';
import { TEST_ID } from '../settings/E2e';
import { LOZENGE_BORDER_RADIUS } from './Lozenge';
import type { CMS_SSR_CssRenderInfo } from '../gql/api-ssr';
import getCssFromRenderInfo from '../utils/getCssFromRenderInfo';

const Container = styled.div<{
    $isInverted: boolean;
    $isHighlighted: boolean;
}>`
    ${({ $isInverted }): ReturnType<typeof css> | null =>
        $isInverted ? invertColorVariables : null};

    ${({ $isHighlighted }): ReturnType<typeof css> | null =>
        $isHighlighted
            ? css`
                  background-color: var(--foregroundColorMix7);
              `
            : null};

    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 1px;
    color: var(--foregroundColor);
`;

const Label = styled.label<{
    $tighterPadding?: boolean;
    $isDisabled?: boolean;
    $isActive: boolean;
    $isChecked: boolean;
    $roundedCornersTop?: boolean;
    $roundedCornersBottom?: boolean;
}>`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing1);
    padding: ${({ $tighterPadding }): string =>
            $tighterPadding ? 'var(--spacing3)' : 'var(--spacing4)'}
        var(--spacing4);
    outline: 1px solid var(--borderColor);
    ${({ $roundedCornersTop }): ReturnType<typeof css> | null =>
        $roundedCornersTop
            ? css`
                  border-top-left-radius: ${LOZENGE_BORDER_RADIUS}px;
                  border-top-right-radius: ${LOZENGE_BORDER_RADIUS}px;
              `
            : null};
    ${({ $roundedCornersBottom }): ReturnType<typeof css> | null =>
        $roundedCornersBottom
            ? css`
                  border-bottom-left-radius: ${LOZENGE_BORDER_RADIUS}px;
                  border-bottom-right-radius: ${LOZENGE_BORDER_RADIUS}px;
              `
            : null};
    position: relative;
    width: 100%;
    height: 100%; /* some items break to two lines which makes them taller. */
    background-color: var(--backgroundColor);
    z-index: ${ZINDEX.FONT_SELECTOR_INPUT_DEFAULT};

    ${({ $isDisabled }): ReturnType<typeof css> | null =>
        !$isDisabled
            ? css`
                  cursor: pointer;
              `
            : null}

    ${({ $isActive, $isDisabled }): ReturnType<typeof css> | null =>
        $isActive && !$isDisabled
            ? css`
                  outline-color: var(--foregroundColor);
                  z-index: ${ZINDEX.FONT_SELECTOR_INPUT_ACTIVE};
              `
            : null}

    ${({ $isChecked, $isDisabled }): ReturnType<typeof css> | null =>
        $isChecked && !$isDisabled
            ? css`
                  outline-color: var(--borderColor);
                  z-index: ${ZINDEX.FONT_SELECTOR_INPUT_CHECKED};
              `
            : null}
`;

const TitleWrapper = styled.div<{
    $smaller?: boolean;
    $fontSizeMultiplier?: number;
    $hasSubTitle?: boolean;
}>`
    --baseFontSize: ${({ $smaller }): string =>
        $smaller ? 'var(--fontSizeFixed5)' : 'var(--fontSizeFixed6)'};

    /* stylelint-disable custom-property-no-missing-var-function */
    font-size: ${({ $fontSizeMultiplier }): string =>
        $fontSizeMultiplier && $fontSizeMultiplier != 1
            ? `calc(var(--baseFontSize) * ${$fontSizeMultiplier})`
            : `var(--baseFontSize)`};
    /* stylelint-enable custom-property-no-missing-var-function */

    line-height: ${({ $smaller }): string =>
        $smaller ? `var(--lineHeightHeading1)` : `var(--lineHeightHeading3)`};
    color: var(--foregroundColor);

    ${({ $hasSubTitle }): ReturnType<typeof css> | null =>
        !$hasSubTitle
            ? css`
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: baseline;
              `
            : null};
`;

const SubTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Title = styled.p<{ $cssRenderInfo: CMS_SSR_CssRenderInfo }>`
    display: inline;
    ${({ $cssRenderInfo }): ReturnType<typeof css> =>
        getCssFromRenderInfo($cssRenderInfo)};
    hyphens: manual;
`;

const Subtitle = styled.div`
    font-size: var(--fontSizeFixed2);
    color: var(--foregroundColorMix3);
    white-space: pre-wrap;
`;

const ChildrenWrapper = styled.div`
    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 1px;
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

function useTitleWithHyphens(title: string): string {
    return title.replace('Dreiviertelfett', 'Drei\u00ADviertel\u00ADfett');
}

function FontSelectorInput({
    title,
    subtitle,
    subtitleRight,
    checked,
    onChange,
    fontId,
    children,
    smaller,
    active,
    setActiveFont,
    cssRenderInfo,
    fontSizeMultiplier,
    isDisabled,
    roundedCornersTop,
    roundedCornersBottom,
}: {
    title: string;
    subtitle?: React.ReactNode;
    subtitleRight?: React.ReactNode;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    fontId: string;
    children?: React.ReactNode;
    pairChecked?: boolean;
    smaller?: boolean;
    active: boolean;
    setActiveFont: React.Dispatch<React.SetStateAction<string | undefined>>;
    cssRenderInfo: CMS_SSR_CssRenderInfo;
    fontSizeMultiplier: number;
    isDisabled?: boolean;
    roundedCornersTop?: boolean;
    roundedCornersBottom?: boolean;
}): React.ReactElement {
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [isFocussed, setIsFocussed] = React.useState<boolean>(false);

    const onFocus = React.useCallback((): void => setIsFocussed(true), []);
    const onBlur = React.useCallback((): void => setIsFocussed(false), []);
    const onMouseEnter = React.useCallback((): void => setIsHovered(true), []);
    const onMouseLeave = React.useCallback((): void => setIsHovered(false), []);

    const titleWithOptionalHyphens = useTitleWithHyphens(title);

    const timeoutRef = React.useRef<number | null>(null);

    React.useEffect((): void => {
        if (isDisabled) {
            return;
        }
        if (!isHovered && !isFocussed) {
            // Set the 'active' product to undefined, but give the browser a
            // chance to figure out whether the user has already set
            // 'active' to a different product. If that's the case, then we
            // want to bail out.
            timeoutRef.current = window.setTimeout((): void => {
                setActiveFont(
                    (state: string | undefined): string | undefined => {
                        if (state === fontId) {
                            return undefined;
                        } else return state;
                    },
                );
            }, 100);
        } else {
            // If we quickly blur and then focus, then we want the browser to
            // bail out of setting the 'active' product to undefined.
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
            }
            setActiveFont((): string => fontId);
        }
    }, [isHovered, isFocussed, timeoutRef, fontId, setActiveFont, isDisabled]);

    return (
        <Container
            $isInverted={!isDisabled && checked}
            $isHighlighted={!isDisabled && !checked}
            data-cy={TEST_ID.BUY_FONT_CONTAINER}
        >
            <Label
                htmlFor={fontId}
                $tighterPadding={smaller}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onFocus}
                onBlur={onBlur}
                $isDisabled={Boolean(isDisabled)}
                $isActive={active}
                $isChecked={checked}
                data-cy={TEST_ID.BUY_FONT_BUTTON}
                $roundedCornersTop={roundedCornersTop}
                $roundedCornersBottom={roundedCornersBottom}
            >
                <TitleWrapper
                    $smaller={smaller}
                    $fontSizeMultiplier={fontSizeMultiplier}
                    $hasSubTitle={!!subtitle}
                >
                    <HiddenInput
                        checked={!isDisabled && checked}
                        onChange={onChange}
                        id={fontId}
                        data-font-id={fontId}
                        type="checkbox"
                    />
                    <Title
                        data-cy={TEST_ID.BUY_FONT_TITLE}
                        $cssRenderInfo={cssRenderInfo}
                    >
                        {titleWithOptionalHyphens}
                    </Title>
                    {subtitleRight && <Subtitle>{subtitleRight}</Subtitle>}
                </TitleWrapper>
                {subtitle && (
                    <SubTitleWrapper>
                        <Subtitle>{subtitle}</Subtitle>
                    </SubTitleWrapper>
                )}
            </Label>
            {children && (
                <ChildrenWrapper data-cy={TEST_ID.BUY_FONT_CHILDREN}>
                    {children}
                </ChildrenWrapper>
            )}
        </Container>
    );
}

export default React.memo(FontSelectorInput);
