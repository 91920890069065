import React from 'react';
import {
    FontStylesContainer,
    FontStylesItem,
    FontStylesTitle,
    FontStylesWeight,
    FontStylesFragmentLink,
} from './FontStylesElements';
import type { FontFamily } from './PageContext';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { TEST_ID } from '../settings/E2e';
import getTypeEditorId from '../utils/getTypeEditorId';
import stripSubfamilyFromStyleName from '../utils/stripSubfamilyFromStyleName';

function FontFamilyFontStyles({
    fontFamily,
    fontSizeMultiplier,
    withFragmentLinks,
}: {
    fontFamily: Pick<
        FontFamily,
        | 'fontStyles'
        | 'vfRomanFiles'
        | 'vfItalicFiles'
        | 'mainSubfamily'
        | 'subfamilies'
        | 'hasVariableFonts'
    >;
    fontSizeMultiplier: number;
    withFragmentLinks?: boolean;
}): React.ReactElement {
    return (
        <FontStylesContainer>
            {fontFamily.fontStyles
                .sort(sortFontsByTypographicRanking)
                .map((style): React.ReactElement | null => {
                    const mainSubFamily =
                        fontFamily.hasVariableFonts &&
                        fontFamily.subfamilies.length
                            ? fontFamily.mainSubfamily
                                ? fontFamily.mainSubfamily
                                : fontFamily.subfamilies[0]
                            : undefined;
                    if (
                        mainSubFamily &&
                        style.fontSubfamily?.name !== mainSubFamily.name
                    ) {
                        return null;
                    }
                    const styleName = mainSubFamily
                        ? stripSubfamilyFromStyleName(
                              style.name,
                              mainSubFamily.name,
                          )
                        : style.name;

                    const Inner = (props: {
                        children: React.ReactNode;
                    }): React.ReactNode =>
                        withFragmentLinks ? (
                            <FontStylesFragmentLink
                                href={`#${getTypeEditorId(style.name)}`}
                                updateUri={false}
                            >
                                {props.children}
                            </FontStylesFragmentLink>
                        ) : (
                            props.children
                        );

                    return (
                        <FontStylesItem
                            key={style.id}
                            $fontSizeMultiplier={fontSizeMultiplier}
                            data-cy={TEST_ID.FONT_FAMILY_STYLES}
                        >
                            <Inner>
                                <FontStylesTitle
                                    $cssRenderInfo={style.cssRenderInfo}
                                    data-cy={TEST_ID.FONT_FAMILY_STYLE_TITLE}
                                >
                                    {styleName}
                                </FontStylesTitle>
                                <FontStylesWeight>
                                    {style.weight}
                                </FontStylesWeight>
                            </Inner>
                        </FontStylesItem>
                    );
                })}
        </FontStylesContainer>
    );
}

export default React.memo(FontFamilyFontStyles);
