import React from 'react';
import styled, { css } from 'styled-components';
import { createEulaUrl, createFaqUrl } from '../utils/urlHelper';
import InlineUnderlinedLink from '../components/InlineUnderlinedLink';
import { ReactComponent as _DesktopLicenceIcon } from '../assets/icons/licence-desktop.svg';
import { ReactComponent as _WebLicenceIcon } from '../assets/icons/licence-web.svg';
import { ReactComponent as _AppLicenceIcon } from '../assets/icons/licence-app.svg';
import { ReactComponent as _BroadcastLicenceIcon } from '../assets/icons/licence-broadcast.svg';
import { ReactComponent as _AdvertisingLicenceIcon } from '../assets/icons/licence-advertising.svg';
import { ReactComponent as _OemLicenceIcon } from '../assets/icons/licence-oem.svg';
import { ReactComponent as _UdlaLicenceIcon } from '../assets/icons/licence-enterprise.svg';
import { LicenceTypeId } from '../gql/api-public';

const svgStyles = css`
    fill: var(--foregroundColor);

    /* Width of the widest SVG */
    width: 24px;
`;

export const DesktopLicenceIcon = styled(_DesktopLicenceIcon)`
    ${svgStyles};
`;

export const WebLicenceIcon = styled(_WebLicenceIcon)`
    ${svgStyles};
`;

export const AppLicenceIcon = styled(_AppLicenceIcon)`
    ${svgStyles};
`;

export const BroadcastLicenceIcon = styled(_BroadcastLicenceIcon)`
    ${svgStyles};
`;

export const AdvertisingLicenceIcon = styled(_AdvertisingLicenceIcon)`
    ${svgStyles};
`;

export const OemLicenceIcon = styled(_OemLicenceIcon)`
    ${svgStyles};
`;

export const UdlaLicenceIcon = styled(_UdlaLicenceIcon)`
    ${svgStyles};
`;

const sharedCompatibilitySection = (
    <>
        <dt>Compatible with</dt>
        <dd>
            <p>
                Mac and PC desktop computer applications like Photoshop,
                Illustrator, Sketch, MS Office, etc.
            </p>
        </dd>
        <hr />
    </>
);

const sharedFontFormatOtfSection = (
    <>
        <dt>Font format</dt>
        <dd>
            <p>
                OpenType Format (OTF)
                <br />
                TrueType Format (TTF) for Variable Fonts
            </p>
        </dd>
        <hr />
    </>
);

export const desktopLicenceSummary = 'Install fonts for use on your computers';
export const desktopLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                Your users can install these fonts on computers to design
                printed materials, packaging, logos, images, signage and for
                ePub embedding. Please see the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                for further information about permitted uses.
            </p>
        </dd>
        <hr />
        {sharedCompatibilitySection}
        <dt>User</dt>
        <dd>
            <p>
                Means one user. A user can install the fonts on as many devices
                as required.
            </p>
        </dd>
        <hr />
        {sharedFontFormatOtfSection}
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.DESKTOP)}>
                    Desktop Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const webLicenceSummary = 'Host fonts on your website';
export const webLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                You can host these fonts on your website and use them with
                @font-face CSS. You can also use the fonts in your dynamic
                web-advertising and/or email marketing. Klim does not offer a
                font hosting service. Please see the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.WEB)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                for full information.
            </p>
        </dd>
        <hr />
        <dt>Domain</dt>
        <dd>
            <p>
                This licence covers one domain name and any related sub-domains.
                You may also use the fonts on internal sites like intranets, as
                long as all use is covered by your licence tier. Please see the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.WEB)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                for full information.
            </p>
        </dd>
        <hr />
        <dt>Page view</dt>
        <dd>
            <p>Means one request for the viewing of a page on your website.</p>
        </dd>
        <hr />
        <dt>Unique user</dt>
        <dd>
            <p>Means an individual visitor to your website.</p>
        </dd>
        <hr />
        <dt>Hinting</dt>
        <dd>
            <p>These fonts are professionally hand-hinted.</p>
        </dd>
        <hr />
        <dt>Font formats</dt>
        <dd>
            <p>Web Open Font Format 2.0 (WOFF2)</p>
        </dd>
        <hr />
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.WEB)}>
                    Web Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const appLicenceSummary = 'Embed fonts into your app or game';
export const appLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                You can embed these fonts into your app. Your app may run on any
                software platform and operating system.
            </p>
        </dd>
        <hr />
        <dt>Application</dt>
        <dd>
            <p>
                This licence covers an app that’s functionally equivalent across
                multiple platforms and operating systems.
            </p>
        </dd>
        <hr />
        <dt>Downloads</dt>
        <dd>
            <p>Means the total number of downloads of your app by end users.</p>
        </dd>
        <hr />
        <dt>Monthly active user</dt>
        <dd>
            <p>
                Means one end user that accesses or views your app at least once
                per month.
            </p>
        </dd>
        <hr />
        <dt>Font formats</dt>
        <dd>
            <p>TrueType Format (TTF)</p>
        </dd>
        <hr />
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.APP)}>
                    App Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const broadcastLicenceSummary =
    'Use fonts in your film, tv show or network';
export const broadcastLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                Your users can install these fonts on computers to make
                non-fiction or entertainment content intended for visual
                transmission, via television, internet, film or other visual
                broadcast media, as well as marketing communications including
                movie trailers or related show or series promotions and other
                associated marketing, such as pamphlets/brochures, posters,
                print advertising, outdoor advertising (OOH), web advertising
                (e.g. web banners), digital screens and billboards
                (non-interactive), and presentations (e.g. Powerpoint) related
                to your broadcasting media. Please see the{' '}
                <InlineUnderlinedLink
                    to={createEulaUrl(LicenceTypeId.BROADCAST)}
                >
                    licence terms
                </InlineUnderlinedLink>{' '}
                for further information about permitted uses.
            </p>
        </dd>
        <hr />
        {sharedCompatibilitySection}
        <dt>Production budget</dt>
        <dd>
            <p>
                Means the total sum of money in USD set aside to produce Your
                visual media project, e.g. Your TV show/series or film.
            </p>
        </dd>
        <hr />
        <dt>Audience size</dt>
        <dd>
            <p>
                Means the potential number of people your media content is
                likely to reach.
            </p>
        </dd>
        <hr />
        <dt>Authorised persons</dt>
        <dd>
            <p>
                Means any person You have given explicit authority to act on
                your behalf.
            </p>
        </dd>
        <hr />
        {sharedFontFormatOtfSection}
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink
                    to={createEulaUrl(LicenceTypeId.BROADCAST)}
                >
                    Broadcasting Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const advertisingLicenceSummary = 'Use fonts in your TVCs or video ads';
export const advertisingLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                Your users can install these fonts on computers to make TV
                commercials or promotional videos/films for campaigns that are
                intended for paid advertising placements online, via social
                media and/or on TV. Please see the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.AD)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                for further information about permitted uses.
            </p>
        </dd>
        <hr />
        {sharedCompatibilitySection}
        <dt>Impressions</dt>
        <dd>
            <p>
                Measure the number of times a piece of media content — be it a
                digital billboard, a web banner, a social media advertisement,
                or a TV commercial — is consumed. The content may be consumed
                many times by the same user, each exposure counts as an
                impression.
            </p>
        </dd>
        <hr />
        <dt>Authorised persons</dt>
        <dd>
            <p>
                Means any person You have given explicit authority to act on
                your behalf.
            </p>
        </dd>
        <hr />
        <dt>Font format</dt>
        <dd>
            <p>
                OpenType Format (OTF)
                <br />
                TrueType Format (TTF) for Variable Fonts
            </p>
            <p>
                <b>Note:</b> You will not receive a font download when you
                purchase this licence. See the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.AD)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                or our{' '}
                <InlineUnderlinedLink to={createFaqUrl()}>
                    FAQs
                </InlineUnderlinedLink>{' '}
                for more information.
            </p>
        </dd>
        <hr />
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.AD)}>
                    Advertising Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const oemLicenceSummary = 'Embed fonts in your hardware device';
export const oemLicenceDisclosure = (
    <dl>
        <dt>Permitted use</dt>
        <dd>
            <p>
                You can install and embed these fonts within an operating system
                that enables your hardware device to run software applications
                and essential periphery services. Please see the{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.OEM)}>
                    licence terms
                </InlineUnderlinedLink>{' '}
                for further information about permitted uses.
            </p>
        </dd>
        <hr />
        {sharedCompatibilitySection}
        <dt>Device</dt>
        <dd>
            <p>
                Means a single unit of hardware that You own or have the rights
                to produce or procure.
            </p>
        </dd>
        <hr />
        {sharedFontFormatOtfSection}
        <dt>Licence</dt>
        <dd>
            <p>
                Read the full{' '}
                <InlineUnderlinedLink to={createEulaUrl(LicenceTypeId.OEM)}>
                    OEM Font Licence
                </InlineUnderlinedLink>
                .
            </p>
        </dd>
    </dl>
);

export const udlaLicenceSummary = 'Enterprise - TODO';
export const udlaLicenceDisclosure = <></>;
