import React from 'react';
import type { SelectionState } from 'draft-js';
import { useEditorState } from '../../components/TypeEditorContext';

export default function useDraftJsSelection(): SelectionState {
    const [editorState] = useEditorState();
    return React.useMemo(() => {
        return editorState.getSelection();
    }, [editorState]);
}
