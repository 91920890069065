import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../settings/Global';
import { useLetterSpacingState } from './TypeEditorContext';
import { type TypeEditorActiveStatus } from './TypeEditorToolbar';
import {
    IconWithActiveStatus,
    SliderWithActiveStatus,
    ValueWithActiveStatus,
    WidgetContainerWithActiveStatus,
} from './TypeEditorToolbarCommon';

const Container = styled(WidgetContainerWithActiveStatus)`
    --lozengeMinWidth: 180px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        --lozengeMinWidth: 160px;
    }
`;

const TypeEditorLetterSpacingInput = React.forwardRef(
    (
        {
            activeStatus,
        }: {
            activeStatus: TypeEditorActiveStatus;
        },
        forwardedRef: React.Ref<HTMLSpanElement>,
    ): React.ReactElement => {
        const [letterSpacing, setLetterSpacing] = useLetterSpacingState();

        return (
            <Container $activeStatus={activeStatus} title="Letter spacing">
                <IconWithActiveStatus
                    type="LETTER_SPACING"
                    $activeStatus={activeStatus}
                />
                <SliderWithActiveStatus
                    ref={forwardedRef}
                    onValueChange={(val): void =>
                        val.length ? setLetterSpacing(val[0]) : undefined
                    }
                    min={-0.1}
                    max={0.2}
                    value={[letterSpacing]}
                    step={0.001}
                    $activeStatus={activeStatus}
                />
                <ValueWithActiveStatus $activeStatus={activeStatus}>
                    {letterSpacing.toFixed(3)}
                </ValueWithActiveStatus>
            </Container>
        );
    },
);
TypeEditorLetterSpacingInput.displayName = 'TypeEditorLetterSpacingInput';

export default TypeEditorLetterSpacingInput;
