import Decimal from 'decimal.js-light';

// Apply same settings as Python's Decimal (defaults)
Decimal.config({ precision: 28, rounding: Decimal.ROUND_HALF_UP });

/**
 * This aims to round exactly how backend code rounds, using Python's Decimal.ROUND_HALF_UP.
 */
export default function roundHalfUp(
    input: number,
    decimalPlaces: number = 0,
): number {
    return new Decimal(input).toDecimalPlaces(decimalPlaces).toNumber();
}
