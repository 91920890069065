import React from 'react';
import FontFace from './FontFace';
import type { FontFamily, FontFamilyGroup } from './PageContext';
import type { Font } from './FontFace';
import { createStaticAssetUrl } from '../utils/urlHelper';

type UnionFontFamily = FontFamilyGroup['fontFamilies'][number] | FontFamily;
type UnionFontStyle = UnionFontFamily['fontStyles'][number];

function GlobalRemoteFonts({
    fontFamily,
    fontFamilyGroup,
}: {
    fontFamily?: FontFamily;
    fontFamilyGroup?: FontFamilyGroup;
}): React.ReactElement | null {
    if (!fontFamily && !fontFamilyGroup) {
        return null;
    }
    const fontFamilies: UnionFontFamily[] = fontFamilyGroup?.fontFamilies
        ? fontFamilyGroup.fontFamilies
        : [];
    if (fontFamily) {
        fontFamilies.push(fontFamily);
    }

    const remoteFonts: Font[] =
        //
        // Static fonts
        //
        fontFamilies
            .filter((ff) => !ff.hasVariableFonts)
            .reduce(
                (carry: UnionFontStyle[], fontFamily): UnionFontStyle[] =>
                    carry.concat(fontFamily.fontStyles),
                [],
            )
            .filter((fontStyle) => fontStyle.ttfFiles?.woff2File)
            .map((fontStyle): Font => {
                return {
                    fontFamily: fontStyle.cssRenderInfo.fontFamilyName,
                    fontWeight: fontStyle.weight,
                    fontStyle: 'normal',
                    ttfFiles: {
                        woff2File: createStaticAssetUrl(
                            fontStyle.ttfFiles?.woff2File || '',
                        ),
                    },
                };
            })
            .concat(
                //
                // Variable fonts
                //
                fontFamilies
                    .filter((fontFamily) => fontFamily.hasVariableFonts)
                    .flatMap((fontFamily) => {
                        return [
                            {
                                fontFamily:
                                    fontFamily.cssRenderInfo.fontFamilyName,
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                ttfFiles: {
                                    woff2File: createStaticAssetUrl(
                                        // VF will always have this...
                                        fontFamily.vfRomanFiles?.woff2File ||
                                            '',
                                    ),
                                },
                            },
                            {
                                fontFamily:
                                    fontFamily.cssRenderInfo.fontFamilyName,
                                fontWeight: 'normal',
                                fontStyle: 'italic',
                                ttfFiles: {
                                    woff2File: createStaticAssetUrl(
                                        // VF will always have this...
                                        fontFamily.vfItalicFiles?.woff2File ||
                                            '',
                                    ),
                                },
                            },
                        ];
                    }),
            );

    if (!remoteFonts.length) {
        return null;
    }

    return <FontFace fonts={remoteFonts} preventFallbackFont />;
}

export default React.memo(GlobalRemoteFonts);
