import React from 'react';
import { useEditorState } from '../../components/TypeEditorContext';
import useDraftJsSelection from './useDraftJsSelection';

/**
 * Returns `true` when we've selected all text
 * (or none, in which case the selection will be expanded to all).
 */
export default function useDraftJsIsAllSelected(): boolean {
    const [editorState] = useEditorState();
    const currentSelection = useDraftJsSelection();

    return React.useMemo(() => {
        return (
            currentSelection.isCollapsed() ||
            (currentSelection.getStartOffset() === 0 &&
                currentSelection.getEndOffset() ===
                    editorState.getCurrentContent().getPlainText('').length)
        );
    }, [editorState, currentSelection]);
}
