import { EditorState, Modifier, SelectionState } from 'draft-js';
import type { ContentState } from 'draft-js';
import getSelectionToApplyInlineStylesTo from './getSelectionToApplyInlineStylesTo';

const applyStyles = (
    content: ContentState,
    selectionToApplyTo: SelectionState,
    stylesToApply?: string[],
): ContentState => {
    if (!stylesToApply) {
        return content;
    }
    return stylesToApply.reduce(
        (carry: ContentState, styleName: string): ContentState => {
            return Modifier.applyInlineStyle(
                carry,
                selectionToApplyTo,
                styleName,
            );
        },
        content,
    );
};

const removeStyles = (
    content: ContentState,
    selectionToApplyTo: SelectionState,
    stylesToRemove?: string[],
): ContentState => {
    if (!stylesToRemove) {
        return content;
    }
    return stylesToRemove.reduce(
        (carry: ContentState, styleName: string): ContentState => {
            return Modifier.removeInlineStyle(
                carry,
                selectionToApplyTo,
                styleName,
            );
        },
        content,
    );
};

export default function updateEditorInlineStyles({
    editorState,
    stylesToApply,
    stylesToRemove,
}: {
    editorState: EditorState;
    stylesToApply?: string[];
    stylesToRemove?: string[];
}): EditorState {
    const initialContent = editorState.getCurrentContent();
    const selectionToApplyTo = getSelectionToApplyInlineStylesTo(editorState);

    const contentWithStylesRemoved = removeStyles(
        initialContent,
        selectionToApplyTo,
        stylesToRemove,
    );
    const newContentState = applyStyles(
        contentWithStylesRemoved,
        selectionToApplyTo,
        stylesToApply,
    );

    return EditorState.forceSelection(
        EditorState.push(editorState, newContentState, 'change-inline-style'),
        selectionToApplyTo,
    );
}
