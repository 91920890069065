import React from 'react';
import { css } from 'styled-components';
import AccountUserDetailsForm from './AccountUserDetailsForm';
import { User } from '../hooks/useUserQuery';
import { useCountries } from '../hooks/useConfig';
import { BUTTON_AND_FIELD_HEIGHT, VIEWPORT } from '../settings/Global';
import { CountryChoices } from '../gql/api-public';
import { TEST_ID } from '../settings/E2e';
import AccountCartAddress from './AccountCartAddress';
import useTaxIdLabel from '../hooks/useTaxIdLabel';
import {
    AccountRow,
    AccountRowData,
    AccountRowsContainer,
    AccountRowTitle,
    AccountSpacer,
} from './AccountElements';

const firstLineStyles = css`
    line-height: ${BUTTON_AND_FIELD_HEIGHT}px;

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        line-height: initial;
    }
`;

function AccountUserDetails({
    user,
    isEditable,
    setIsEditable,
}: {
    user: User;
    isEditable: boolean;
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
    const countries = useCountries();
    const taxIdLabel = useTaxIdLabel(user.billingCountry || CountryChoices.US);

    return (
        <AccountRowsContainer data-cy={TEST_ID.ACCOUNT_USER_DETAILS}>
            {isEditable ? (
                <>
                    <AccountRow $noBorder>
                        <AccountUserDetailsForm
                            user={user}
                            onComplete={(): void => setIsEditable(false)}
                        />
                    </AccountRow>
                </>
            ) : (
                <>
                    <AccountRow>
                        <AccountRowTitle css={firstLineStyles}>
                            Name
                        </AccountRowTitle>
                        <AccountRowData $tabletColEnd={2} css={firstLineStyles}>
                            {user.fullName}
                        </AccountRowData>
                    </AccountRow>
                    <AccountRow>
                        <AccountRowTitle>Email</AccountRowTitle>
                        <AccountRowData $tabletColEnd={2}>
                            {user.email}
                        </AccountRowData>
                    </AccountRow>
                    <AccountRow>
                        <AccountRowTitle>Newsletter</AccountRowTitle>
                        <AccountRowData $tabletColEnd={2}>
                            {user.emailOptIn ? `Subscribed` : `Unsubscribed`}
                        </AccountRowData>
                    </AccountRow>
                    <AccountRow $noBorder={!user.billingVatNumber}>
                        <AccountRowTitle>Billing address</AccountRowTitle>
                        <AccountRowData>
                            <AccountCartAddress
                                companyName={user.billingCompanyName}
                                address1={user.billingAddress1}
                                address2={user.billingAddress2}
                                city={user.billingCity}
                                postcode={user.billingPostcode}
                                state={user.billingState}
                                countryCode={user.billingCountry}
                                countries={countries}
                            />
                        </AccountRowData>
                    </AccountRow>
                    {user.billingVatNumber ? (
                        <AccountRow $noBorder>
                            <AccountRowTitle>{taxIdLabel}</AccountRowTitle>
                            <AccountRowData>
                                {user.billingVatNumber}
                            </AccountRowData>
                        </AccountRow>
                    ) : null}
                </>
            )}
            <AccountSpacer />
        </AccountRowsContainer>
    );
}

export default React.memo(AccountUserDetails);
