/**
 * Formats a Django date string, either YYYY-MM-DD or ISO 8601, into a nice string for the website.
 */
export default function formatDjangoDateString(
    dateStr: string,
    withTime = false,
): string {
    const date = new Date(dateStr);
    if (date.getFullYear() <= 1970) {
        throw Error(`Invalid date: ${dateStr} -> ${date}`);
    }

    // Settings for Intl.DateTimeFormat
    const locale = 'en-US';
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        hourCycle: 'h12',
        minute: 'numeric',
    };

    let dateTimeFormat: Intl.DateTimeFormat;
    try {
        dateTimeFormat = new Intl.DateTimeFormat(locale, dateOptions);
    } catch (e) {
        if (!(e instanceof RangeError)) {
            throw e;
        }
        // Fallback timezone
        dateOptions.timeZone = 'Pacific/Auckland';
        dateTimeFormat = new Intl.DateTimeFormat(locale, dateOptions);
    }

    // Format date
    const dateParts = dateTimeFormat.formatToParts(date);
    const day = dateParts.find((dp) => dp.type === 'day')?.value;
    const month = dateParts.find((dp) => dp.type === 'month')?.value;
    const year = dateParts.find((dp) => dp.type === 'year')?.value;

    if (day === undefined || month === undefined || year === undefined) {
        throw Error(
            `Invalid date parts: ${dateStr} -> ${date} (${day}-${month}-${year})`,
        );
    }

    const dateOut = `${day} ${month} ${year}`;
    if (!withTime) {
        return dateOut;
    }

    // Format time
    const hour = dateParts.find((dp) => dp.type === 'hour')?.value;
    const minute = dateParts.find((dp) => dp.type === 'minute')?.value;
    const dayPeriod = dateParts
        .find((dp) => dp.type === 'dayPeriod')
        ?.value.toLowerCase();

    if (hour === undefined || minute === undefined) {
        throw Error(
            `Invalid time parts: ${dateStr} -> ${date} (${hour}:${minute})`,
        );
    }

    return `${dateOut} at ${hour}:${minute}${
        dayPeriod !== undefined ? dayPeriod : ''
    }`;
}
