import React from 'react';
import styled from 'styled-components';
import FontFamilyGroupsListItem from './FontFamilyGroupsListItem';
import type { FontFamilyGroup, FontFamily } from './FontFamilyGroupsListItem';
import notPreRelease from '../utils/notPreRelease';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import useConfig from '../hooks/useConfig';
import useIsUserTestingSite from '../hooks/useIsUserTestingSite';
import isWhitelisted from '../utils/isWhitelisted';
import {
    darkColorScheme,
    lightColorScheme,
    SimpleColorScheme,
} from './ColorSchemeContext';
import { MARGIN_SMALL } from '../settings/Global';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: ${MARGIN_SMALL}px;
`;

function FontFamilyGroupsList({
    groups,
    fontFamilyIdFilter,
    invertColors,
}: {
    groups: FontFamilyGroup[];
    fontFamilyIdFilter?: string[];
    invertColors?: boolean;
}): React.ReactElement {
    const config = useConfig();
    const isUserTestingSite = useIsUserTestingSite();

    const fontFamilyGroups = React.useMemo(
        () =>
            groups
                // Limit the fonts for the user testing site,
                // so that we're not exposing any pre-release ones there.
                .filter((fontFamilyGroup) => {
                    if (!isUserTestingSite) {
                        return true;
                    }
                    return isWhitelisted(
                        config.userTestingFontFamilyGroupWhitelist,
                        fontFamilyGroup.id,
                    );
                })
                .filter(
                    // Filter out groups without any families in them
                    (fontFamilyGroup): boolean =>
                        (fontFamilyGroup.fontFamilies as FontFamily[])
                            .filter(
                                (ff) =>
                                    !fontFamilyIdFilter?.length ||
                                    fontFamilyIdFilter.includes(ff.id),
                            )
                            .filter(notPreRelease).length > 0,
                )
                .sort(sortFontsByTypographicRanking),
        [
            config.userTestingFontFamilyGroupWhitelist,
            fontFamilyIdFilter,
            groups,
            isUserTestingSite,
        ],
    );
    const previousColorScheme = React.useRef<SimpleColorScheme>(
        fontFamilyGroups.length === 1
            ? fontFamilyGroups[0].colorSchemes[0]
            : invertColors
              ? darkColorScheme
              : lightColorScheme,
    );

    return (
        <Container>
            {fontFamilyGroups.map((fontFamilyGroup): React.ReactElement => {
                if (fontFamilyGroups.length > 1) {
                    /* Alternate default colour schemes, so we never get two black or white rows following each other */
                    const fontFamilyGroupTheme = fontFamilyGroup.colorSchemes
                        .length
                        ? fontFamilyGroup.colorSchemes[0]
                        : undefined;
                    const fontFamilyGroupThemeBackgroundColor =
                        fontFamilyGroupTheme?.backgroundColorAlt
                            ? fontFamilyGroupTheme?.backgroundColorAlt
                            : fontFamilyGroupTheme?.backgroundColor;
                    const previousColorSchemeBackgroundColor =
                        previousColorScheme.current.backgroundColorAlt ||
                        previousColorScheme.current.backgroundColor;

                    const fontColorScheme: SimpleColorScheme | undefined =
                        fontFamilyGroupTheme &&
                        fontFamilyGroupThemeBackgroundColor !==
                            previousColorSchemeBackgroundColor
                            ? fontFamilyGroupTheme
                            : undefined;
                    const defaultColorScheme: SimpleColorScheme =
                        previousColorSchemeBackgroundColor ===
                        lightColorScheme.backgroundColor
                            ? darkColorScheme
                            : lightColorScheme;

                    previousColorScheme.current =
                        fontColorScheme || defaultColorScheme;
                }
                return (
                    <FontFamilyGroupsListItem
                        key={fontFamilyGroup.slug}
                        fontFamilyGroup={fontFamilyGroup}
                        fontFamilyIdFilter={fontFamilyIdFilter}
                        colorScheme={previousColorScheme.current}
                        invertColors={invertColors}
                    />
                );
            })}
        </Container>
    );
}
export default FontFamilyGroupsList;
