import styled, { css } from 'styled-components';
import { Div, LOZENGE_HORIZONTAL_PADDING } from './Lozenge';
import InputSlider from './InputSlider';
import SVGIcon from './SVGIcon';
import { VIEWPORT } from '../settings/Global';
import { type TypeEditorActiveStatus } from './TypeEditorToolbar';

export const WidgetContainer = styled(Div)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${LOZENGE_HORIZONTAL_PADDING}px;
    font-feature-settings: 'tnum';
`;
export const WidgetContainerWithActiveStatus = styled(WidgetContainer)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus === 'forceHidden'
            ? css`
                  visibility: hidden;
              `
            : $activeStatus !== 'active'
              ? css`
                    --lozengeColor: var(--foregroundColorMix6);
                    --lozengeBackgroundColor: transparent;

                    cursor: pointer;
                `
              : null};
`;
export const ValueWithActiveStatus = styled.div<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus === 'forceHidden'
            ? css`
                  visibility: hidden;
              `
            : $activeStatus === 'inactive'
              ? css`
                    color: var(--foregroundColorMix6);
                `
              : $activeStatus === 'hovered'
                ? css`
                      color: var(--foregroundColorMix4);
                  `
                : null};

    @media screen and (max-width: ${VIEWPORT.TABLET_LARGE}px) {
        display: none;
    }
`;
export const IconWithActiveStatus = styled(SVGIcon)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null};
`;
export const SliderWithActiveStatus = styled(InputSlider)<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null};
`;

export const TitleWithActiveStatus = styled.span<{
    $activeStatus: TypeEditorActiveStatus;
}>`
    white-space: nowrap;
    ${({ $activeStatus }): ReturnType<typeof css> | null =>
        $activeStatus !== 'active'
            ? css`
                  visibility: hidden;
              `
            : null};
`;
