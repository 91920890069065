import React from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import AccountLoginForm from '../components/AccountLoginForm';
import AccountUser, { Title } from '../components/AccountUser';
import useUserQuery from '../hooks/useUserQuery';
import {
    createAccountUrl,
    createNextUrlFromLocation,
} from '../utils/urlHelper';
import { HeadingExtraLarge } from '../components/Heading';

export { Head } from '../components/Head';

const Container = styled.div`
    position: relative;
    padding: var(--paddingPageMedium);
    margin: 0 var(--gridMarginGap);
    min-height: 90vh;
`;

function AccountPage(): React.ReactElement {
    const location = useLocation();
    const userQuery = useUserQuery();
    const user = userQuery?.data?.user;

    return (
        <Container>
            {userQuery.loading ? (
                <Title>
                    <HeadingExtraLarge as="h1">
                        Please wait…
                        <br />
                        Loading account details
                    </HeadingExtraLarge>
                </Title>
            ) : user ? (
                <AccountUser user={user} />
            ) : (
                <AccountLoginForm
                    next={
                        createNextUrlFromLocation(location) ||
                        createAccountUrl()
                    }
                />
            )}
        </Container>
    );
}

export default React.memo(AccountPage);
