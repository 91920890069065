import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import Button from './Button';
import Fieldset from './Fieldset';
import FieldTextarea from './FieldTextarea';
import FieldInputGroup from './FieldInputGroup';
import { Fields } from './CheckoutFormElements';
import type { Order } from '../hooks/useUserOrdersQuery';
import { upgradesAccordion } from '../data/faqAccordions';

import { orderUpgradeRequestMutation } from '../utils/runtimeQueries';
import type {
    OrderUpgradeRequestMutationMutation,
    OrderUpgradeRequestMutationMutationVariables,
} from '../gql/api-public';
import { LOZENGE_SPACING, VIEWPORT } from '../settings/Global';
import { sentryException } from '../utils/sentry';
import notNull from '../utils/notNull';
import { useErrorOverlayState } from './GlobalRuntimeState';
import FieldText from './FieldText';
import Paragraph from './Paragraph';
import InlineUnderlinedLink from './InlineUnderlinedLink';
import { createFaqUrl } from '../utils/urlHelper';
import { AccountRow, AccountRowData } from './AccountElements';

const FaqParagraph = styled(Paragraph)`
    margin-top: var(--spacing3);
    grid-column: 5 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const FieldsetContainer = styled.div`
    grid-column: 5 / span 6;

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-column: 3 / span 4;
    }

    @media screen and (max-width: ${VIEWPORT.MOBILE}px) {
        grid-column: 1 / -1;
    }
`;

const ButtonWrap = styled.div`
    margin-top: var(--spacing4);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${LOZENGE_SPACING}px;
`;

interface FormValues {
    details: string;
    licenceWebDomainName: string;
    licenceAppName: string;
}

export default function AccountOrderHistoryForm({
    order,
    setShowForm,
}: {
    order: Order;
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
    const [, setErrorOverlayState] = useErrorOverlayState();
    const { handleSubmit, register, setError, formState } = useForm<FormValues>(
        {
            defaultValues: {},
        },
    );
    const [doRequestUpgrade] = useMutation<
        OrderUpgradeRequestMutationMutation,
        OrderUpgradeRequestMutationMutationVariables
    >(orderUpgradeRequestMutation);

    const label =
        'Let us know what you would like to upgrade. ' +
        'You can upgrade your font licence from a few font styles to a family or collection, ' +
        "or increase the number of 'computers' (desktop) / 'page views' (web) / 'MAUs' or 'downloads' (app).";

    const onSubmit = async ({
        details,
        licenceWebDomainName,
        licenceAppName,
    }: FormValues): Promise<void> => {
        try {
            const result = await doRequestUpgrade({
                variables: {
                    input: {
                        order: order.id,
                        details,
                        licenceWebDomainName,
                        licenceAppName,
                    },
                },
            });

            // handle API errors
            const errors =
                result?.data?.orderUpgradeRequest?.errors?.filter(notNull) ||
                [];

            if (errors.length > 0) {
                errors.forEach((currentError): void => {
                    setError(currentError.field as keyof FormValues, {
                        type: 'generic',
                        message: currentError.messages.shift(),
                    });
                });
                return;
            }

            setShowForm(false);
        } catch (e) {
            sentryException(e);
            setErrorOverlayState({ isShown: true });
        }
    };

    return (
        <AccountRow $noBorder>
            <AccountRowData>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FieldsetContainer>
                        <Fieldset legend="Upgrade order">
                            <Fields>
                                <FieldInputGroup
                                    data-span="double"
                                    input={
                                        <FieldTextarea
                                            label={label}
                                            isError={!!formState.errors.details}
                                            {...register('details', {
                                                required: 'Message is required',
                                                maxLength: 1000,
                                            })}
                                        />
                                    }
                                    error={formState.errors.details}
                                />
                                <FieldInputGroup
                                    data-span="double"
                                    input={
                                        <FieldText
                                            label="Domain name for web licensing (optional)"
                                            isError={
                                                !!formState.errors
                                                    .licenceWebDomainName
                                            }
                                            {...register(
                                                'licenceWebDomainName',
                                                {
                                                    maxLength: 120,
                                                },
                                            )}
                                        />
                                    }
                                    error={
                                        formState.errors.licenceWebDomainName
                                    }
                                />
                                <FieldInputGroup
                                    data-span="double"
                                    input={
                                        <FieldText
                                            label="App name for app licensing (optional)"
                                            isError={
                                                !!formState.errors
                                                    .licenceAppName
                                            }
                                            {...register('licenceAppName', {
                                                maxLength: 120,
                                            })}
                                        />
                                    }
                                    error={formState.errors.licenceAppName}
                                />
                            </Fields>
                        </Fieldset>
                    </FieldsetContainer>
                    <ButtonWrap>
                        <Button
                            type="submit"
                            disabled={formState.isSubmitting || undefined}
                        >
                            {formState.isSubmitting
                                ? 'Sending…'
                                : 'Send upgrade request'}
                        </Button>
                        <Button
                            $type="secondary"
                            disabled={formState.isSubmitting || undefined}
                            type="button"
                            onClick={(): void => setShowForm(false)}
                        >
                            Cancel
                        </Button>
                    </ButtonWrap>
                    <FaqParagraph>
                        Refer to our{' '}
                        <InlineUnderlinedLink
                            to={`${createFaqUrl()}#${upgradesAccordion.id}`}
                        >
                            FAQs
                        </InlineUnderlinedLink>{' '}
                        for more information.
                    </FaqParagraph>
                </form>
            </AccountRowData>
        </AccountRow>
    );
}
