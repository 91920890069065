import React from 'react';
import styled, { type css } from 'styled-components';
import { useFontFamily } from './PageContext';
import { VIEWPORT } from '../settings/Global';
import printFilesize from '../utils/printFilesize';
import type { CMS_SSR_CssRenderInfo } from '../gql/api-ssr';
import getCssFromRenderInfo from '../utils/getCssFromRenderInfo';

const Table = styled.table`
    display: block;
    font-size: var(--fontSizeFixed2);
    padding-top: var(--spacing3);
`;

const Thead = styled.thead`
    display: block;
`;

const Tbody = styled.tbody`
    display: block;
`;

const Hr = styled.td.attrs({ role: 'presentation' })`
    margin: var(--spacing2) 0;
    border-top: 1px solid var(--borderColor);

    tbody & {
        border-top: 1px solid var(--borderColor);
        grid-column: 1 / -1;
    }

    tbody tr:first-child & {
        border-width: 2px;
        border-color: var(--borderColor);
    }
`;

const Tr = styled.tr<{ $hasPixelsColumn: boolean; $hasPointsColumn: boolean }>`
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    grid-column-gap: var(--gridColumnGap);
    align-items: baseline;

    thead & {
        padding: 0;
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-template-columns: 4fr 1fr ${({ $hasPixelsColumn }): string =>
                $hasPixelsColumn ? '1fr' : ''} ${({
                $hasPointsColumn,
            }): string => ($hasPointsColumn ? '1fr' : '')};
    }
`;

const Td = styled.td<{
    $cssRenderInfo?: CMS_SSR_CssRenderInfo;
    $fontSizeMultiplier?: number;
}>`
    font-feature-settings: 'tnum';
    display: block;
    text-align: right;

    ${({ $cssRenderInfo }): ReturnType<typeof css> | null =>
        $cssRenderInfo ? getCssFromRenderInfo($cssRenderInfo) : null};

    &:first-child {
        text-align: left;
    }

    &[data-cell='font-style'] {
        --baseFontSize: var(--fontSizeFixed5);

        font-size: ${({ $fontSizeMultiplier }): string =>
            $fontSizeMultiplier && $fontSizeMultiplier != 1
                ? `calc(var(--baseFontSize) * ${$fontSizeMultiplier});`
                : 'var(--baseFontSize)'};
    }

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:empty {
            display: none;
        }
    }
`;

const Th = styled(Td).attrs({ as: 'th' })`
    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        &:empty {
            display: none;
        }
    }
`;

function FontSizeInfoRow({
    title,
    hasPixelsColumn,
    pixelSize,
    hasPointsColumn,
    pointSize,
    cssRenderInfo,
    filesizeBytes,
    fontSizeMultiplier,
}: {
    title: string;
    hasPixelsColumn: boolean;
    pixelSize: number | null;
    hasPointsColumn: boolean;
    pointSize: number | null;
    cssRenderInfo: CMS_SSR_CssRenderInfo;
    filesizeBytes: number | null | undefined;
    fontSizeMultiplier: number;
}): React.ReactElement {
    return (
        <React.Fragment key={title}>
            <Tr
                role="presentation"
                $hasPixelsColumn={hasPixelsColumn}
                $hasPointsColumn={hasPointsColumn}
            >
                <Hr />
            </Tr>
            <Tr
                $hasPixelsColumn={hasPixelsColumn}
                $hasPointsColumn={hasPointsColumn}
            >
                <Td
                    $cssRenderInfo={cssRenderInfo}
                    $fontSizeMultiplier={fontSizeMultiplier}
                    data-cell="font-style"
                >
                    {title}
                </Td>
                <Td>{pixelSize || null}</Td>
                <Td>{pointSize || null}</Td>
                <Td>{filesizeBytes ? printFilesize(filesizeBytes) : null}</Td>
            </Tr>
        </React.Fragment>
    );
}

function getMinimumSize(
    fontStyles: ReturnType<typeof useFontFamily>['fontStyles'],
    fieldName: 'pixelSize' | 'pointSize',
): number | null {
    const stylesWithSize = fontStyles.filter(
        (fontStyle) => fontStyle[fieldName] !== null,
    );
    if (!stylesWithSize.length) {
        return null;
    }
    const minSize = Math.min(
        ...stylesWithSize.map((fontStyle) => fontStyle[fieldName] || NaN),
    );
    return isNaN(minSize) ? null : minSize;
}

function FontSizeInfo(): React.ReactElement {
    const fontFamily = useFontFamily();
    const { minPixelSize, hasPixelsColumn, minPointSize, hasPointsColumn } =
        React.useMemo(() => {
            const minPixelSize = getMinimumSize(
                fontFamily.fontStyles,
                'pixelSize',
            );
            const minPointSize = getMinimumSize(
                fontFamily.fontStyles,
                'pointSize',
            );
            return {
                minPixelSize,
                hasPixelsColumn: Boolean(minPixelSize),
                minPointSize,
                hasPointsColumn: Boolean(minPointSize),
            };
        }, [fontFamily.fontStyles]);

    return (
        <Table>
            <Thead>
                <Tr
                    $hasPixelsColumn={hasPixelsColumn}
                    $hasPointsColumn={hasPointsColumn}
                >
                    <Th>&nbsp;</Th>
                    <Th>{hasPixelsColumn && 'Pixels'}</Th>
                    <Th>{hasPointsColumn && 'Points'}</Th>
                    <Th>.woff2</Th>
                </Tr>
            </Thead>
            <Tbody>
                {/* Variable fonts */}
                {!fontFamily.hasVariableFonts ? null : (
                    <>
                        {FontSizeInfoRow({
                            title: `${fontFamily.name} roman variable font`,
                            hasPixelsColumn,
                            hasPointsColumn,
                            cssRenderInfo: fontFamily.cssRenderInfo,
                            pixelSize: minPixelSize,
                            pointSize: minPointSize,
                            filesizeBytes:
                                fontFamily.vfRomanFiles?.woff2FileSize,
                            fontSizeMultiplier: fontFamily.fontSizeMultiplier,
                        })}
                        {FontSizeInfoRow({
                            title: `${fontFamily.name} italic variable font`,
                            hasPixelsColumn,
                            hasPointsColumn,
                            cssRenderInfo: {
                                ...fontFamily.cssRenderInfo,
                                isItalic: true,
                            },
                            pixelSize: minPixelSize,
                            pointSize: minPointSize,
                            filesizeBytes:
                                fontFamily.vfItalicFiles?.woff2FileSize,
                            fontSizeMultiplier: fontFamily.fontSizeMultiplier,
                        })}
                    </>
                )}
                {/* Static fonts */}
                {fontFamily.fontStyles.map(
                    (fontStyle): React.ReactElement =>
                        FontSizeInfoRow({
                            title: `${fontFamily.name} ${fontStyle.name}`,
                            hasPixelsColumn,
                            hasPointsColumn,
                            cssRenderInfo: fontStyle.cssRenderInfo,
                            pixelSize: fontStyle.pixelSize,
                            pointSize: fontStyle.pointSize,
                            filesizeBytes: fontStyle.ttfFiles?.woff2FileSize,
                            fontSizeMultiplier: fontFamily.fontSizeMultiplier,
                        }),
                )}
            </Tbody>
        </Table>
    );
}

export default FontSizeInfo;
