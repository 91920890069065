import React from 'react';
import { useLocation } from '@reach/router';
import styled, { css } from 'styled-components';
import { useFontFamilyGroup, type FontFamilyGroup } from './PageContext';
import notNull from '../utils/notNull';
import useCartQuery from '../hooks/useCartQuery';
import FontSelectorInput from './FontSelectorInput';
import {
    addFontProductFromLookup,
    createFontProductLookup,
    removeFontProductFromLookup,
    type FontProductLookup,
} from '../utils/fontProductLookup';
import { VIEWPORT } from '../settings/Global';
import isMaelstromSpecialCase from '../utils/isMaelstromSpecialCase';
import isCalibreSpecialCase from '../utils/isCalibreSpecialCase';
import { getRecentlyVisitedPrereleaseFontFamilyIds } from '../utils/visitedPrereleaseFonts';
import notUndefined from '../utils/notUndefined';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import { sentryException } from '../utils/sentry';
import useConfig from '../hooks/useConfig';
import { TEST_ID } from '../settings/E2e';
import { useDebouncedCartUpdateMutationContext } from '../hooks/useDebouncedCartUpdateMutation';
import { useErrorOverlayState } from './GlobalRuntimeState';
import getMainSubfamilyName from '../utils/getMainSubfamilyName';

const Container = styled.div<{ $isDisabled?: boolean }>`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing7);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing6);
    }

    ${({ $isDisabled }): ReturnType<typeof css> | null =>
        $isDisabled
            ? css`
                  opacity: 0.3;
              `
            : null};
`;

function getAllCheckedFonts(
    lookup: FontProductLookup,
    fontIds: string[],
): string[] {
    return fontIds.reduce((carry: string[], fontId: string): string[] => {
        return [...carry, fontId, ...lookup.descendents[fontId]];
    }, []);
}

function getAllActiveFonts(
    lookup: FontProductLookup,
    activeFont: undefined | string,
): string[] {
    if (!activeFont) {
        return [];
    }
    return [activeFont, ...lookup.descendents[activeFont]];
}

function FontSelector({
    fontFamilyGroup,
}: {
    fontFamilyGroup: FontFamilyGroup;
}): React.ReactElement {
    const cartQuery = useCartQuery();
    const cart = cartQuery?.data?.cart;
    const isDisabled = false; // !cart?.licenceTiers.length;
    const config = useConfig();
    const [, setErrorOverlayState] = useErrorOverlayState();
    const doDebouncedCartUpdate = useDebouncedCartUpdateMutationContext();
    const location = useLocation();
    const fromFontFamilyId: string | undefined = location.state
        ? (location.state as KlimLinkState).fromFontFamilyId
        : undefined;

    const [activeFont, setActiveFont] = React.useState<string | undefined>(
        undefined,
    );

    const lookup = React.useMemo(
        (): FontProductLookup => createFontProductLookup(fontFamilyGroup),
        [fontFamilyGroup],
    );

    const items = React.useMemo(
        (): string[] =>
            cart
                ? cart.items
                      .map((item): string | undefined => item.font?.fontId)
                      .filter(notUndefined)
                      // Only get ids which are relevant to this fontFamilyGroup
                      .filter((item): boolean => !!lookup.byId[item])
                : [],
        [cart, lookup],
    );

    const allActiveFonts = getAllActiveFonts(lookup, activeFont);
    const allCheckedFonts = getAllCheckedFonts(lookup, items);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (isDisabled || !cart) {
                return;
            }
            const { fontId = '' } = event.target.dataset;
            if (!fontId) {
                throw new Error('Empty fontId');
            }

            // After (de)selecting this item this will return the selected items on the page...
            const newItems = event.target.checked
                ? addFontProductFromLookup(items, lookup, fontId)
                : removeFontProductFromLookup(items, lookup, fontId);

            const fontIdsToRemove = items.filter(
                (item): boolean => !newItems.includes(item),
            );

            const fontsToAdd = newItems
                .filter((item): boolean => !items.includes(item))
                .map((fontId) => lookup.byId[fontId]);

            doDebouncedCartUpdate({
                cart,
                config,
                fontIdsToRemove,
                fontsToAdd,
            }).catch((error) => {
                sentryException(error);
                setErrorOverlayState({
                    isShown: true,
                });
            });
        },
        [cart, items, lookup, doDebouncedCartUpdate, isDisabled, config],
    );

    // There is a special rendering case for fonts like Maelstrom which have
    // only a single style per family, where no 'family' inputs are displayed.
    const maelstromSpecialCase = isMaelstromSpecialCase(fontFamilyGroup);

    // There is a special rendering case for fonts like Calibre which have only
    // a single family, in which case we shouldn't render a FontSelectorInput
    // for the 'collection'.
    const calibreSpecialCase = isCalibreSpecialCase(fontFamilyGroup);

    const isCollectionAvailable =
        fontFamilyGroup.isCollection && !calibreSpecialCase;

    return (
        <Container $isDisabled={isDisabled} data-cy={TEST_ID.BUY_FONTS_WRAP}>
            {isCollectionAvailable && (
                <FontSelectorInput
                    isDisabled={isDisabled}
                    title={fontFamilyGroup.productName}
                    subtitle={fontFamilyGroup.productSubtitle}
                    checked={allCheckedFonts.includes(fontFamilyGroup.id)}
                    fontId={fontFamilyGroup.id}
                    onChange={onChange}
                    active={allActiveFonts.includes(fontFamilyGroup.id)}
                    setActiveFont={setActiveFont}
                    fontSizeMultiplier={fontFamilyGroup.fontSizeMultiplier}
                    cssRenderInfo={fontFamilyGroup.cssRenderInfo}
                    roundedCornersTop
                    roundedCornersBottom
                />
            )}
            {fontFamilyGroup.fontFamilies
                .sort((a, b) => {
                    // Sort the font family that we navigated from to the top.
                    if (a.id === fromFontFamilyId) {
                        return -1;
                    } else if (b.id === fromFontFamilyId) {
                        return 1;
                    }
                    // Then sort typographically
                    return sortFontsByTypographicRanking(a, b);
                })
                .filter(notNull)
                .map((fontFamily): React.ReactElement => {
                    const mainSubfamilyName = getMainSubfamilyName(fontFamily);
                    return (
                        <FontSelectorInput
                            key={fontFamily.id}
                            isDisabled={isDisabled}
                            title={fontFamily.productName}
                            subtitle={fontFamily.productSubtitle}
                            fontId={fontFamily.id}
                            onChange={onChange}
                            checked={allCheckedFonts.includes(fontFamily.id)}
                            active={allActiveFonts.includes(fontFamily.id)}
                            setActiveFont={setActiveFont}
                            fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                            cssRenderInfo={fontFamily.cssRenderInfo}
                            roundedCornersTop
                            roundedCornersBottom={
                                maelstromSpecialCase ||
                                fontFamily.fontStyles.length <= 1
                            }
                        >
                            {maelstromSpecialCase
                                ? // Special case for e.g. "Maelstrom" whose families only
                                  // have one style. So don't list them
                                  null
                                : fontFamily.fontStyles
                                      .filter(
                                          (fontStyle) =>
                                              mainSubfamilyName ===
                                              fontStyle.fontSubfamily?.name,
                                      )
                                      .sort(sortFontsByTypographicRanking)
                                      .map(
                                          (
                                              fontStyle,
                                              idx,
                                          ): React.ReactElement | null => {
                                              return (
                                                  <FontSelectorInput
                                                      key={fontStyle.id}
                                                      isDisabled={isDisabled}
                                                      smaller
                                                      title={
                                                          fontStyle.productName
                                                      }
                                                      // Position to the right for font styles...
                                                      subtitleRight={
                                                          fontStyle.productSubtitle
                                                      }
                                                      checked={allCheckedFonts.includes(
                                                          fontStyle.id,
                                                      )}
                                                      fontId={fontStyle.id}
                                                      onChange={onChange}
                                                      active={allActiveFonts.includes(
                                                          fontStyle.id,
                                                      )}
                                                      setActiveFont={
                                                          setActiveFont
                                                      }
                                                      fontSizeMultiplier={
                                                          fontStyle.fontSizeMultiplier
                                                      }
                                                      cssRenderInfo={
                                                          fontStyle.cssRenderInfo
                                                      }
                                                      roundedCornersBottom={
                                                          idx ===
                                                          fontFamily.fontStyles
                                                              .length -
                                                              1
                                                      }
                                                  />
                                              );
                                          },
                                      )}
                        </FontSelectorInput>
                    );
                })}
        </Container>
    );
}

export default function FontSelectorWrapper(): React.ReactElement | null {
    const fontFamilyGroup = useFontFamilyGroup();
    const visitedPrereleaseFonts = fontFamilyGroup.isCollection
        ? []
        : getRecentlyVisitedPrereleaseFontFamilyIds();

    // We need to remove all the fontFamilies which are `isPreRelease === true`.
    // If we've previously visited the font's pre-release page however, and
    // we've remembered that visit then we will show the font.
    //
    // Alternatively, if this is a Collection, then we _can_ show all the
    // pre-release fonts. (new logic implemented 2022-08-17)
    const safeFontFamilyGroup = fontFamilyGroup.isCollection
        ? fontFamilyGroup
        : {
              ...fontFamilyGroup,
              fontFamilies: fontFamilyGroup.fontFamilies.filter(
                  (fontFamily): boolean => {
                      return (
                          !fontFamily.isPreRelease ||
                          visitedPrereleaseFonts.indexOf(fontFamily.id) !== -1
                      );
                  },
              ),
          };

    return <FontSelector fontFamilyGroup={safeFontFamilyGroup} />;
}
