import type { FontFamilyGroup } from '../components/PageContext';

type FontFamily = FontFamilyGroup['fontFamilies'][number];
type FontStyle = FontFamily['fontStyles'][number];

import isMaelstromSpecialCase from './isMaelstromSpecialCase';
import getMainSubfamilyName from './getMainSubfamilyName';

export interface FontProductLookup {
    parents: {
        [id: string]: string;
    };
    children: {
        [id: string]: string[];
    };
    descendents: {
        [id: string]: string[];
    };
    siblings: {
        [id: string]: string[];
    };
    byId: {
        [id: string]: FontFamilyGroup | FontFamily | FontStyle;
    };
}

export function createFontProductLookup(
    fontFamilyGroup: FontFamilyGroup,
): FontProductLookup {
    // Create 'byId'
    const byId: { [id: string]: FontFamilyGroup | FontFamily | FontStyle } = {
        [fontFamilyGroup.id]: fontFamilyGroup,
    };

    const maelstromSpecialCase = isMaelstromSpecialCase(fontFamilyGroup);

    fontFamilyGroup.fontFamilies.forEach((fontFamily): void => {
        byId[fontFamily.id] = fontFamily;

        const mainSubfamilyName = getMainSubfamilyName(fontFamily);
        fontFamily.fontStyles
            .filter(
                (fontStyle) =>
                    mainSubfamilyName === fontStyle.fontSubfamily?.name,
            )
            .forEach((fontStyle): void => {
                byId[fontStyle.id] = fontStyle;
            });
    });

    // Create 'parents'
    const parents: { [id: string]: string } = {};
    fontFamilyGroup.fontFamilies.forEach((fontFamily): void => {
        if (fontFamilyGroup.isCollection) {
            parents[fontFamily.id] = fontFamilyGroup.id;
        }
        if (maelstromSpecialCase) {
            return;
        }
        const mainSubfamilyName = getMainSubfamilyName(fontFamily);
        fontFamily.fontStyles
            .filter(
                (fontStyle) =>
                    mainSubfamilyName === fontStyle.fontSubfamily?.name,
            )
            .forEach((fontStyle): void => {
                parents[fontStyle.id] = fontFamily.id;
            });
    });

    // Create 'children'
    const children: { [id: string]: string[] } = {};
    children[fontFamilyGroup.id] = fontFamilyGroup.fontFamilies.map(
        (fontFamily): string => fontFamily.id,
    );
    if (!maelstromSpecialCase) {
        fontFamilyGroup.fontFamilies.forEach((fontFamily): void => {
            const mainSubfamilyName = getMainSubfamilyName(fontFamily);
            const fontStyles = fontFamily.fontStyles.filter(
                (fontStyle) =>
                    mainSubfamilyName === fontStyle.fontSubfamily?.name,
            );
            children[fontFamily.id] = fontStyles.map(
                (fontStyle): string => fontStyle.id,
            );
            fontStyles.forEach((fontStyle): void => {
                children[fontStyle.id] = [];
            });
        });
    }

    // Create 'descendents'
    const descendents: { [id: string]: string[] } = {
        [fontFamilyGroup.id]: [],
    };
    fontFamilyGroup.fontFamilies.forEach((fontFamily): void => {
        descendents[fontFamilyGroup.id].push(fontFamily.id);
        descendents[fontFamily.id] = [];
        if (maelstromSpecialCase) {
            return;
        }

        const mainSubfamilyName = getMainSubfamilyName(fontFamily);
        fontFamily.fontStyles
            .filter(
                (fontStyle) =>
                    mainSubfamilyName === fontStyle.fontSubfamily?.name,
            )
            .forEach((fontStyle): void => {
                descendents[fontFamilyGroup.id].push(fontStyle.id);
                descendents[fontFamily.id].push(fontStyle.id);
                descendents[fontStyle.id] = [];
            });
    });

    // Create 'siblings'
    const siblings: { [id: string]: string[] } = { [fontFamilyGroup.id]: [] };
    fontFamilyGroup.fontFamilies.forEach(
        (fontFamily, _, fontFamilies): void => {
            siblings[fontFamily.id] = fontFamilies
                .map((fontFamily): string => fontFamily.id)
                .filter(
                    (fontFamilyId): boolean => fontFamilyId !== fontFamily.id,
                );
            if (maelstromSpecialCase) {
                return;
            }

            const mainSubfamilyName = getMainSubfamilyName(fontFamily);
            const fontStyles = fontFamily.fontStyles.filter(
                (fontStyle) =>
                    mainSubfamilyName === fontStyle.fontSubfamily?.name,
            );

            fontStyles.forEach((fontStyle): void => {
                siblings[fontStyle.id] = fontStyles
                    .map((fontStyle): string => fontStyle.id)
                    .filter(
                        (fontStyleId): boolean => fontStyleId !== fontStyle.id,
                    );
            });
        },
    );

    return {
        parents,
        children,
        siblings,
        descendents,
        byId,
    };
}

export function addFontProductFromLookup(
    items: string[],
    lookup: FontProductLookup,
    fontId: string,
): string[] {
    const siblingIds = lookup.siblings[fontId];
    const parentId = lookup.parents[fontId];

    const allSiblingsAdded = siblingIds.every((siblingId): boolean =>
        items.includes(siblingId),
    );

    if (allSiblingsAdded && parentId !== undefined) {
        // If a whole set of sibling products have been checked then they should be
        // consolidated into a single instance of their parent product instead.
        const siblingDescendentIds = siblingIds.reduce(
            (carry: string[], siblingId): string[] => [
                ...carry,
                ...lookup.descendents[siblingId],
            ],
            [],
        );

        return addFontProductFromLookup(
            // Add the parent product
            items
                // Remove sibling products
                .filter((item): boolean => !siblingIds.includes(item))
                // Remove sibling products' descendent products
                .filter(
                    (item): boolean => !siblingDescendentIds.includes(item),
                ),
            lookup,
            parentId,
        );
    } else {
        // Otherwise, we just add the product and remove any descendent
        // products.
        const descendentIds = lookup.descendents[fontId];

        return (
            items
                // Add the product
                .concat(fontId)
                // remove descendents
                .filter((item): boolean => !descendentIds.includes(item))
        );
    }
}

export function removeFontProductFromLookup(
    items: string[],
    lookup: FontProductLookup,
    fontId: string,
): string[] {
    if (items.find((item): boolean => item === fontId)) {
        // If the product is explicitly in the cart, then simply remove it.
        return items.filter((item): boolean => item !== fontId);
    } else {
        // Otherwise, the product was checked by 'inheritance', in which case
        // we need to do some recursion to add sibling products, and ancestral
        // sibling products.
        const siblingIds = lookup.siblings[fontId];
        const siblingDescendentIds = siblingIds.reduce(
            (carry: string[], siblingId): string[] => [
                ...carry,
                ...lookup.descendents[siblingId],
            ],
            [],
        );
        const parentId = lookup.parents[fontId];
        return removeFontProductFromLookup(
            // Recurse
            items
                // Add siblings of the product
                .concat(siblingIds)
                // Remove descendents of those siblings
                .filter(
                    (item): boolean => !siblingDescendentIds.includes(item),
                ),
            lookup,
            parentId,
        );
    }
}
