import React from 'react';
import type { Property } from 'csstype';
import { useLocation } from '@reach/router';
import type { RawDraftContentState, RawDraftInlineStyleRange } from 'draft-js';
import { useQuery } from '@apollo/client';
import {
    DEFAULT_SPECIMEN_MIN_FONT_SIZE_PX,
    SpecimenType,
    VIEWPORT,
} from '../settings/Global';
import {
    useBigSpecimenSet,
    useFontFamily,
    useMediumSpecimenSet,
    useSmallSpecimenSet,
    type BigSpecimenSet,
    type MediumSpecimenSet,
    type SmallSpecimenSet,
} from '../components/PageContext';
import type {
    FontMetricsFragment,
    FontVariationAxesFragment,
} from '../gql/api-ssr';
import type {
    PreviewSpecimenSetQueryQuery,
    PreviewSpecimenSetQueryQueryVariables,
    FontMetricsFragmentFragment,
    FontVariationAxesFragmentFragment,
} from '../gql/api-public';
import notUndefined from './notUndefined';
import notNull from './notNull';
import opentypeFeatureTokens, { TokenType } from './type-editor/tokens';
import getSearchVariableFromLocation from './getSearchVariableFromLocation';
import createRandomTextFromSpecimenSet from './createRandomTextFromSpecimenSet';
import { previewSpecimenSetQuery } from './runtimeQueries';
import useHasMounted from '../hooks/useHasMounted';
import createSpecimenKey from './createSpecimenKey';
import shuffleArray from './shuffleArray';
import getCssFontFamilyNameFromId from './getCssFontFamilyNameFromId';
import type { UnionCssRenderInfo } from '../components/TypeEditorContext';
import { useGlobalState } from '../components/GlobalRuntimeState';

type PreviewSpecimenSet = NonNullable<
    PreviewSpecimenSetQueryQuery['previewSpecimenSet']
>;

type UnionBigSpecimenSet =
    | BigSpecimenSet
    | NonNullable<PreviewSpecimenSet['bigSpecimenSet']>;
type UnionBigSpecimenFontStyle = UnionBigSpecimenSet['fontStyles'][number];
type UnionBigSpecimenWord = UnionBigSpecimenFontStyle['words'][number];

type UnionMediumSpecimenSet =
    | MediumSpecimenSet
    | NonNullable<PreviewSpecimenSet['mediumSpecimenSet']>;
type UnionMediumSpecimenFontStyle =
    UnionMediumSpecimenSet['fontStyles'][number];

type UnionSmallSpecimenSet =
    | SmallSpecimenSet
    | NonNullable<PreviewSpecimenSet['smallSpecimenSet']>;
type UnionSmallSpecimenFontStyle = UnionSmallSpecimenSet['fontStyles'][number];

type UnionFontMetrics = FontMetricsFragment | FontMetricsFragmentFragment;
type UnionFontVariationAxes =
    | FontVariationAxesFragment
    | FontVariationAxesFragmentFragment;

const markupRegex = new RegExp(/([*_`])(.*?)\1/, 'g');

function getMaxColumns(viewportWidth: number | undefined): number {
    return viewportWidth !== undefined && viewportWidth < VIEWPORT.TABLET
        ? 1
        : viewportWidth !== undefined && viewportWidth < VIEWPORT.TABLET_LARGE
          ? 2
          : 3;
}

export interface GeneratedSpecimen {
    key: string;
    fontStyle: {
        id: string;
        name: string;
        cssRenderInfo: UnionCssRenderInfo;
    };
    fontSizeRef: number;
    lineHeight: number;
    state: RawDraftContentState;
    textAlign?: Property.TextAlign;
    columns?: number;
    letterSpacing: number;
    type: SpecimenType;
    lineCount?: number;
    columnFill?: string;
    ttfFiles?: {
        metrics: UnionFontMetrics;
    };
    variableAxes: UnionFontVariationAxes[] | null;
}

function createInlineStyleRangeForOpentypeFeature({
    opentypeFeature,
    length,
}: {
    opentypeFeature: string;
    length: number;
}): RawDraftInlineStyleRange | undefined {
    // Split up e.g. `pnum+onum`
    const opentypeFeatures = opentypeFeature.split('+').sort();
    // Find token
    const token = Object.values(opentypeFeatureTokens).find(
        (token): boolean =>
            token.features.sort().join('|') === opentypeFeatures.join('|'),
    );
    if (!token) {
        return;
    }
    return {
        offset: 0,
        length: length,
        // @ts-ignore: These token names aren't a valid DraftInlineStyleType
        style: token.name,
    };
}

const DEFAULT_APPLIED_TOKENS = [
    opentypeFeatureTokens.defaults,
    opentypeFeatureTokens.numstyDefault,
];

/**
 * All specimens should have a selection of tokens applied by default.
 */
function createDefaultInlineStyleRanges({
    length,
    otherInlineStyleRanges,
}: {
    length: number;
    otherInlineStyleRanges: RawDraftInlineStyleRange[];
}): RawDraftInlineStyleRange[] {
    // If other inline styles already contain numeral settings (NSTY),
    // then we don't want to apply the default here. The numeral settings
    // are represented by radio buttons, so you can't have multiple selected...
    const otherInlineStylesContainNsty =
        otherInlineStyleRanges.findIndex((inlineStyleRange) =>
            inlineStyleRange.style.startsWith(TokenType.OPENTYPE_NSTY),
        ) > -1;

    return DEFAULT_APPLIED_TOKENS.map(
        (token): RawDraftInlineStyleRange | undefined => {
            if (
                token.name.startsWith(TokenType.OPENTYPE_NSTY) &&
                otherInlineStylesContainNsty
            ) {
                return undefined;
            }
            return {
                offset: 0,
                length,
                // @ts-ignore: TS doesn't recognise our custom token name values.
                style: token.name,
            };
        },
    ).filter(notUndefined);
}

enum markupType {
    italic,
    bold,
    c2sc,
}

/**
 * Apply optional *bold*, _italic_ and `C2SC OpenType` styling from any markup present in the text.
 */
function applyMarkupContentStyles(
    content: string,
    boldStyleId: string | null,
    italicStyleId: string | null,
): {
    inlineStyleRanges: RawDraftInlineStyleRange[];
    content: string;
} {
    let newContent = ''; // We'll reconstitute the content without the markup, as it's parsed.
    let nextMatchMarkupType: markupType | undefined;

    const inlineStyleRanges = content
        .split(markupRegex)
        .map((match): RawDraftInlineStyleRange | undefined => {
            switch (match) {
                case '':
                    return;
                case '_':
                    // The next match will be italic
                    nextMatchMarkupType = markupType.italic;
                    break;
                case '*':
                    // The next match will be bold
                    nextMatchMarkupType = markupType.bold;
                    break;
                case '`':
                    // The next match will be C2SC
                    nextMatchMarkupType = markupType.c2sc;
                    break;
                default: {
                    const offset = newContent.length;
                    newContent += match;
                    const thisMarkupType = nextMatchMarkupType;
                    nextMatchMarkupType = undefined;
                    switch (thisMarkupType) {
                        case markupType.italic:
                            if (italicStyleId) {
                                return {
                                    offset: offset,
                                    length: match.length,
                                    // @ts-ignore: Style IDs aren't a valid DraftInlineStyleType
                                    style: getCssFontFamilyNameFromId(
                                        italicStyleId,
                                    ),
                                };
                            }
                            break;
                        case markupType.bold:
                            if (boldStyleId) {
                                return {
                                    offset: offset,
                                    length: match.length,
                                    // @ts-ignore: Style IDs aren't a valid DraftInlineStyleType
                                    style: getCssFontFamilyNameFromId(
                                        boldStyleId,
                                    ),
                                };
                            }
                            break;
                        case markupType.c2sc:
                            return {
                                offset: offset,
                                length: match.length,
                                // @ts-ignore: OpenType token names aren't a valid DraftInlineStyleType
                                style: opentypeFeatureTokens.c2sc.name,
                            };
                    }
                }
            }
        })
        .filter(notUndefined);

    return {
        inlineStyleRanges: inlineStyleRanges,
        content: newContent,
    };
}

/**
 * Sets a number of words at the start of the text in small caps.
 */
function getSmallCapStyleRange(
    text: string,
    wordsInSmallCaps: number,
): RawDraftInlineStyleRange | undefined {
    if (!wordsInSmallCaps) {
        return;
    }
    const length = text.split(' ', wordsInSmallCaps).join(' ').length;
    return createInlineStyleRangeForOpentypeFeature({
        opentypeFeature: 'smcp',
        length,
    });
}

/**
 * Check if a word has been used before or if it starts/ends with the same character as any of the previous
 * two words.
 */
function wordClashesPreviousWords({
    word,
    previousWords,
}: {
    word: UnionBigSpecimenWord;
    previousWords: string[];
}): boolean {
    const wordIdenticalClash = previousWords.some(
        (w): boolean => w.toLowerCase() === word.contentAscii.toLowerCase(),
    );

    const wordStartChar = word.contentAscii.charAt(0);
    const wordEndChar = word.contentAscii.charAt(word.contentAscii.length - 1);

    const previousTwoWords = previousWords.slice(-2, previousWords.length);

    const wordStartCharClashes = previousTwoWords.some(
        (w): boolean => w.charAt(0) === wordStartChar,
    );
    const wordEndCharClashes = previousTwoWords.some(
        (w): boolean => w.charAt(w.length - 1) === wordEndChar,
    );

    return wordIdenticalClash || wordStartCharClashes || wordEndCharClashes;
}

/**
 * Generates a Big specimen set from a specimen configuration and a list of words.
 */
function getRandomBigSpecimensFromSet(
    specimenSet: UnionBigSpecimenSet | undefined,
): GeneratedSpecimen[] {
    if (!specimenSet) {
        return [];
    }

    // We'll remember the words that we're showing, to enforce some rules for subsequent words
    const wordContentsOutput: string[] = [];

    const fontStyles: UnionBigSpecimenFontStyle[] = specimenSet.fontStyles;

    // Iterate the font styles of the set
    return fontStyles.reduce(
        (
            carry: GeneratedSpecimen[],
            fontStyle: UnionBigSpecimenFontStyle,
        ): GeneratedSpecimen[] => {
            // We will mutate this array to remove words as we display them
            const words: UnionBigSpecimenWord[] = fontStyle.words;

            // For each font style grab a number of words.
            // The number is `specimenSet.specimensPerFontStyle` or `words.length` whichever is less.
            const specimens = [
                ...Array(
                    Math.min(specimenSet.specimensPerFontStyle, words.length),
                ),
            ].map((): GeneratedSpecimen | undefined => {
                // We may have run out of words for this font style
                if (!words.length) {
                    return;
                }

                // Get a suitable word
                const word = shuffleArray(words).find(
                    (word): boolean =>
                        !wordClashesPreviousWords({
                            word,
                            previousWords: wordContentsOutput,
                        }),
                );

                if (!word) {
                    return;
                }

                // Remember the word we've output
                wordContentsOutput.push(word.contentAscii);

                // Create a Draft.js `RawDraftContentState` instance
                const wordLength = word.content.length;

                const opentypeFeatureInlineStyleRanges = (
                    word.opentypeFeatures || []
                )
                    .filter(notNull)
                    .map(
                        (
                            opentypeFeature,
                        ): RawDraftInlineStyleRange | undefined =>
                            createInlineStyleRangeForOpentypeFeature({
                                opentypeFeature,
                                length: wordLength,
                            }),
                    )
                    .filter(notUndefined);

                const defaultInlineStyleRanges = createDefaultInlineStyleRanges(
                    {
                        length: wordLength,
                        otherInlineStyleRanges:
                            opentypeFeatureInlineStyleRanges,
                    },
                );

                const rawContentState: RawDraftContentState = {
                    blocks: [
                        {
                            key: '0',
                            text: word.content,
                            type: 'unstyled',
                            depth: 0,
                            inlineStyleRanges: [
                                ...defaultInlineStyleRanges,
                                ...opentypeFeatureInlineStyleRanges,
                            ],
                            entityRanges: [],
                        },
                    ],
                    entityMap: {},
                };

                return {
                    key: createSpecimenKey(rawContentState, 'BIG-'),
                    // We will use this to calculate the font size
                    fontSizeRef: word.width,
                    lineHeight: specimenSet.lineHeight,
                    letterSpacing: specimenSet.letterSpacing,
                    type: SpecimenType.BIG,
                    state: rawContentState,
                    ttfFiles: fontStyle.fontMetrics
                        ? {
                              metrics: fontStyle.fontMetrics,
                          }
                        : undefined,
                    fontStyle: {
                        id: fontStyle.id,
                        name: fontStyle.name,
                        cssRenderInfo: fontStyle.cssRenderInfo,
                    },
                    variableAxes:
                        fontStyle.variableAxes?.filter(notNull) || null,
                };
            });
            return [...carry, ...specimens.filter(notUndefined)];
        },
        [],
    );
}

/**
 * Returns a random OpenType feature with a set likelihood.
 */
function getRandomFeature(
    randomFeatureArray: (string | null)[] | null,
    likelihoodPercentage: number,
): string | null {
    if (
        !randomFeatureArray ||
        !randomFeatureArray.length ||
        Math.random() > 0.01 * likelihoodPercentage
    ) {
        return null;
    }
    const randomIndex = Math.floor(Math.random() * randomFeatureArray.length);
    return randomFeatureArray[randomIndex];
}

/**
 * Generates a Medium specimen set from a specimen configuration and a list of sentences.
 */
function getRandomMediumSpecimensFromSet(
    specimenSet: UnionMediumSpecimenSet | undefined,
): GeneratedSpecimen[] {
    if (!specimenSet) {
        return [];
    }

    const fixedOpentypeFeatures = specimenSet.fixedOpentypeFeatures || [];

    const fontStyles: UnionMediumSpecimenFontStyle[] = specimenSet.fontStyles;

    // We will be removing sentences as we use them
    const sentences = specimenSet.sentences;

    // Iterate the font styles of the set
    return fontStyles.reduce(
        (
            carry: GeneratedSpecimen[],
            fontStyle: UnionMediumSpecimenFontStyle,
        ): GeneratedSpecimen[] => {
            if (!fontStyle.cssRenderInfo) {
                throw Error('CSS render info not set!');
            }

            const numSpecimensToCreate = Math.min(
                specimenSet.specimensPerFontStyle,
                sentences.length,
            );
            const specimens = [...Array(numSpecimensToCreate)].map(
                (): GeneratedSpecimen | undefined => {
                    // We may have run out of sentences for this font style
                    if (!sentences.length) {
                        return;
                    }

                    // Get random sentence
                    const index = Math.floor(Math.random() * sentences.length);
                    const sentence = sentences[index];

                    // Apply markup
                    const {
                        inlineStyleRanges: fontStyleStyleRanges,
                        content: sentenceContent,
                    } = applyMarkupContentStyles(
                        sentence,
                        fontStyle.boldStyleId,
                        fontStyle.italicStyleId,
                    );

                    const sentenceLength = sentenceContent.length;

                    // Ensure we don't use the same sentence again by removing it from the pool
                    sentences.splice(index, 1);

                    // Determine which random OpenType feature to apply
                    const randomFeature = getRandomFeature(
                        specimenSet.randomOpentypeFeatures,
                        specimenSet.randomOpentypeLikelihood,
                    );

                    // Get the OpenType style ranges
                    const openTypeStyleRanges = [randomFeature]
                        .concat(fixedOpentypeFeatures)
                        .map(
                            (
                                opentypeFeature,
                            ): RawDraftInlineStyleRange | undefined => {
                                if (!opentypeFeature) {
                                    return;
                                }
                                return createInlineStyleRangeForOpentypeFeature(
                                    {
                                        opentypeFeature,
                                        length: sentenceLength,
                                    },
                                );
                            },
                        )
                        .filter(notUndefined);

                    const defaultInlineStyleRanges =
                        createDefaultInlineStyleRanges({
                            length: sentenceLength,
                            otherInlineStyleRanges: openTypeStyleRanges,
                        });

                    // Create a Draft.js `RawDraftContentState` instance
                    const rawContentState: RawDraftContentState = {
                        blocks: [
                            {
                                key: '0',
                                text: sentenceContent,
                                type: 'unstyled',
                                depth: 0,
                                inlineStyleRanges: [
                                    ...defaultInlineStyleRanges,
                                    ...openTypeStyleRanges,
                                    ...fontStyleStyleRanges,
                                ],
                                entityRanges: [],
                            },
                        ],
                        entityMap: {},
                    };

                    return {
                        key: createSpecimenKey(rawContentState, 'MEDIUM-'),
                        fontSizeRef: specimenSet.fontSize,
                        lineHeight: specimenSet.lineHeight,
                        letterSpacing: specimenSet.letterSpacing,
                        fontStyle: {
                            id: fontStyle.id,
                            name: fontStyle.name,
                            cssRenderInfo: fontStyle.cssRenderInfo,
                        },
                        type: SpecimenType.MEDIUM,
                        state: rawContentState,
                        ttfFiles: fontStyle.fontMetrics
                            ? {
                                  metrics: fontStyle.fontMetrics,
                              }
                            : undefined,
                        variableAxes:
                            fontStyle.variableAxes?.filter(notNull) || null,
                    };
                },
            );
            return [...carry, ...specimens.filter(notUndefined)];
        },
        [],
    );
}

export function applyMinimumFontSize(
    windowWidth: number,
    specimenType: SpecimenType,
    fontSizePx: number,
): number {
    if (specimenType === SpecimenType.SMALL) {
        // On mobile and tablet ensure that the font size doesn't drop below a sensible minimum
        return windowWidth >= VIEWPORT.TABLET_LARGE
            ? fontSizePx
            : Math.max(fontSizePx, DEFAULT_SPECIMEN_MIN_FONT_SIZE_PX);
    }
    return fontSizePx;
}

function getSingleSmallSpecimen({
    specimenSet,
    fontStyle,
    maxColumns,
    secondarySpecimen = false,
}: {
    specimenSet: UnionSmallSpecimenSet;
    fontStyle: UnionSmallSpecimenFontStyle;
    maxColumns: number;
    secondarySpecimen?: boolean;
}): GeneratedSpecimen {
    const { innerWidth } = window;

    // We estimate the character count per line...
    const lineCountSource = secondarySpecimen
        ? specimenSet.lineCountSecondary
        : specimenSet.lineCount;
    const charsPerLine =
        maxColumns === 3 || innerWidth <= VIEWPORT.MOBILE ? 100 : 150;
    const characterCount = charsPerLine * lineCountSource * maxColumns;
    const columns = Math.min(
        secondarySpecimen ? specimenSet.columnsSecondary : specimenSet.columns,
        maxColumns,
    );
    const fixedOpentypeFeatures = specimenSet.fixedOpentypeFeatures || [];
    const text = createRandomTextFromSpecimenSet({
        sentences: specimenSet.sentences,
        characterCount,
    });

    // Process markup
    const { inlineStyleRanges: fontStyleStyleRanges, content: processedText } =
        applyMarkupContentStyles(
            text,
            fontStyle.boldStyleId,
            fontStyle.italicStyleId,
        );

    const textLength = processedText.length;

    // Determine which random OpenType feature to apply
    const randomFeature = getRandomFeature(
        specimenSet.randomOpentypeFeatures,
        specimenSet.randomOpentypeLikelihood,
    );

    // Get the OpenType style ranges
    const openTypeStyleRanges = [randomFeature]
        .concat(fixedOpentypeFeatures)
        .map((opentypeFeature): RawDraftInlineStyleRange | undefined => {
            if (!opentypeFeature) {
                return;
            }
            return createInlineStyleRangeForOpentypeFeature({
                opentypeFeature,
                length: textLength,
            });
        })
        .filter(notUndefined);

    // Words in small caps?
    const smallCapsStyleRange = getSmallCapStyleRange(
        processedText,
        specimenSet.wordsInSmallCaps,
    );

    const defaultInlineStyleRanges = createDefaultInlineStyleRanges({
        length: textLength,
        otherInlineStyleRanges: openTypeStyleRanges,
    });

    // Create a Draft.js `RawDraftContentState` instance
    const rawContentState: RawDraftContentState = {
        blocks: [
            {
                key: '0',
                text: processedText,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [
                    ...defaultInlineStyleRanges,
                    ...openTypeStyleRanges,
                    ...fontStyleStyleRanges,
                    ...(smallCapsStyleRange ? [smallCapsStyleRange] : []),
                ],
                entityRanges: [],
            },
        ],
        entityMap: {},
    };

    return {
        key: createSpecimenKey(
            rawContentState,
            [
                'SMALL-',
                fontStyle.name,
                fontStyle.pixelSize,
                fontStyle.cssRenderInfo.fontFamilyName,
                maxColumns,
                secondarySpecimen,
            ].join(''),
        ),
        fontSizeRef: secondarySpecimen
            ? specimenSet.fontSizeSecondary
            : specimenSet.fontSize,
        lineHeight: specimenSet.lineHeight,
        letterSpacing: specimenSet.letterSpacing,
        columns,
        lineCount: lineCountSource,
        fontStyle: {
            id: fontStyle.id,
            name: fontStyle.name,
            cssRenderInfo: fontStyle.cssRenderInfo,
        },
        type: SpecimenType.SMALL,
        state: rawContentState,
        ttfFiles: fontStyle.fontMetrics
            ? {
                  metrics: fontStyle.fontMetrics,
              }
            : undefined,
        variableAxes: fontStyle.variableAxes?.filter(notNull) || null,
    };
}

/**
 * Generates a Small specimen set from a specimen configuration and a list of texts.
 */
function getRandomSmallSpecimensFromSet({
    specimenSet,
    maxColumns,
}: {
    specimenSet: UnionSmallSpecimenSet | undefined;
    maxColumns: number;
}): GeneratedSpecimen[] {
    if (!specimenSet) {
        return [];
    }
    if (!specimenSet.sentences.length) {
        return [];
    }

    const fontStyles: UnionSmallSpecimenFontStyle[] = specimenSet.fontStyles;

    // Iterate the font styles of the set
    return fontStyles.reduce(
        (
            carry: GeneratedSpecimen[],
            fontStyle: UnionSmallSpecimenFontStyle,
        ): GeneratedSpecimen[] => {
            const specimensToAdd = [
                getSingleSmallSpecimen({
                    specimenSet,
                    fontStyle,
                    maxColumns,
                }),
                maxColumns === 3 &&
                specimenSet.columnsSecondary &&
                specimenSet.fontSizeSecondary
                    ? getSingleSmallSpecimen({
                          specimenSet,
                          fontStyle,
                          maxColumns,
                          secondarySpecimen: true,
                      })
                    : undefined,
            ].filter(notUndefined);

            return [...carry, ...specimensToAdd];
        },
        [],
    );
}

/**
 * Generates a Big, Medium or Small specimen set from a specimen configuration ID passed as a query string variable.
 */
export function usePreviewSpecimens(): GeneratedSpecimen[] | undefined {
    const { id } = useFontFamily();
    const location = useLocation();
    const specimenConfigId = getSearchVariableFromLocation(location, 'sc');
    const [previewSpecimenSet, setPreviewSpecimenSet] =
        React.useState<PreviewSpecimenSet | null>(null);
    const [viewportWidth] = useGlobalState('viewportWidth');

    useQuery<
        PreviewSpecimenSetQueryQuery,
        PreviewSpecimenSetQueryQueryVariables
    >(previewSpecimenSetQuery, {
        variables: {
            fontFamily: id,
            specimenConfig:
                typeof specimenConfigId === 'string' ? specimenConfigId : '',
        },
        ssr: false,
        // We can't fetch a preview specimen when there's no specimenConfigId,
        // so skip the query in that case.
        skip: typeof specimenConfigId !== 'string' || !specimenConfigId,
        // Fetch fresh, always
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            setPreviewSpecimenSet(data.previewSpecimenSet);
        },
    });

    return React.useMemo<GeneratedSpecimen[] | undefined>(():
        | GeneratedSpecimen[]
        | undefined => {
        if (!previewSpecimenSet) {
            return;
        }
        if (previewSpecimenSet.bigSpecimenSet) {
            return getRandomBigSpecimensFromSet(
                previewSpecimenSet.bigSpecimenSet,
            );
        }
        if (previewSpecimenSet.mediumSpecimenSet) {
            return getRandomMediumSpecimensFromSet(
                previewSpecimenSet.mediumSpecimenSet,
            );
        }
        if (previewSpecimenSet.smallSpecimenSet) {
            return getRandomSmallSpecimensFromSet({
                specimenSet: previewSpecimenSet.smallSpecimenSet,
                maxColumns: getMaxColumns(viewportWidth),
            });
        }
    }, [previewSpecimenSet]);
}

/*
 * Randomly generates (but then memoizes on subsequent renders) a set of
 * specimens for each size.
 */
export function useGeneratedGroupedSpecimens(): GeneratedSpecimen[][] {
    const bigSpecimenSet = useBigSpecimenSet();
    const mediumSpecimenSet = useMediumSpecimenSet();
    const smallSpecimenSet = useSmallSpecimenSet();
    const hasMounted = useHasMounted();
    const [viewportWidth] = useGlobalState('viewportWidth');

    return React.useMemo((): GeneratedSpecimen[][] => {
        // TODO: Fix possibly?
        // We're not rendering specimens at static build time as there are some
        // as-of-yet-unresolved issues with OpenType settings being "remembered"
        // from static build specimens through to runtime random specimens.
        if (!hasMounted) {
            return [];
        }

        return [
            getRandomBigSpecimensFromSet(bigSpecimenSet),
            getRandomMediumSpecimensFromSet(mediumSpecimenSet),
            getRandomSmallSpecimensFromSet({
                specimenSet: smallSpecimenSet,
                maxColumns: getMaxColumns(viewportWidth),
            }),
        ];
    }, [hasMounted, bigSpecimenSet, mediumSpecimenSet, smallSpecimenSet]);
}
