import React from 'react';
import styled from 'styled-components';
import { useCustomFont } from '../components/PageContext';
import CustomFontsPostInformation from '../components/CustomFontsPostInformation';
import _TitledSection from '../components/TitledSection';
import CustomFontsPostStyles from '../components/CustomFontsPostStyles';
import CustomFontsPostSpecimens from '../components/CustomFontsPostSpecimens';
import CustomFontsPostImages from '../components/CustomFontsPostImages';
import { VIEWPORT } from '../settings/Global';
import printStylesCount from '../utils/printStylesCount';
import FontHeroWithWrapper from '../components/FontHeroWithWrapper';

export { Head } from '../components/Head';

const TitledSection = styled(_TitledSection)`
    padding-top: var(--spacing7);
`;

const Container = styled.div`
    padding: var(--paddingPageDefault);
    padding-top: 0;
    margin: 0 var(--gridMarginGap);

    [data-section='styles'],
    [data-section='specimens'],
    [data-section='images'],
    [data-section='information'] {
        padding-top: var(--spacing7);
        margin-top: var(--spacing9);

        @media screen and (max-width: ${VIEWPORT.TABLET}px) {
            margin-top: var(--spacing7);
        }
    }
`;

function CustomFontsPostPage(): React.ReactElement {
    const { styles, images, svgHeading, bitmapHeading, name, slug } =
        useCustomFont();

    const inUseTitle = React.useMemo(() => {
        return slug && ['hokotohu', 'pure-pakati'].includes(slug)
            ? `Process`
            : `${name} in use`;
    }, [slug]);

    return (
        <>
            <FontHeroWithWrapper
                svgHeading={svgHeading}
                svgTitle={name}
                bitmapHeading={bitmapHeading}
            />
            <Container>
                {styles.length > 1 && (
                    <TitledSection
                        data-section="styles"
                        title={printStylesCount(styles.length) || 'Styles'}
                    >
                        <CustomFontsPostStyles />
                    </TitledSection>
                )}
                <div data-section="specimens">
                    <CustomFontsPostSpecimens />
                </div>
                {images.length > 0 && (
                    <TitledSection
                        data-section="images"
                        title={inUseTitle}
                        stacked
                    >
                        <CustomFontsPostImages />
                    </TitledSection>
                )}
                <TitledSection data-section="information" title="Information">
                    <CustomFontsPostInformation />
                </TitledSection>
            </Container>
        </>
    );
}

export default React.memo(CustomFontsPostPage);
