import { FontFeatureSetting } from './FontFeatureSetting';

export interface Token {
    name: string;
    features: FontFeatureSetting[];
    label: string;
}

export const STYLISTIC_SET_LABEL_PREFIX = 'Stylistic set';
export const TOKEN_SEP = '|';
export const TOKEN_NAME_SEP = '*';
export const TOKEN_PREFIX_KLIM = 'KLIM_';

export const enum TokenType {
    OPENTYPE = `${TOKEN_PREFIX_KLIM}OpenType`,
    OPENTYPE_NSTY = `${TOKEN_PREFIX_KLIM}OpenType_Nsty`,
    VARIABLE = `${TOKEN_PREFIX_KLIM}Variable`,
    FAMILY = `${TOKEN_PREFIX_KLIM}FontFamily`,
}

type TokenData = {
    type: TokenType;
    names: string[];
    value?: number;
};

/**
 * Returns the token name prefix based on type+names.
 */
export function getTokenNamePrefix(type: TokenType, names: string[]): string {
    return `${type}${TOKEN_SEP}${names.join(TOKEN_NAME_SEP)}`;
}

/**
 * Token names are structured data. This function
 * compiles a name from data.
 */
export function compileTokenName({ type, names, value }: TokenData): string {
    const prefix = getTokenNamePrefix(type, names);
    if (value !== undefined) {
        return `${prefix}${TOKEN_SEP}${value.toString(10)}`;
    }
    return prefix;
}

/**
 * Token names are structured data. This function
 * decompiles a name into data.
 */
export function decompileTokenName(tokenName: string): TokenData | undefined {
    if (!tokenName.startsWith(TOKEN_PREFIX_KLIM)) {
        return;
    }
    const parts = tokenName.split(TOKEN_SEP);
    if (parts.length < 2 || parts.length > 3) {
        throw new Error(`Invalid token: ${tokenName}`);
    }
    return {
        type: parts[0] as TokenType,
        names: parts[1] === '' ? [] : parts[1].split(TOKEN_NAME_SEP),
        value: parts.length === 3 ? parseFloat(parts[2]) : undefined,
    };
}

function _tokenDef(
    features: FontFeatureSetting[],
    label: string = '',
    isNsty?: boolean,
): Token {
    return {
        name: compileTokenName({
            type: isNsty ? TokenType.OPENTYPE_NSTY : TokenType.OPENTYPE,
            names: features,
        }),
        features,
        label,
    };
}

// All names should be prefixed with 'KLIM' as a clash-prevention measure.
const tokens: Record<string, Token> = {
    /*
     * These `defaults` OpenType features are hard-coded for all Type Editors.
     * If they are altered they should also be altered in back end code
     * as this will affect word width calculations and other things.
     *
     * See: backend/fonts/opentype.py
     *
     * For background on why we hard-code some of these see:
     * https://twitter.com/klimtypefoundry/status/1142156839825498112
     */
    defaults: _tokenDef([
        FontFeatureSetting.kern,
        FontFeatureSetting.liga,
        FontFeatureSetting.clig,
        FontFeatureSetting.calt,
    ]),
    /*
    All non-default, non-hardcoded ones, below
     */
    smcp: _tokenDef([FontFeatureSetting.smcp], 'Small caps'),
    c2sc: _tokenDef([FontFeatureSetting.c2sc], 'All small caps'),
    dlig: _tokenDef([FontFeatureSetting.dlig], 'Discretionary ligatures'),
    swsh: _tokenDef([FontFeatureSetting.swsh], 'Swashes'),
    numstyDefault: _tokenDef([], 'Default numerals', true),
    numstyLining: _tokenDef(
        [FontFeatureSetting.pnum, FontFeatureSetting.lnum],
        'Lining numerals',
        true,
    ),
    numstyOldstyle: _tokenDef(
        [FontFeatureSetting.pnum, FontFeatureSetting.onum],
        'Old-style numerals',
        true,
    ),
    numstyTabularLining: _tokenDef(
        [FontFeatureSetting.tnum, FontFeatureSetting.lnum],
        'Tabular lining numerals',
        true,
    ),
    numstyTabularOldstyle: _tokenDef(
        [FontFeatureSetting.tnum, FontFeatureSetting.onum],
        'Tabular old-style numerals',
        true,
    ),
    zero: _tokenDef([FontFeatureSetting.zero], 'Slashed zero'),
    frac: _tokenDef([FontFeatureSetting.frac], 'Fractions'),
    sups: _tokenDef([FontFeatureSetting.sups], 'Superiors'),
    sinf: _tokenDef([FontFeatureSetting.sinf], 'Inferiors'),
    ordn: _tokenDef([FontFeatureSetting.ordn], 'Ordinals'),
    case: _tokenDef([FontFeatureSetting.case], 'Capital forms'),
};

// Add Stylistic Set tokens
Array.from(Array(20)).map((_, idx) => {
    const token: FontFeatureSetting = (
        idx < 9 ? `ss0${idx + 1}` : `ss${idx + 1}`
    ) as FontFeatureSetting;
    tokens[token] = _tokenDef(
        [token],
        `${STYLISTIC_SET_LABEL_PREFIX} ${idx + 1}`,
    );
});

export default tokens;
