import type {
    Cart,
    Item,
    FontProduct,
    LicenceType,
    LicenceTier,
    CartTier,
    TieredLicence,
} from '../hooks/useCartQuery';
import {
    CurrencyChoices,
    LineItemType,
    FontProductTypeChoice,
} from '../gql/api-public';

export const baseCart: Cart = {
    __typename: 'CartInterface',
    id: '',
    licenceTiers: [],
    items: [],
    discounts: [],
    amountTotal: 0,
    amountSubtotal: 0,
    amountSubtotalForFontsWithLicenceMultiplier: 0,
    amountSubtotalConverted: 0,
    amountTax: 0,
    amountTaxUsd: 0,
    amountTotalUsd: 0,
    exchangeRate: 1,
    taxRate: 0,
    currency: CurrencyChoices.USD,
    emailOptIn: false,
    userEmail: null,
    billingName: null,
    billingCompanyName: null,
    billingAddress1: null,
    billingAddress2: null,
    billingCity: null,
    billingState: null,
    billingPostcode: null,
    billingCountry: null,
    billingVatNumber: null,
    billingPurchaseOrder: null,
    legacyBillingAddress: null,
    licensedForSomeoneElse: false,
    licensedToCompanyName: null,
    licensedToAddress1: null,
    licensedToAddress2: null,
    licensedToCity: null,
    licensedToState: null,
    licensedToPostcode: null,
    licensedToCountry: null,
    licensedToVatNumber: null,
    legacyLicensedTo: null,
    isInvoice: false,
    invoiceId: null,
    invoiceNumber: null,
    invoiceExtraDescription: null,
    hasDownloadables: false,
    usesNativeCurrency: false,
    usesCurrencyConversion: true,
    nativeCurrency: CurrencyChoices.USD,
    hasSimpleLicensing: true,
    hasFontlessItems: false,
    refundedAmount: 0,
};

export const baseLineItem: Item = {
    __typename: 'LineItemInterface',
    id: '',
    description: null,
    price: 0,
    unitPrice: 0,
    type: LineItemType.RETAIL,
    upgradedOrderNumbers: [],
    fontLicenceType: null,
    productName: null,
    productSubtitle: null,
    font: null,
    isRefunded: false,
};

export const baseFontProduct: FontProduct = {
    __typename: 'FontProductInterface',
    fontId: '',
    fontProductType: FontProductTypeChoice.FONTSTYLE,
    cssRenderInfo: {
        __typename: 'CssRenderInfo',
        fontFamilyName: 'ABC',
        isItalic: false,
        variableAxisValues: null,
    },
    fontStyleIdsByFamilyId: [],
    fontSizeMultiplier: 1,
    typographicRanking: null,
};

export const baseLicenceType: LicenceType = {
    __typename: 'LicenceTypeInterface',
    id: '',
    name: '',
    licenceSubjectLabel: null,
    contractDetailsLabel: '',
};

export const baseTieredLicence: TieredLicence = {
    __typename: 'TieredLicensingInterface',
    id: '',
};

export const baseLicenceTier: LicenceTier = {
    __typename: 'TierInterface',
    id: '',
    title: '',
    titleAbbrev: '',
    amount: '',
    multiplier: 1,
    isActive: true,
    isUnlimited: false,
    hasContractDetailsField: false,
    hasLicenceSubjectField: false,
    licenceType: {
        ...baseLicenceType,
    },
    tieredLicence: {
        ...baseTieredLicence,
    },
};

export const baseCartTier: CartTier = {
    __typename: 'CartTierInterface',
    id: '',
    licenceSubject: null,
    contractDetails: null,
    tier: {
        ...baseLicenceTier,
    },
};
