import React from 'react';
import styled, { css } from 'styled-components';
import { useFontFamilyGroup } from './PageContext';
import { Button } from './Lozenge';
import { createFontFamilyUrl } from '../utils/urlHelper';
import { VIEWPORT } from '../settings/Global';
import FontFamilyFontStyles from './FontFamilyFontStyles';
import { FontStylesLink } from './FontStylesElements';
import notPreRelease from '../utils/notPreRelease';
import sortFontsByTypographicRanking from '../utils/sortFontsByTypographicRanking';
import Icon, { IconType } from './Icon';
import type { CMS_SSR_CssRenderInfo } from '../gql/api-ssr';
import getCssFromRenderInfo from '../utils/getCssFromRenderInfo';

const Container = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--spacing9);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing8);
    }
`;

const linkHoverStyles = css`
    &:hover,
    &:focus,
    &:active {
        color: var(--foregroundColorMix5);

        --lozengeColor: var(--backgroundColor);
        --lozengeHoverColor: var(--lozengeColor);
        --lozengeBackgroundColor: var(--foregroundColorMix5);
        --lozengeBackgroundHoverColor: var(--lozengeBackgroundColor);
        --lozengeBackgroundActiveColor: var(--lozengeBackgroundColor);
    }
`;

const View = styled.div`
    color: inherit;
    border-color: inherit;
    ${linkHoverStyles};
`;

const Link = styled(FontStylesLink)`
    display: grid;
    grid-template-columns: 100%;

    /*
     * Actually --spacing4 in the design, but that uses line-height to achieve
     * more space.
     */
    grid-row-gap: var(--spacing5);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        grid-row-gap: var(--spacing3);
    }

    ${linkHoverStyles};
`;

const Title = styled.h2<{
    $cssRenderInfo: CMS_SSR_CssRenderInfo;
    $fontSizeMultiplier: number;
}>`
    ${linkHoverStyles};

    ${({ $cssRenderInfo }): ReturnType<typeof css> =>
        getCssFromRenderInfo($cssRenderInfo)};

    font-weight: normal;
    margin-bottom: var(--spacing2);

    --baseFontSize: var(--fontSizeFluid8);

    hyphens: manual;
    line-height: var(--lineHeightHeading3);

    @media screen and (max-width: ${VIEWPORT.TABLET}px) {
        --baseFontSize: var(--fontSizeFluid6);
    }

    font-size: ${({ $fontSizeMultiplier }): string =>
        $fontSizeMultiplier && $fontSizeMultiplier != 1
            ? `calc(var(--baseFontSize) * ${$fontSizeMultiplier});`
            : 'var(--baseFontSize)'};
`;

function titleWithHyphens(title: string): string {
    return title.replace('Maelstrom', 'Mael\u00ADstrom');
}

function FontCollectionStyles(): React.ReactElement {
    const currentFontFamilyGroup = useFontFamilyGroup();

    const fontFamilies = currentFontFamilyGroup.isCollection
        ? currentFontFamilyGroup.fontFamilies
        : currentFontFamilyGroup.fontFamilies.filter(notPreRelease);

    return (
        <Container>
            {fontFamilies.sort(sortFontsByTypographicRanking).map(
                (fontFamily): React.ReactElement => (
                    <Link
                        key={fontFamily.id}
                        to={createFontFamilyUrl(fontFamily.slug)}
                    >
                        <Title
                            key={fontFamily.id}
                            $cssRenderInfo={fontFamily.cssRenderInfo}
                            $fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                        >
                            {titleWithHyphens(fontFamily.name)}
                        </Title>
                        <FontFamilyFontStyles
                            fontFamily={fontFamily}
                            fontSizeMultiplier={fontFamily.fontSizeMultiplier}
                        />
                        <View>
                            <Button>
                                View {fontFamily.name}&nbsp;
                                <Icon type={IconType.ARROW_RIGHT} />
                            </Button>
                        </View>
                    </Link>
                ),
            )}
        </Container>
    );
}

export default FontCollectionStyles;
