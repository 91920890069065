import { FontProductTypeChoice } from '../gql/api-public';
import type { Item } from '../hooks/useCartQuery';
import { createCollectionUrl, createFontFamilyUrl } from './urlHelper';
import type { FontFamilyGroup } from '../hooks/useAllFonts';
import getFontFamilyFromFontProduct from './getFontFamilyFromFontProduct';

export default function getFontUrl(
    item: Item,
    fontFamilyGroups: FontFamilyGroup[],
): string | undefined {
    if (!item.font || !fontFamilyGroups.length) {
        return;
    }

    // Link to a Collection?
    if (item.font.fontProductType === FontProductTypeChoice.FONTFAMILYGROUP) {
        const fontId = item.font.fontId;
        const collection = fontFamilyGroups.find(
            (fontFamilyGroup): boolean =>
                (fontFamilyGroup.id as string) === fontId,
        );
        if (collection) {
            return createCollectionUrl(collection.slug);
        }
    }
    // Link to a family?
    const family = getFontFamilyFromFontProduct(item.font, fontFamilyGroups);
    if (family) {
        return createFontFamilyUrl(family.slug);
    }
}
