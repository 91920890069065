import React from 'react';
import styled from 'styled-components';
import {
    useEditorState,
    useSpecimenContext,
    useVariableAxesState,
} from './TypeEditorContext';
import type { EditorState } from 'draft-js';
import updateEditorInlineStyles from '../utils/type-editor/updateEditorInlineStyles';
import {
    compileTokenName,
    decompileTokenName,
    getTokenNamePrefix,
    TokenType,
} from '../utils/type-editor/tokens';
import useDraftJsCurrentInlineStyle from '../hooks/type-editor/useDraftJsCurrentInlineStyle';
import useDraftJsIsAllSelected from '../hooks/type-editor/useDraftJsIsAllSelected';
import { type TypeEditorActiveStatus } from './TypeEditorToolbar';
import {
    SliderWithActiveStatus,
    TitleWithActiveStatus,
    ValueWithActiveStatus,
    WidgetContainerWithActiveStatus,
} from './TypeEditorToolbarCommon';

const Container = styled(WidgetContainerWithActiveStatus)`
    --lozengeMinWidth: 160px;
    --lozengeWidth: calc(15vw - var(--gridMarginGap));
`;

const TypeEditorVariableFontInputs = ({
    activeStatus,
}: {
    activeStatus: TypeEditorActiveStatus;
}): React.ReactElement | null => {
    const [, setEditorState] = useEditorState();
    const currentInlineStyle = useDraftJsCurrentInlineStyle();
    const isAllSelected = useDraftJsIsAllSelected();
    const [variableAxes, setVariableAxes] = useVariableAxesState();
    const specimen = useSpecimenContext();

    const vfValuesFromCurrentInlineStyle: Record<string, number> =
        React.useMemo(
            () =>
                variableAxes
                    ? Object.fromEntries(
                          currentInlineStyle
                              .toArray()
                              // Reverse, so that we only take the last value for a certain tag
                              .reverse()
                              .map((token) => {
                                  const tokenData = decompileTokenName(token);
                                  if (tokenData?.type !== TokenType.VARIABLE) {
                                      return [];
                                  }
                                  return [tokenData.names[0], tokenData.value];
                              }),
                      )
                    : {},
            [currentInlineStyle],
        );

    if (specimen.variableAxes === null) {
        return null;
    }

    function getValue(tag: string): number | undefined {
        const tokenNamePrefix = getTokenNamePrefix(TokenType.VARIABLE, [tag]);
        const token = currentInlineStyle
            .toArray()
            .find((token) => token.startsWith(tokenNamePrefix));
        const defaultAxis = variableAxes?.find((axis) => axis.tag === tag);
        if (!token) {
            return defaultAxis?.value;
        }
        const tokenData = decompileTokenName(token);
        if (!tokenData) {
            return defaultAxis?.value;
        }
        return tokenData.value;
    }

    return (
        <>
            {specimen.variableAxes.map((variableAxis) => {
                const value = getValue(variableAxis.tag);
                return (
                    <Container
                        title={`Variable font: ${variableAxis.name}`}
                        key={variableAxis.tag}
                        $activeStatus={activeStatus}
                    >
                        <TitleWithActiveStatus $activeStatus={activeStatus}>
                            VF {variableAxis.name}
                        </TitleWithActiveStatus>
                        <SliderWithActiveStatus
                            value={value ? [value] : []}
                            min={variableAxis.minValue}
                            max={variableAxis.maxValue}
                            step={1}
                            $activeStatus={activeStatus}
                            onValueChange={(val): void => {
                                if (!val.length) {
                                    return;
                                }
                                const value = val[0];

                                // When everything (or nothing) is selected,
                                // also set the VF state for the entire specimen.
                                if (isAllSelected) {
                                    setVariableAxes((prevState) => {
                                        if (!prevState) {
                                            return prevState;
                                        }
                                        return prevState.map((axisEntry) => {
                                            if (
                                                axisEntry.tag !==
                                                variableAxis.tag
                                            ) {
                                                return axisEntry;
                                            }
                                            return {
                                                ...axisEntry,
                                                value,
                                            };
                                        });
                                    });
                                }

                                const stylesToApply = variableAxes?.map(
                                    (vAxis) => {
                                        return compileTokenName({
                                            type: TokenType.VARIABLE,
                                            names: [vAxis.tag],
                                            value:
                                                vAxis.tag === variableAxis.tag
                                                    ? value
                                                    : Object.prototype.hasOwnProperty.call(
                                                            vfValuesFromCurrentInlineStyle,
                                                            vAxis.tag,
                                                        )
                                                      ? vfValuesFromCurrentInlineStyle[
                                                            vAxis.tag
                                                        ]
                                                      : vAxis.value,
                                        });
                                    },
                                );

                                setEditorState(
                                    (state: EditorState): EditorState => {
                                        return updateEditorInlineStyles({
                                            editorState: state,
                                            stylesToApply,
                                            stylesToRemove: currentInlineStyle
                                                .toArray()
                                                .filter(
                                                    (token) =>
                                                        !stylesToApply?.includes(
                                                            token,
                                                        ) &&
                                                        token.startsWith(
                                                            getTokenNamePrefix(
                                                                TokenType.VARIABLE,
                                                                [],
                                                            ),
                                                        ),
                                                ),
                                        });
                                    },
                                );
                            }}
                        />
                        <ValueWithActiveStatus $activeStatus={activeStatus}>
                            {value ? value.toFixed(0) : null}
                        </ValueWithActiveStatus>
                    </Container>
                );
            })}
        </>
    );
};

export default TypeEditorVariableFontInputs;
